import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-switch-to-file-review',
  templateUrl: './switch-to-file-review.component.html',
  styleUrls: ['./switch-to-file-review.component.scss'],
})
export class SwitchToFileReviewComponent implements OnInit {

  
  constructor() { }

  ngOnInit(): void {
  }

}
