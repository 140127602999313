import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  constructor() { }

  private isFileUploaded = false;
  private currentStep!: any;
  public isFileUploadeBehaviorSubject = new BehaviorSubject(this.isFileUploaded);
  public currentStepBehaviorSubject = new BehaviorSubject(this.currentStep);
  public errorInForm = new BehaviorSubject(false);

  public setFileUploadStatus(value: any) {
    this.isFileUploaded = value;
    this.isFileUploadeBehaviorSubject.next(this.isFileUploaded);
  }

  public getFileUploadStatus() {
    return this.isFileUploaded;
  }

  public getFileUploaded() {
    return this.isFileUploadeBehaviorSubject;
  }


  public setCurrentStep(value: any) {
    this.currentStep = value;
    this.currentStepBehaviorSubject.next(this.currentStep);
  }

  public getCurrentStepData() {
    return this.currentStep;
  }

  public getCurrentStep() {
    return this.currentStepBehaviorSubject;
  }

}
