<app-switch-to-file-review *ngIf="component == 'SwitchToFileReview'">
</app-switch-to-file-review>
<app-switch-to-web-review *ngIf="component == 'SwitchToWebReview'">
</app-switch-to-web-review>
<app-switch-to-web-update *ngIf="component == 'SwitchToWebUpdate'">
</app-switch-to-web-update>
<app-review-file-download *ngIf="component == 'ReviewFileDownload'">
</app-review-file-download>
<app-review-file-tips
  *ngIf="component == 'ReviewFileTips'"
></app-review-file-tips>
<app-webtable-or-file-selection *ngIf="component == 'WebTableOrFileSelection'">
</app-webtable-or-file-selection>
<app-review-web-table-tips *ngIf="component == 'ReviewWebTableTips'">
</app-review-web-table-tips>
<app-file-upload-tips
  *ngIf="component == 'FileUploadTips'"
></app-file-upload-tips>
<app-download-report
  *ngIf="component == 'DownloadReport'"
></app-download-report>
