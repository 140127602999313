<div class="pt-3 global-footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <div class="d-flex justify-content-between">
                    <div class="pb-3 footerTextContainer">
                        <div class="links" [innerHTML]="'globalrichtext.footerlinks' | translate | safeHtml">
                        </div>
                        <div class="copyright">
                            <span [innerHTML]="'globalrichtext.copyright' | translate | safeHtml">
                            </span>
                        </div>
                    </div>
                    <div class="d-flex pb-3" style="margin-right: 3%;">
                        <app-digicert class="pr-1"></app-digicert>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>