import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DataOwnerService, SessionService } from '@cyber/navigator-services';
import { LoadingService } from 'src/app/services/loading.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-legal-disclaimer',
  templateUrl: './legal-disclaimer.component.html',
  styleUrls: ['./legal-disclaimer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LegalDisclaimerComponent implements OnInit {

  constructor(private router: Router, 
    public loadingService: LoadingService,
    public dataOwnerService: DataOwnerService, 
    public storageService: StorageService,
    public sessionService: SessionService) { }

  ngOnInit(): void {
  }

  async nextPage(){
    try {
      this.loadingService.show();
      await this.dataOwnerService.beginWorkflow();
      await this.sessionService.completeWelcome();
      this.loadingService.hide();
      this.router.navigateByUrl('settingup?url=/home');
    } catch (err: any) {
      console.error(err);
    } finally {
      this.loadingService.hide();
    }
  }

}
