import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  ChangeOfAddressService,
  CustomerExceptionService,
  CustomerFileError,
  CustomerFileStatus,
  CustomerService,
  PageResponse,
} from '@cyber/navigator-services';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { KendoGridHelper } from 'src/app/helpers/kendo-grid-helper';
import { LoadingService } from 'src/app/services/loading.service';
import { MemberService } from 'src/app/services/member.service';
import { StepsHelperService } from 'src/app/services/steps-helper.service';
import { appConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-customer-file-details',
  templateUrl: './customer-file-details.component.html',
  styleUrls: ['./customer-file-details.component.scss'],
})
export class CustomerFileDetailsComponent implements OnInit {
  public gridData!: GridDataResult;
  public gridState: any = {
    sort: [
      {
        field: 'id',
        dir: 'asc',
      },
    ],
    skip: 0,
    take: 15,
  };

  fileStatus = 'Pending';
  fileId: any;
  fileName: any;
  lastFileErrors: CustomerFileError[] = [];

  fileDetails!: any;
  loadingError!: boolean;
  showErrorGrid = true;
  currentStepDetails: any;

  constructor(
    private _customerService: CustomerService,
    private _customerExceptionService: CustomerExceptionService,
    private _changeOfAddressService: ChangeOfAddressService,
    private _stepsHelperService: StepsHelperService,
    private kendoHelper: KendoGridHelper,
    private _loadingService: LoadingService,
    private _memberService: MemberService,
    private _router: Router
  ) {
    this._memberService.getFileUploaded().subscribe(async (data: boolean) => {
      if (data) {
        this.getFileDetailsFromLocalStorage();
        await this.getFileStatus();
      } else {
        this.showErrorGrid = false;
      }
    });
  }

  ngOnInit(): void {
    this.getFileDetailsFromLocalStorage();
    this.getFileStatus();
  }

  getFileDetailsFromLocalStorage() {
    this.currentStepDetails = this._stepsHelperService.getCurrentStepByRoute(
      this._router.url
    );
    if (
      this.currentStepDetails.data != null &&
      this.currentStepDetails.data != '' &&
      this.currentStepDetails.data != undefined
    ) {
      let currentStepData = this.currentStepDetails.data;
      currentStepData = JSON.parse(currentStepData);
      this.fileDetails = currentStepData.fileDetails;
    }
  }

  async getFileStatus() {
    this._loadingService.show();
    this.fileStatus = 'Pending';
    let data = <CustomerFileStatus>{};
    switch (this.currentStepDetails.step.cms_binding) {
      case appConstants.WorkflowStep.CustomerUpdate:
        data = await this._customerService.getFileStatus();
        break;
      case appConstants.WorkflowStep.NCOA_Review:
        data = await this._changeOfAddressService.getFileStatus();
        break;
      case appConstants.WorkflowStep.Exceptions_Review:
        data = await this._customerExceptionService.getFileStatus();
        break;
    }

    this.fileId = data?.file_id;
    if (data.processed) {
      this.getFileErrors();
    }
    this._loadingService.hide();
  }

  async getFileErrors() {
    this.loadingError = true;
    const sort = this.kendoHelper.getSortString(this.gridState?.sort);
    try {
      let response = <PageResponse<CustomerFileError>>{};
      switch (this.currentStepDetails.step.cms_binding) {
        case appConstants.WorkflowStep.CustomerUpdate:
          response = await this._customerService.pageErrorsForCustomerFile(
            sort,
            this.gridState.skip / this.gridState.take + 1,
            this.gridState.take,
            `(CustomerFileId.Equals(${this.fileId}))`
          );
          break;
        case appConstants.WorkflowStep.NCOA_Review:
          response = await this._changeOfAddressService.pageErrorsForFileUpload(
            sort,
            this.gridState.skip / this.gridState.take + 1,
            this.gridState.take,
            `(CustomerFileId.Equals(${this.fileId}))`
          );
          break;
        case appConstants.WorkflowStep.Exceptions_Review:
          response =
            await this._customerExceptionService.pageErrorsForFileUpload(
              sort,
              this.gridState.skip / this.gridState.take + 1,
              this.gridState.take,
              `(CustomerFileId.Equals(${this.fileId}))`
            );
          break;
      }

      this.gridData = {
        data: response.data,
        total: response.total_count,
      };
      this.showErrorGrid = response.total_count > 0;
      this.fileStatus = this.showErrorGrid ? 'Failed' : 'Processed';
    } catch (err) {
    } finally {
      this.loadingError = false;
    }
  }

  public async onStateChange(state: State) {
    this.gridState = state;
    await this.getFileErrors();
  }
}
