import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  CustomerDownloadProcessingStatus,
  CustomerService,
  STEP_STATUS,
} from '@cyber/navigator-services';
import { LoadingService } from 'src/app/services/loading.service';
import { StepsHelperService } from 'src/app/services/steps-helper.service';
import { appConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-review-file-download',
  templateUrl: './review-file-download.component.html',
  styleUrls: ['./review-file-download.component.scss'],
})
export class ReviewFileDownloadComponent implements OnInit {
  public loadedTip = '';
  error = '';
  currentDataOwner: any;
  fileType: string = 'xlsx';
  interval: any;
  fileGenerationStatus!: CustomerDownloadProcessingStatus;
  constructor(
    private _stepsHelperService: StepsHelperService,
    private _customerService: CustomerService,
    private _loadingService: LoadingService
  ) {}

  async ngOnInit(): Promise<void> {
    this._loadingService.show();
    try {
      this.currentDataOwner =
        this._stepsHelperService.getDataOwnerFromLocalStorage();
      await this.makeFileAsMySelection();
      this.fileGenerationStatus =
        await this._customerService.getFileGenerationStatus();
    } catch (err: any) {
      console.error(err);
    } finally {
      this._loadingService.hide();
    }

    this.pollForStatusUntilComplete();
  }

  async makeFileAsMySelection() {
    let data = {
      display_selection: 'file',
    };
    await this._stepsHelperService.updateStepStatus(
      appConstants.WorkflowStep.CustomerReview,
      null,
      data
    );
    let customerUpdateStep =
      await this._stepsHelperService.getCurrentStepByBinding(
        appConstants.WorkflowStep.CustomerUpdate
      );
    if (customerUpdateStep.data != null) {
      data = JSON.parse(customerUpdateStep.data);
      data.display_selection = 'file';
    }
    await this._stepsHelperService.updateStepStatus(
      appConstants.WorkflowStep.CustomerUpdate,
      null,
      data
    );
  }

  async downloadFile() {
    this.error = '';
    this._loadingService.show();
    try {
      this.fileType = (
        await this._customerService.getDownloadType()
      ).toLowerCase();
      if (this.fileType === 'xlsx') {
        let response = await this._customerService.downloadFile();
        this.processFileDownload(response);
      } else if (this.fileType === 'zip') {
        if (!this.fileGenerationStatus) {
          await this._customerService.startDataFileGeneration();
          this.fileGenerationStatus =
            await this._customerService.getFileGenerationStatus();
          this.pollForStatusUntilComplete();
        } else if (this.fileGenerationStatus?.percentage_complete > 100) {
          let response = await this._customerService.downloadZip(
            this.fileGenerationStatus.download_id
          );
          this.processFileDownload(response);
        }
      }
    } catch (err: any) {
      this.error = 'Error while downloading file. Please contact support';
    }
    this._loadingService.hide();
  }

  async processFileDownload(response: any) {
    let downloadLink = document.createElement('a');
    downloadLink.setAttribute('download', 'DataFile.' + this.fileType);
    downloadLink.href = window.URL.createObjectURL(response.body);
    downloadLink.click();
    await this._stepsHelperService.updateStepStatus(
      appConstants.WorkflowStep.CustomerReview,
      STEP_STATUS.DOWNLOADED
    );
    this._stepsHelperService.stepDataUpdate$.next(true);
  }

  pollForStatusUntilComplete() {
    this.interval = setInterval(async () => {
      try {
        this.fileGenerationStatus =
          await this._customerService.getFileGenerationStatus();
        if (
          !this.fileGenerationStatus || //No status was returned -- no files actively being processed
          this.fileGenerationStatus.percentage_complete > 100 //File is complete, no need to keep polling
        ) {
          clearInterval(this.interval);
        }
      } catch (err: any) {
        console.error(err);
      }
    }, 15 * 1000);
  }
}
