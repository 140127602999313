<div class="container mt-5 step-button-bar">
    <div class="button-row-normal mt-4 d-flex">
        <div class="button-hover">
            <button *ngIf="backButton" [disabled]="disableBack" class="btn btn-primary-outline text-uppercase" type="button"
                (click)="backButtonClick()">
                <span>{{ 'globaltext.BackButton' | translate}}</span>
            </button>
            <div class="tooltiptext" *ngIf="disableBack && backErrorMessage != ''" [innerHTML]="'globalrichtext.' + backErrorMessage |
            translate | safeHtml">
            </div>
        </div>
        <div class="button-hover">
            <button *ngIf="nextButton" [disabled]="disableNext" class="btn btn-primary text-uppercase" type="button"
                (click)="nextButtonClick()">
                <span *ngIf="nextButtonText===''">{{ 'globaltext.NextStepButton' | translate}}</span>
                <span *ngIf="nextButtonText!==''">{{ nextButtonText | translate}}</span>
            </button>
            <div class="tooltiptext" *ngIf="disableNext && errorMessage != ''" [innerHTML]="'globalrichtext.' + errorMessage |
            translate | safeHtml">
            </div>
        </div>
    </div>
</div>