import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingSpinnerComponent } from '../components/loading-spinner/loading-spinner.component';
import { DigicertComponent } from '../components/digicert/digicert.component';
import { FooterPreloginComponent } from '../components/footer-prelogin/footer-prelogin.component';
import { TranslationResolverService } from '../services/translation-resolver.service';
import { CommonModule } from '@angular/common';
import { DigicertService } from '../services/digicert.service';
import { RouterModule } from '@angular/router';
import { TipsComponent } from '../components/tips/tips.component';
import { SvgComponent } from '../svg/svg.component';
import { StorageService } from '../services/storage.service';
import { InfoBoxComponent } from '../components/info-box/info-box.component';
import { NgIdleModule } from '@ng-idle/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { StepsHelperService } from '../services/steps-helper.service';
import { SettingUpEnvironmentComponent } from '../components/setting-up-environment/setting-up-environment.component';
import { KendoGridHelper } from '../helpers/kendo-grid-helper';
import { StepsNavigationButtonsComponent } from '../components/steps-navigation-buttons/steps-navigation-buttons.component';
import { FaqTileComponent } from '../components/faq-tile/faq-tile.component';
import { ModalPopupComponent } from '../components/modal-popup/modal-popup.component';
import { DynamicComponentHelperService } from '../services/dynamic-component-helper.service';
import { DateHelper } from '../helpers/date.helper';
import { MaintenanceBannerComponent } from '../components/maintenance-banner/maintenance-banner.component';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(value: any) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

@NgModule({
  declarations: [
    SafeHtmlPipe,
    LoadingSpinnerComponent,
    DigicertComponent,
    FooterPreloginComponent,
    TipsComponent,
    SvgComponent,
    InfoBoxComponent,
    SettingUpEnvironmentComponent,
    StepsNavigationButtonsComponent,
    FaqTileComponent,
    ModalPopupComponent,
    MaintenanceBannerComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgIdleModule,
    NgIdleKeepaliveModule.forRoot(),
    TranslateModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    LoadingSpinnerComponent,
    DigicertComponent,
    FooterPreloginComponent,
    TipsComponent,
    SvgComponent,
    InfoBoxComponent,
    StepsNavigationButtonsComponent,
    ModalPopupComponent,
    FaqTileComponent,
    MaintenanceBannerComponent,
  ],
  providers: [
    TranslationResolverService,
    DigicertService,
    StorageService,
    StepsHelperService,
    KendoGridHelper,
    DynamicComponentHelperService,
    DateHelper,
  ],
  schemas: [],
})
export class SharedModule {}
