<div class="row">
  <p
    class="mb-0 steps-page-heading tile-color d-flex justify-content-center"
    [class]="stateColor"
    [innerHTML]="subheadingUpdated"
  ></p>
</div>
<div
  class="button-center d-flex"
  *ngIf="
    showSectionsBasedOnOptInEndDateReached == '0' &&
    optInStartDateReached == '1'
  "
>
  <a
    class="btn btn-primary text-uppercase"
    *ngIf="optedValue == ''"
    (click)="showMakeSelectionPopup()"
  >
    <span> {{ "globaltext.MakeYourSelectionButton" | translate }} </span>
  </a>
  <a
    class="btn btn-primary text-uppercase"
    *ngIf="optedValue != ''"
    (click)="showMakeSelectionPopup()"
  >
    <span> {{ "globaltext.ChangeYourSelectionButton" | translate }} </span>
  </a>
</div>
<div
  class="d-flex justify-content-center danger-text mt-3"
  *ngIf="optInStartDateReached == '0'"
  [innerHTML]="
    'globalrichtext.NotificationOptInPeriodNotStarted' | translate | safeHtml
  "
></div>

<div
  class="d-flex justify-content-center danger-text mt-3"
  *ngIf="showSectionsBasedOnOptInEndDateReached == '1'"
  [innerHTML]="
    'globalrichtext.NotificationOptInEndDateCrossed' | translate | safeHtml
  "
></div>
<div
  class="row mt-2"
  *ngIf="adultServiceList.length > 0 || minorServiceList.length > 0"
>
  <p
    class="subheading tile-color d-flex justify-content-center"
    [innerHTML]="
      'globalrichtext.ServicesOfferedSubTitle' | translate | safeHtml
    "
  ></p>
</div>
<div class="row distribute-evenly">
  <div class="row" *ngIf="adultServiceList.length > 0">
    <p
      class="subheading tile-color mt-1 d-flex justify-content-center"
      [innerHTML]="
        'globalrichtext.ServicesIndividualsOverAge' | translate | safeHtml
      "
    ></p>
  </div>
  <ng-template ngFor let-service [ngForOf]="adultServiceList">
    <div
      class="row image-button hover-hand"
      (click)="loadedTip = 'globalrichtext.' + service.content_binding"
    >
      <app-svg [id]="service.content_binding" [type]="service.icon"></app-svg>
      <span
        class="mt-1"
        [innerHTML]="
          'globaltext.' + service.content_binding | translate | safeHtml
        "
      ></span>
    </div>
  </ng-template>

  <div class="row mt-4" *ngIf="minorServiceList.length > 0">
    <p
      class="subheading tile-color mt-3 d-flex justify-content-center"
      [innerHTML]="
        'globalrichtext.ServicesIndividualsUnderAge' | translate | safeHtml
      "
    ></p>
  </div>
  <ng-template ngFor let-service [ngForOf]="minorServiceList">
    <div
      class="row image-button hover-hand"
      (click)="loadedTip = 'globalrichtext.' + service.content_binding"
    >
      <app-svg [id]="service.content_binding" [type]="service.icon"></app-svg>
      <span
        class="mt-1"
        [innerHTML]="
          'globaltext.' + service.content_binding | translate | safeHtml
        "
      ></span>
    </div>
  </ng-template>
</div>
<app-tips
  [show]="loadedTip != ''"
  (closestate)="closeTip($event)"
  [pageType]="loadedTip"
></app-tips>
<app-modal-popup
  [show]="showMakeSelectionPopupValue"
  (emitstate)="emittedPendingOption($event)"
  [modalType]="'optin_customer_notification_pending_action'"
  leftbutton="NoOptOutButton"
  rightbutton="YesOptInButton"
  [showCloseButton]="true"
>
</app-modal-popup>
<app-modal-popup
  [show]="showOptoutConfirmationPopupValue"
  (emitstate)="emittedOptoutConfirmationPopup($event)"
  [modalType]="'optin_customer_notification_optout_confirmation'"
  leftbutton="ContinueToOptOutButton"
  rightbutton="OptInButton"
  [showCloseButton]="true"
></app-modal-popup>
