import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn:'root'
})

export class LoadingService{
    private isLoading = false;
    public BehaviorSubject = new BehaviorSubject(this.isLoading);

    public show(){
        this.isLoading = true;
        this.BehaviorSubject.next(this.isLoading);
    }

    public hide(){
        this.isLoading = false;
        this.BehaviorSubject.next(this.isLoading);
    }

    public getLoading(){
        return this.BehaviorSubject;
    }

    public getLoadingStatus(){
        return this.isLoading;
    }

}