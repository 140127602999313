import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { STEP_STATUS } from '@cyber/navigator-services';
import { TranslateService } from '@ngx-translate/core';
import { DynamicComponentHelperService } from 'src/app/services/dynamic-component-helper.service';
import { MemberService } from 'src/app/services/member.service';
import { StepsHelperService } from 'src/app/services/steps-helper.service';
import { StorageService } from 'src/app/services/storage.service';
import { appConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-steps-navigation-buttons',
  templateUrl: './steps-navigation-buttons.component.html',
  styleUrls: ['./steps-navigation-buttons.component.scss'],
})
export class StepsNavigationButtonsComponent implements OnInit {
  pageType = '';
  finishButton = false;
  errorMessage = '';
  backErrorMessage = '';
  @Input() disableNext = false;
  @Input() disableBack = false;
  @Input() prevUrl = '';
  @Input() nextUrl = '';
  @Input() backButton = true;
  @Input() nextButton = true;
  @Input() nextButtonText = '';
  currentStep: any;
  constructor(
    public router: Router,
    public memberService: MemberService,
    public translate: TranslateService,
    public storageService: StorageService,
    private stepsHelperService: StepsHelperService
  ) {
    this.handleStepStatus();
    this.stepsHelperService.stepDataUpdate$.subscribe((data: any) => {
      this.handleStepStatus();
    });
  }

  handleStepStatus() {
    let optinPeriodEnded =
      this.storageService.getLocalStorage(appConstants.OptInEndDateReached) ==
      '1';

    let changeOfAddressPeriodEnded =
      this.storageService.getLocalStorage(
        appConstants.ChangeOfAddressEndDateReached
      ) == '1';

    let exceptionReviewPeriodEnded =
      this.storageService.getLocalStorage(
        appConstants.ExceptionReviewEndDateReached
      ) == '1';

    let currentStep = this.stepsHelperService.getCurrentStepByRoute(
      this.router.url
    );
    let current_binding = currentStep?.step?.cms_binding;
    let previousStep = this.stepsHelperService.getStepFromBindingPosition(
      current_binding,
      -1
    );
    let nextStep = this.stepsHelperService.getStepFromBindingPosition(
      current_binding,
      1
    );

    this.errorMessage = '';
    this.backErrorMessage = '';
    this.disableNext = false;
    this.disableBack = false;

    switch (nextStep?.step?.cms_binding) {
      case appConstants.WorkflowStep.NotificationOptIn:
        let dataReviewStep = this.stepsHelperService.getCurrentStepByBinding(
          appConstants.WorkflowStep.CustomerReview
        );
        if (
          dataReviewStep?.step_status_id != STEP_STATUS.VIEWED &&
          dataReviewStep?.step_status_id != STEP_STATUS.DOWNLOADED
        ) {
          this.disableNext = true;
          this.errorMessage = 'CustomerReviewNextStepDisabled';
        }
        if (optinPeriodEnded) {
          this.disableNext = true;
          this.errorMessage = 'OptInPeriodEndedNextStepNotAvailable';
        }
        break;
      case appConstants.WorkflowStep.CustomerUpdate:
        let notificationOptInStatus =
          this.stepsHelperService.getCurrentStepByBinding(
            appConstants.WorkflowStep.NotificationOptIn
          );
        if (notificationOptInStatus?.step_status_id != STEP_STATUS.OPTED_IN) {
          this.disableNext = true;
          this.errorMessage = 'StepNotAvailable';
        }
        if (optinPeriodEnded) {
          this.disableNext = true;
          this.errorMessage = 'OptInPeriodEndedNextStepNotAvailable';
        }
        break;
      case appConstants.WorkflowStep.OCR_OptIn:
        if (optinPeriodEnded) {
          this.disableNext = true;
          this.errorMessage = 'OptInPeriodEndedNextStepNotAvailable';
        }
        break;
      case appConstants.WorkflowStep.Regulator_Notice:
        if (optinPeriodEnded) {
          this.translate
            .get('page.Regulator_Notice.pagecomponents')
            .subscribe(async (res: any) => {
              let stepData = res;
              let hasOptIn = stepData.filter(
                (item: any) =>
                  item.component == 'Optin' ||
                  item.component == 'FormCollection'
              );
              if (hasOptIn.length > 0) {
                this.disableNext = true;
                this.errorMessage = 'OptInPeriodEndedNextStepNotAvailable';
              }
            });
        }
        break;
      case appConstants.WorkflowStep.NCOA_Review:
        if (nextStep?.step_status_id == STEP_STATUS.NOT_YET_AVAILABLE) {
          this.disableNext = true;
          this.errorMessage = 'NextStepNCOAReviewNotAvailable';
        }
        if (changeOfAddressPeriodEnded) {
          this.disableNext = true;
          this.errorMessage = 'ChangeOfAddressReviewEndedNextStepNotAvailable';
        }
        break;
      case appConstants.WorkflowStep.Exceptions_Review:
        if (nextStep?.step_status_id == STEP_STATUS.NOT_YET_AVAILABLE) {
          this.disableNext = true;
          this.errorMessage = 'NextStepNCOAExceptionsNotAvailable';
        }
        if (exceptionReviewPeriodEnded) {
          this.disableNext = true;
          this.errorMessage = 'ExceptionReviewEndedNextStepNotAvailable';
        }
        break;
      case appConstants.WorkflowStep.Notification_Report:
        if (nextStep?.step_status_id == STEP_STATUS.NOT_YET_AVAILABLE) {
          this.disableNext = true;
          this.errorMessage = 'NextStepNotificationReportNotAvailable';
        }
        break;
    }

    //Handling back step conditions
    switch (previousStep?.step?.cms_binding) {
      case appConstants.WorkflowStep.CustomerUpdate:
        let notificationOptInStatus =
          this.stepsHelperService.getCurrentStepByBinding(
            appConstants.WorkflowStep.NotificationOptIn
          );
        if (notificationOptInStatus?.step_status_id != STEP_STATUS.OPTED_IN) {
          this.disableBack = true;
          this.backErrorMessage = 'PreviousStepDisabledNotOptedIn';
        }
        if (optinPeriodEnded) {
          this.disableBack = true;
          this.backErrorMessage = 'PreviousStepDisabledOptInEnded';
        }
        break;
      case appConstants.WorkflowStep.OCR_OptIn:
        if (optinPeriodEnded) {
          this.disableBack = true;
          this.backErrorMessage = 'PreviousStepDisabledOptInEnded';
        }
        break;
      case appConstants.WorkflowStep.Regulator_Notice:
        if (optinPeriodEnded) {
          this.translate
            .get('page.Regulator_Notice.pagecomponents')
            .subscribe(async (res: any) => {
              let stepData = res;
              let hasOptIn = stepData.filter(
                (item: any) =>
                  item.component == 'Optin' ||
                  item.component == 'FormCollection'
              );
              if (hasOptIn.length > 0) {
                this.disableBack = true;
                this.errorMessage = 'PreviousStepDisabledOptInEnded';
              }
            });
        }
        break;
    }

    //Handling next step conditions
    switch (this.stepsHelperService.getAlternateStepType(current_binding)) {
      case appConstants.WorkflowStep.NCOA_Review:
      case appConstants.WorkflowStep.CustomerUpdate:
        if (
          currentStep?.step_status_id != STEP_STATUS.COMPLETED &&
          currentStep?.step_status_id != STEP_STATUS.UPDATED &&
          currentStep?.step_status_id != STEP_STATUS.NO_UPDATES_REQUIRED
        ) {
          this.disableNext = true;
          this.errorMessage = 'NextStepDisabledCustomerUpdate';
        }
        break;
      case appConstants.WorkflowStep.OCR_OptIn:
        if (
          currentStep?.step_status_id != STEP_STATUS.OPTED_OUT &&
          currentStep?.step_status_id != STEP_STATUS.OPTED_IN_SUBMITTED
        ) {
          this.disableNext = true;
          this.errorMessage = 'NextStepDisabledOCROptInOrOptOut';
        }
        break;
      case appConstants.WorkflowStep.Regulator_Notice:
        if (
          currentStep?.step_status_id != STEP_STATUS.OPTED_OUT &&
          currentStep?.step_status_id != STEP_STATUS.OPTED_IN_SUBMITTED &&
          currentStep?.step_status_id != STEP_STATUS.OPTED_IN
        ) {
          this.translate
            .get('page.Regulator_Notice.pagecomponents')
            .subscribe(async (res: any) => {
              let stepData = res;
              let hasOptIn = stepData.filter(
                (item: any) => item.component == 'Optin'
              );

              if (hasOptIn.length > 0) {
                this.disableNext = true;
                this.errorMessage = 'NextStepDisabledOptInOrOptOut';
              }

              let hasFormCollection = stepData.filter(
                (item: any) => item.component == 'FormCollection'
              );
              if (hasFormCollection.length > 0) {
                this.disableNext = true;
                this.errorMessage =
                  'NextStepDisabledFormCollectionOptInOrOptOut';
              }
            });
        }
        break;
    }
  }

  ngOnInit(): void {}

  backButtonClick() {
    this.router.navigate([this.prevUrl]);
  }
  nextButtonClick() {
    this.router.navigate([this.nextUrl]);
  }
}
