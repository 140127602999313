import * as moment from 'moment';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export class DateHelper {
  formatToLocalMonthDate(dateTimeOffsetValue: any) {
    let localDate = new Date(dateTimeOffsetValue).toLocaleDateString();
    let displayDate = new Date(localDate);
    return `${months[displayDate.getMonth()]} ${this.padTo2Digits(
      displayDate.getDate()
    )}, ${displayDate.getFullYear()} ${this.formatAMPM(
      displayDate.getHours(),
      displayDate.getMinutes()
    )}`;
  }

  formatToDisplayDate(dateTimeOffsetValue: any, offset: any) {
    let displayDate = moment(dateTimeOffsetValue).utcOffset(offset);

    let sTimeZone = 'CT';
    switch (offset) {
      case '-04:00':
        sTimeZone = 'ET';
        break;
      case '-05:00':
        sTimeZone = 'CT';
        break;
      case '-06:00':
        sTimeZone = 'MT';
        break;
      case '-07:00':
        sTimeZone = 'PT';
        break;
    }
    return `${months[displayDate.month()]} ${this.padTo2Digits(
      displayDate.date()
    )}, ${displayDate.year()} ${this.formatAMPM(
      displayDate.hours(),
      displayDate.minutes()
    )} ${sTimeZone}`;
  }

  formatAMPM(hours: any, minutes: any) {
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes.toString().padStart(2, '0');
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  private padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }

  formatDate(date: Date) {
    return (
      [
        date.getFullYear(),
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate()),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date.getHours()),
        this.padTo2Digits(date.getMinutes()),
        this.padTo2Digits(date.getSeconds()),
      ].join(':')
    );
  }

  formatShortDate(datestring: any) {
    var date = new Date(datestring);
    if (!this.isValid(date)) {
      return datestring;
    }
    var dd = this.padTo2Digits(date.getDate());
    var mm = this.padTo2Digits(date.getMonth() + 1);
    var yyyy = date.getFullYear();
    return mm + '/' + dd + '/' + yyyy;
  }

  isFirstDateAfterSecondDate(dateValue1: any, dateValue2: any) {
    let date1 = new Date(dateValue1);
    let date2 = new Date(dateValue2);

    let utcDate1 = Date.UTC(
      date1.getUTCFullYear(),
      date1.getUTCMonth(),
      date1.getUTCDate(),
      date1.getUTCHours(),
      date1.getUTCMinutes()
    );
    let utcDate2 = Date.UTC(
      date2.getUTCFullYear(),
      date2.getUTCMonth(),
      date2.getUTCDate(),
      date2.getUTCHours(),
      date2.getUTCMinutes()
    );
    if (utcDate2 - utcDate1 > 0) return false;
    return true;
  }

  isFutureDate(dateValue1: any) {
    return this.isFirstDateAfterSecondDate(dateValue1, new Date());
  }

  isFutureDateWithTimeZone(dateValue1: any, timeZone: any) {
    let displayDate!: any;
    if (dateValue1 !== null && dateValue1 != undefined) {
      displayDate = moment(dateValue1).utcOffset(timeZone).toDate();
    }

    return this.isFirstDateAfterSecondDate(displayDate, new Date());
  }

  isValid(value: any) {
    let date = new Date(value);
    if (date.toString() == 'Invalid Date') return false;
    return true;
  }
}
