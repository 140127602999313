import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedKendoRoutingModule } from './shared-kendo-routing.module';
import { SharedKendoComponent } from './shared-kendo.component';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicKendoComponent } from 'src/app/components/dynamic-kendo/dynamic-kendo.component';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploadTipsComponent } from 'src/app/components/file-upload-tips/file-upload-tips.component';
import { ReviewFileDownloadComponent } from 'src/app/components/review-file-download/review-file-download.component';
import { ReviewFileTipsComponent } from 'src/app/components/review-file-tips/review-file-tips.component';
import { ReviewWebTableTipsComponent } from 'src/app/components/review-web-table-tips/review-web-table-tips.component';
import { SwitchToFileReviewComponent } from 'src/app/components/switch-to-file-review/switch-to-file-review.component';
import { SwitchToFileUploadComponent } from 'src/app/components/switch-to-file-upload/switch-to-file-upload.component';
import { SwitchToWebReviewComponent } from 'src/app/components/switch-to-web-review/switch-to-web-review.component';
import { SwitchToWebUpdateComponent } from 'src/app/components/switch-to-web-update/switch-to-web-update.component';
import { UploadFileComponent } from 'src/app/components/upload-file/upload-file.component';
import { WebTableEditGroupComponent } from 'src/app/components/web-table-edit-group/web-table-edit-group.component';
import { WebTableComponent } from 'src/app/components/web-table/web-table.component';
import { WebtableOrFileSelectionComponent } from 'src/app/components/webtable-or-file-selection/webtable-or-file-selection.component';
import { SharedModule } from '../shared.module';
import { CustomerFileDetailsComponent } from 'src/app/components/customer-file-details/customer-file-details.component';
import { DynamicKendoChildComponent } from 'src/app/components/dynamic-kendo-child/dynamic-kendo-child.component';
import { UploadModule } from '@progress/kendo-angular-upload';
import { GridModule } from '@progress/kendo-angular-grid';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { DownloadReportComponent } from 'src/app/components/download-report/download-report.component';
import { ReviewChangeOfAddressFileComponent } from 'src/app/components/review-change-of-address-file/review-change-of-address-file.component';
import { ReviewChangeOfAddressWebComponent } from 'src/app/components/review-change-of-address-web/review-change-of-address-web.component';
import { ChangeOfAddressEditGroupComponent } from 'src/app/components/change-of-address-edit-group/change-of-address-edit-group.component';
import { ReviewCustomerExceptionsWebComponent } from 'src/app/components/review-customer-exceptions-web/review-customer-exceptions-web.component';
import { ReviewCustomerExceptionsFileComponent } from 'src/app/components/review-customer-exceptions-file/review-customer-exceptions-file.component';
import { CustomerExceptionEditGroupComponent } from 'src/app/components/customer-exception-edit-group/customer-exception-edit-group.component';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(value: any) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

@NgModule({
  declarations: [
    SafeHtmlPipe,
    SharedKendoComponent,
    DynamicKendoComponent,
    WebtableOrFileSelectionComponent,
    SwitchToFileReviewComponent,
    SwitchToFileUploadComponent,
    ReviewWebTableTipsComponent,
    SwitchToWebReviewComponent,
    SwitchToWebUpdateComponent,
    ReviewFileTipsComponent,
    FileUploadTipsComponent,
    ReviewFileDownloadComponent,
    DownloadReportComponent,
    WebTableEditGroupComponent,
    UploadFileComponent,
    WebTableComponent,
    CustomerFileDetailsComponent,
    DynamicKendoChildComponent,
    ReviewChangeOfAddressWebComponent,
    ReviewChangeOfAddressFileComponent,
    ChangeOfAddressEditGroupComponent,
    ReviewCustomerExceptionsWebComponent,
    ReviewCustomerExceptionsFileComponent,
    CustomerExceptionEditGroupComponent,
  ],
  imports: [
    CommonModule,
    SharedKendoRoutingModule,
    SharedModule,
    GridModule,
    UploadModule,
    TranslateModule,
    ProgressBarModule,
  ],
})
export class SharedKendoModule {}
