import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RegistrationService, SessionService } from '@cyber/navigator-services';
import { ContactUs } from '@cyber/navigator-services/lib/models/contact-us';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from 'src/app/services/loading.service';
import { RegionalDataService } from 'src/app/services/regional-data.service';
import { StorageService } from 'src/app/services/storage.service';
import { appConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  formGroup: FormGroup;
  errorMessage = '';
  status = 'loaded';
  showThankYou = false;
  isUserLoggedIn = false;
  thankYouContent: string = "";

  constructor(
    public regionalDataService: RegionalDataService,
    private _loadingService: LoadingService,
    private _registrationService: RegistrationService,
    private _storageService: StorageService,
    private translate: TranslateService
  ) {
    this.formGroup = new FormGroup({
      firstName: new FormControl(null, appConstants.FirstNameRegex),
      lastName: new FormControl(null, appConstants.LastNameRegex),
      email: new FormControl(null, appConstants.EmailRegex),
      message: new FormControl(null, [Validators.required]),
    });

    this._loadingService.show();
    this.updateUserDetails();
    this.formatThankYouMessage();
    this._loadingService.hide();
  }

  updateUserDetails(){
    let userDetailsString = this._storageService.getLocalStorage(appConstants.User);
    let userDetails = JSON.parse(userDetailsString);
    this.formGroup.get('firstName')?.setValue(userDetails?.first_name);
    this.formGroup.get('lastName')?.setValue(userDetails?.last_name);
    this.formGroup.get('email')?.setValue(userDetails?.email);
  }

  formatThankYouMessage() {
    let dataOwnerString = this._storageService.getLocalStorage(appConstants.DataOwner);
    let dataOwnerDetails = JSON.parse(dataOwnerString);
    this.thankYouContent = this.translate.instant('globalrichtext.ContactUsThankYou');
    if (dataOwnerDetails != null && dataOwnerDetails?.engagement_entity != "" && dataOwnerDetails?.engagement_entity != null && dataOwnerDetails?.engagement_entity != undefined) {
      let jsonString = this.translate.instant('globalrichtext.ContactUsThankYouWithSupportHours');
      jsonString = jsonString.replace(
        /{{EngagementSupportPhone}}/g,
        dataOwnerDetails?.engagement_entity?.support_phone
      );
      jsonString = jsonString.replace(
        /{{EngagementSupportHours}}/g,
        dataOwnerDetails?.engagement_entity?.support_hours
      );
      this.thankYouContent = jsonString;
    }
  }

  ngOnInit(): void { }

  async onSubmit() {
    if (this.status === 'loading') return;
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      this.status = 'loading';
      this._loadingService.show();
      let  contatctUsData: ContactUs ={
        first_name : this.firstName?.value,
        last_name : this.lastName?.value,
        email : this.email?.value,
        message : this.message?.value,
      }
      await this._registrationService.sendContactUsEmail(contatctUsData);
      this.formGroup.reset();
      this.updateUserDetails();
      this._loadingService.hide();
      this.showThankYou = true;
    }
    this.status = 'loaded';
  }

  //Controls
  get firstName() {
    return this.formGroup.get('firstName');
  }
  get lastName() {
    return this.formGroup.get('lastName');
  }
  get email() {
    return this.formGroup.get('email');
  }
  get message() {
    return this.formGroup.get('message');
  }
  get supportEmail() {
    return this.formGroup.get('supportEmail');
  }

  closeTip(event: any) {
    if (event) this.showThankYou = false;
  }
}
