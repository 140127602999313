import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AppState } from '@auth0/auth0-angular';
import { RegistrationService, SessionService } from '@cyber/navigator-services';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { LoadingService } from 'src/app/services/loading.service';
import { appConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-finish-setup',
  templateUrl: './finish-setup.component.html',
  styleUrls: ['./finish-setup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FinishSetupComponent implements OnInit {
  registrationCode: any;
  formGroup!: FormGroup;

  constructor(
    private router: Router,
    private registrationService: RegistrationService,
    private sessionService: SessionService,
    private loadingService: LoadingService
  ) {
    this.formGroup = new FormGroup({
      firstName: new FormControl(null, appConstants.FirstNameRegex),
      lastName: new FormControl(null, appConstants.LastNameRegex),
    });
  }

  ngOnInit(): void {
    this.sessionService.appState$.pipe(take(1)).subscribe(async (appState) => {
      try {
        this.loadingService.show();
        if (appState?.state?.code) {
          await this.registrationService.complete(appState?.state?.code);
          await this.sessionService.getAccessTokenSilently(); //Completing registration may involve adding roles, get new token so that user groups are updated
        }
      } catch (err: any) {
        this.router.navigateByUrl(`/error/${err.error}`);
      } finally {
        this.loadingService.hide();
      }
    });
  }

  get firstName() {
    return this.formGroup?.get('firstName');
  }
  get lastName() {
    return this.formGroup?.get('lastName');
  }

  async onSubmit() {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) {
      return;
    }
    this.loadingService.show();

    await this.registrationService.updateUserName(
      this.firstName?.value,
      this.lastName?.value
    );

    this.router.navigateByUrl('/welcome');

    this.loadingService.hide();
  }
}
