<div class="container-fluid">
  <div class="row">
    <form #form="ngForm" #nativeForm ngNativeValidate (submit)="closeEditor()">
      <kendo-grid
        #grid
        [data]="gridData"
        [loading]="loadingData"
        [pageSize]="gridState.take"
        [skip]="gridState.skip"
        [sort]="gridState.sort"
        [pageable]="true"
        [sortable]="{
          mode: 'single'
        }"
        [filter]="gridState.filter"
        filterable="menu"
        (dataStateChange)="onStateChange($event)"
        (cellClick)="cellClickHandler($event)"
        (cellClose)="cellCloseHandler($event)"
        (save)="saveHandler($event)"
        (remove)="removeHandler($event)"
        (cancel)="cancelHandler($event)"
        [navigable]="true"
        [resizable]="true"
      >
        <ng-template kendoGridToolbarTemplate>
          <div class="row search-div">
            <div class="input-icon form-group col-6">
              <input
                type="text"
                class="form-control"
                [(ngModel)]="searchQuery"
                [ngModelOptions]="{ standalone: true }"
                (keyup)="searchAllColumns($event)"
                (keydown)="searchAllColumns($event)"
                [placeholder]="'globaltext.search_webtable' | translate"
              />
              <span class="material-icons-outlined icon"> search </span>
            </div>
            <div class="right-side-div col-6">
              <a
                class="btn btn-primary-outline text-uppercase bg-transparent"
                *ngIf="mode == ''"
                (click)="openFullWindow()"
              >
                <i class="material-icons-outlined icon pr-1 font-m"
                  >open_in_new</i
                >
                <span>{{
                  "globaltext.ViewInFullWindowButton" | translate
                }}</span>
              </a>
              <a
                class="btn btn-primary-outline text-uppercase bg-transparent"
                *ngIf="mode != ''"
                (click)="closeFullWindow()"
              >
                <app-svg [type]="'close-new-page'"></app-svg>
                <span class="ml-1">{{
                  "globaltext.CloseFullWindowButton" | translate
                }}</span>
              </a>
              <div class="button-hover">
                <button
                  type="button"
                  class="btn btn-primary text-uppercase"
                  *ngIf="editable === '1' && engagement_entity?.is_add_allowed"
                  [disabled]="invalid || editCellRowIndex === -1"
                  (click)="addHandler()"
                >
                  <span>{{ "globaltext.AddNewButton" | translate }}</span>
                </button>
                <div
                  class="tooltiptext tooltip-alignment"
                  *ngIf="invalid || editCellRowIndex === -1"
                  [innerHTML]="
                    'globalrichtext.AddNewDisabledMessage'
                      | translate
                      | safeHtml
                  "
                ></div>
              </div>
            </div>
          </div>
        </ng-template>
        <kendo-grid-command-column
          title="Action"
          [width]="160"
          *ngIf="!isReview"
          [sticky]="true"
        >
          <ng-template
            kendoGridCellTemplate
            let-isNew="isNew"
            let-rowIndex="rowIndex"
          >
            <button
              *ngIf="
                grid.isEditing() && rowIndex === editCellRowIndex && !isNew
              "
              class="k-button k-primary"
              type="button"
              (click)="dummySaveClick()"
            >
              Save
            </button>
            <button
              kendoGridRemoveCommand
              *ngIf="
                (!grid.isEditing() || rowIndex !== editCellRowIndex) &&
                engagement_entity?.is_delete_allowed
              "
            >
              Remove
            </button>
            <button kendoGridSaveCommand *ngIf="isNew" [primary]="true">
              Save
            </button>
            <button kendoGridCancelCommand *ngIf="isNew">Cancel</button>
          </ng-template>
        </kendo-grid-command-column>
        <!-- <kendo-grid-column [width]="200" [autoSize]="true" *ngFor="let breachColumn of breachColumns" [sticky]="breachColumn.frozen" [title]="breachColumn.col_name" [field]="breachColumn.prop_name" [sortable]="false" [editable]="breachColumn.editable" > -->
        <kendo-grid-column
          [width]="200"
          [autoSize]="true"
          *ngFor="let customerField of customerFields"
          [title]="
            customerField.caption +
            (editable === '1' && customerField.is_required ? ' *' : '')
          "
          [field]="customerField.field_name"
          [sortable]="false"
          [editable]="customerField.is_editable"
          [headerClass]="
            editable === '1' && customerField.is_editable
              ? ' editable_column '
              : ''
          "
        >
          <ng-template
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService"
          >
            <kendo-grid-string-filter-menu
              *ngIf="
                getDataTypeOfCustomerField(customerField.field_name) == 'string'
              "
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              operator="startswith"
            >
              <!-- <kendo-filter-contains-operator></kendo-filter-contains-operator> -->
              <!-- <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator> -->
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <!-- <kendo-filter-endswith-operator></kendo-filter-endswith-operator> -->
              <kendo-filter-eq-operator></kendo-filter-eq-operator>
              <kendo-filter-neq-operator></kendo-filter-neq-operator>
            </kendo-grid-string-filter-menu>
            <kendo-grid-numeric-filter-menu
              *ngIf="
                getDataTypeOfCustomerField(customerField.field_name) == 'number'
              "
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
            >
            </kendo-grid-numeric-filter-menu>
            <kendo-grid-date-filter-menu
              *ngIf="
                getDataTypeOfCustomerField(customerField.field_name) == 'date'
              "
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
            >
            </kendo-grid-date-filter-menu>
            <kendo-grid-boolean-filter-menu
              [column]="column"
              *ngIf="
                getDataTypeOfCustomerField(customerField.field_name) ==
                'boolean'
              "
              [filter]="filter"
              [filterService]="filterService"
            >
            </kendo-grid-boolean-filter-menu>
          </ng-template>
          <ng-template
            kendoGridEditTemplate
            let-rowIndex="rowIndex"
            let-dataItem="dataItem"
            let-isNew="isNew"
            *ngIf="customerField.is_editable"
          >
            <div class="d-flex">
              <div class="left-side-div">
                <input
                  *ngIf="
                    getDataTypeOfCustomerField(customerField.field_name) ===
                      'boolean' && customerField.is_editable
                  "
                  class="k-checkbox"
                  [formControl]="
                    getFormControl(rowIndex, customerField.field_name)
                  "
                  (keydown.Tab)="editNextCell($event, customerField.field_name)"
                  type="checkbox"
                  [checked]="dataItem[customerField.field_name]"
                />
                <input
                  *ngIf="
                    getDataTypeOfCustomerField(customerField.field_name) !=
                      'boolean' && customerField.is_editable
                  "
                  [formControl]="
                    getFormControl(rowIndex, customerField.field_name)
                  "
                  class="k-textbox"
                  (keydown.Tab)="editNextCell($event, customerField.field_name)"
                  kendoGridFocusable
                />
              </div>
              <div
                class="right-side-div ml-1"
                *ngIf="
                  getFormControl(rowIndex, customerField.field_name)?.invalid &&
                  (getFormControl(rowIndex, customerField.field_name)?.dirty ||
                    getFormControl(rowIndex, customerField.field_name)?.touched)
                "
              >
                <span
                  class="form-text bad font-m"
                  *ngIf="
                    getFormControl(rowIndex, customerField.field_name)?.errors
                      ?.required
                  "
                  >*</span
                >
                <span
                  class="form-text bad font-m"
                  *ngIf="
                    getFormControl(rowIndex, customerField.field_name)?.errors
                      ?.pattern
                  "
                  >!</span
                >
              </div>
            </div>
          </ng-template>
          <ng-template
            kendoGridCellTemplate
            let-dataItem="dataItem"
            let-isNew="isNew"
          >
            <input
              *ngIf="
                getDataTypeOfCustomerField(customerField.field_name) ===
                  'boolean' && !isNew
              "
              class="k-checkbox"
              type="checkbox"
              disabled="true"
              [checked]="dataItem[customerField.field_name]"
            />
            <p
              *ngIf="
                getDataTypeOfCustomerField(customerField.field_name) !=
                  'boolean' &&
                getDataTypeOfCustomerField(customerField.field_name) !=
                  'date' &&
                !isNew
              "
            >
              {{ dataItem[customerField.field_name] }}
            </p>
            <p
              *ngIf="
                getDataTypeOfCustomerField(customerField.field_name) ===
                  'date' && !isNew
              "
            >
              {{ formatDateTimeValue(dataItem[customerField.field_name]) }}
            </p>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </form>
  </div>
</div>
<app-modal-popup
  [show]="showConfirmDelete"
  leftbutton="CancelButton"
  rightbutton="DeleteButton"
  (emitstate)="emittedConfirmDelete($event)"
  [modalType]="'edit_customer_delete_confirm'"
></app-modal-popup>
