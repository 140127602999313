import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import {
  ChangeOfAddressService,
  CustomerExceptionService,
  CustomerFileUpload,
  CustomerService,
  STEP_STATUS,
} from '@cyber/navigator-services';
import { TranslateService } from '@ngx-translate/core';
import {
  SuccessEvent,
  FileInfo,
  FileRestrictions,
} from '@progress/kendo-angular-upload';
import * as filesize from 'filesize';
import { LoadingService } from 'src/app/services/loading.service';
import { MemberService } from 'src/app/services/member.service';
import { StepsHelperService } from 'src/app/services/steps-helper.service';
import { StorageService } from 'src/app/services/storage.service';
import { appConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UploadFileComponent implements OnInit {
  currentStepDetails!: any;
  showNoChangesPopup = false;
  showConfirmChangesPopup = false;
  modifiedData = false;
  previousState = '';
  fileUploaded = false;
  state = 'selectionPending';
  stateColor = '';
  fileDetails!: FileUploadedDetails;
  submittedDate = '2 Nov 2022';
  currentDataOwner: any;
  loadButtonText = '';
  showFileUpload = true;

  allowedFileFormat: FileRestrictions = {
    allowedExtensions: ['.xlsx', '.xls', '.sheets', '.ods'],
  };

  fileUploadErrorMsg: any;
  subheadingUpdated: any;
  fileData: any;
  showSectionsBasedOnOptInEndDateReached: any = '0';
  hideResumeButton = false;

  showDefaultErrorMsg: boolean = false;
  selectionPendingTitle: any;
  modalType!: string;
  pageType!: string;
  loadedTip: any;
  switchToWebReview!: string;

  constructor(
    public translate: TranslateService,
    public memberService: MemberService,
    private _router: Router,
    private _stepsHelperService: StepsHelperService,
    private _customerService: CustomerService,
    private _customerExceptionService: CustomerExceptionService,
    private _changeOfAddressService: ChangeOfAddressService,
    private _loadingService: LoadingService,
    private _storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.currentDataOwner =
      this._stepsHelperService.getDataOwnerFromLocalStorage();
    this.currentStepDetails = this._stepsHelperService.getCurrentStepByRoute(
      this._router.url
    );

    if (
      this.currentStepDetails.data != null &&
      this.currentStepDetails.data != '' &&
      this.currentStepDetails.data != undefined
    ) {
      let currentStepData = this.currentStepDetails.data;
      currentStepData = JSON.parse(currentStepData);
      this.fileDetails = currentStepData.fileDetails;
    }
    this.fileUploadErrorMsg = '';
    this.loadButtonText = '';
    this.mapToStates(this.currentStepDetails?.step_status_id);

    switch (this.currentStepDetails.step.cms_binding) {
      case appConstants.WorkflowStep.CustomerUpdate:
        this.selectionPendingTitle = this.translate.instant(
          'globalrichtext.FileChangesQuestion'
        );
        this.showSectionsBasedOnOptInEndDateReached =
          this._storageService.getLocalStorage(
            appConstants.OptInEndDateReached
          );
        this.modalType = 'FileUploadNoticeContent';
        this.pageType = 'globalrichtext.UploadFileTipsContent';
        this.switchToWebReview = 'globalrichtext.SwitchToWebUpdateContent';
        break;
      case appConstants.WorkflowStep.NCOA_Review:
        this.selectionPendingTitle = this.translate.instant(
          'globalrichtext.ChangeOfAddressChangesQuestion'
        );
        this.showSectionsBasedOnOptInEndDateReached =
          this._storageService.getLocalStorage(
            appConstants.ChangeOfAddressEndDateReached
          );
        this.modalType = 'ChangeOfAddressFileUploadNoticeContent';
        this.pageType = 'globalrichtext.ChangeOfAddressUploadFileTipsContent';
        this.switchToWebReview =
          'globalrichtext.SwitchToWebReviewChangeOfAddressContent';
        break;
      case appConstants.WorkflowStep.Exceptions_Review:
        this.state = 'inProgress';
        this.showSectionsBasedOnOptInEndDateReached =
          this._storageService.getLocalStorage(
            appConstants.ExceptionReviewEndDateReached
          );
        this.pageType = 'globalrichtext.ExceptionUploadFileTipsContent';
        this.switchToWebReview =
          'globalrichtext.SwitchToWebReviewExceptionsContent';
        break;
    }

    if (this.showSectionsBasedOnOptInEndDateReached == '1')
      this.hideResumeButton = true;
    if (
      (this.state == 'noChangesToMake' || this.state == 'fileSaved') &&
      this.showSectionsBasedOnOptInEndDateReached == '1'
    ) {
      this.showSectionsBasedOnOptInEndDateReached = '0';
    }
    this.saveMyPreferrence();
  }

  async saveMyPreferrence() {
    let updateNeeded = false;
    let data = {
      display_selection: 'file',
    };

    if (this.currentStepDetails.data != null) {
      data = JSON.parse(this.currentStepDetails.data);
      if (data.display_selection != 'file') updateNeeded = true;
      data.display_selection = 'file';
    } else {
      updateNeeded = true;
    }
    if (updateNeeded) {
      let updateEdit = await this._stepsHelperService.updateStepStatus(
        this.currentStepDetails.step.cms_binding,
        null,
        data
      );
    }
  }

  mapToStates(stepStatusId: number) {
    let currentStatus = this.translate.instant('globalrichtext.CurrentStatus');
    switch (stepStatusId) {
      case STEP_STATUS.PENDING:
        this.state = 'selectionPending';
        this.subheadingUpdated = currentStatus.replace(
          '{{Selection_Type}}',
          this.translate.instant('globaltext.Pending')
        );
        break;
      case STEP_STATUS.NO_UPDATES_REQUIRED:
        this.state = 'noChangesToMake';
        this.stateColor = 'b-yellow';
        this.loadButtonText = this.translate.instant('globaltext.ResumeButton');
        this.subheadingUpdated = currentStatus.replace(
          '{{Selection_Type}}',
          this.translate.instant('globaltext.NoUpdatesRequired')
        );
        break;
      case STEP_STATUS.IN_PROGRESS:
        this.state = 'inProgress';
        this.stateColor = 'b-yellow';
        this.fileUploaded = false;
        this.loadButtonText = this.translate.instant(
          'globaltext.ConfirmChangesButton'
        );
        this.subheadingUpdated = currentStatus.replace(
          '{{Selection_Type}}',
          this.translate.instant('globaltext.InProgress')
        );
        break;
      case STEP_STATUS.UPDATED:
        this.state = 'fileSaved';
        this.stateColor = 'b-green';
        this.showFileUpload = false;
        this.loadButtonText = this.translate.instant('globaltext.ResumeButton');
        this.subheadingUpdated = currentStatus.replace(
          '{{Selection_Type}}',
          this.translate.instant('globaltext.Updated')
        );
        break;
    }
    this._stepsHelperService.stepDataUpdate$.next(true);
  }

  async emittedNoChangesPopup(event: any) {
    if (event) this.state = 'noChangesToMake';
    this.showNoChangesPopup = false;
    await this._stepsHelperService.updateStepStatus(
      this.currentStepDetails.step.cms_binding,
      STEP_STATUS.NO_UPDATES_REQUIRED
    );
    this.mapToStates(STEP_STATUS.NO_UPDATES_REQUIRED);
  }

  emittedConfirmChangesPopup(event: any, upload: any) {
    if (event) {
      upload.uploadFiles();
    } else {
      upload.clearFiles();
    }
    this.showConfirmChangesPopup = false;
  }

  async loadButtonClick() {
    if (this.state == 'inProgress') {
      await this._stepsHelperService.updateStepStatus(
        this.currentStepDetails.step.cms_binding,
        STEP_STATUS.UPDATED
      );
      this.mapToStates(STEP_STATUS.UPDATED);
    } else if (this.state == 'fileSaved' || this.state == 'noChangesToMake') {
      await this._stepsHelperService.updateStepStatus(
        this.currentStepDetails.step.cms_binding,
        STEP_STATUS.IN_PROGRESS
      );
      this.mapToStates(STEP_STATUS.IN_PROGRESS);
    }
  }
  async saveFileClick(upload: any) {
    this._loadingService.show();
    this.fileUploadErrorMsg = '';
    this.showDefaultErrorMsg = false;
    this.memberService.setFileUploadStatus(false);
    let customerFileDetails: CustomerFileUpload = {
      name: this.fileDetails.Name,
      data: this.fileData,
      data_owner_id: this.currentDataOwner.id,
    };
    try {
      switch (this.currentStepDetails.step.cms_binding) {
        case appConstants.WorkflowStep.CustomerUpdate:
          await this._customerService.uploadFile(customerFileDetails);
          break;
        case appConstants.WorkflowStep.NCOA_Review:
          await this._changeOfAddressService.uploadFile(customerFileDetails);
          break;
        case appConstants.WorkflowStep.Exceptions_Review:
          await this._customerExceptionService.uploadFile(customerFileDetails);
          break;
      }
      let currentStepData = JSON.parse(this.currentStepDetails.data);
      currentStepData.fileDetails = this.fileDetails;
      await this._stepsHelperService.updateStepStatus(
        this.currentStepDetails.step.cms_binding,
        STEP_STATUS.IN_PROGRESS,
        currentStepData
      );
      this.mapToStates(STEP_STATUS.IN_PROGRESS);
      this.memberService.setFileUploadStatus(true);
      upload.clearFiles();
      this._loadingService.hide();
    } catch (errorResponse: any) {
      if (errorResponse.status == 400) {
        this.showDefaultErrorMsg = false;
        this.fileUploadErrorMsg = errorResponse.error;
      } else {
        this.showDefaultErrorMsg = true;
      }
      this.state = 'inProgress';
      this.memberService.setFileUploadStatus(true);
      this._stepsHelperService.stepDataUpdate$.next(true);
      this._loadingService.hide();
    }
  }

  public onUpload(ev: SuccessEvent): void {
    this.fileUploadErrorMsg = '';
    this.showDefaultErrorMsg = false;
    if (ev.files) {
      ev.files.forEach((file: FileInfo) => {
        if (
          file.rawFile &&
          !file.validationErrors &&
          ev.operation === 'upload'
        ) {
          const reader = new FileReader();
          reader.onloadend = () => {
            this.fileUploaded = true;

            if (reader.result != null)
              this.fileData = reader.result.toString().split(',')[1];

            this.fileDetails = {
              Name: file.name,
              Size: filesize(file.size ?? 0),
              UploadedDate: Date(),
            };
          };
          reader.readAsDataURL(file.rawFile);
        }
      });
    }
  }

  makeChangesClick(change: any) {
    if (!change) {
      this.showNoChangesPopup = true;
    } else {
      this.previousState = this.state;
      this.state = 'inProgress';
    }
  }

  uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
  uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint
  public fileCount = 0;

  public onSelectEvent(e: any) {
    this.fileCount += 1;
    if (
      this.currentStepDetails.step.cms_binding !==
      appConstants.WorkflowStep.Exceptions_Review
    ) {
      this.showConfirmChangesPopup = true;
    }
  }

  public onUploadEvent(e: any) {
    this.fileCount = 0;
  }

  public onRemoveEvent(e: any, upload: any) {
    if (this.fileCount > 0) {
      this.fileCount -= 1;
    }
    this.fileUploaded = false;
  }

  closeFileUpload() {
    this.showFileUpload = false;
  }
}

export class FileUploadedDetails {
  Name = '';
  UploadedDate = '';
  Size = '';
}
