import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { RegionalDataService } from 'src/app/services/regional-data.service';
import { appConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-request-registration-link',
  templateUrl: './request-registration-link.component.html',
  styleUrls: ['./request-registration-link.component.scss']
})
export class RequestRegistrationLinkComponent implements OnInit {
  formGroup: FormGroup;
  errorMessage = '';
  status = 'loaded';
  constructor(
    public regionalDataService: RegionalDataService,
    public router: Router) {

    this.formGroup = new FormGroup({
      emails: new FormGroup(
        {
          email: new FormControl(null, appConstants.EmailRegex),
          confirmEmail: new FormControl(null, appConstants.EmailRegex)
        },
        this.emailMatcher
      )
    });
  }


  emailMatcher(c: AbstractControl): any {
    if (!c.get("confirmEmail")?.hasError("required") && !c.get("confirmEmail")?.hasError("pattern")) {
      if (c.get("email")?.value && c.get("confirmEmail")?.value && c.get("email")?.value.toLowerCase() !== c.get("confirmEmail")?.value.toLowerCase()) {
        c.get('confirmEmail')?.setErrors({invalid: true});
        return { nomatch: true };
      }
    }
  }

  fieldsMatch(fieldName: string) {
    return (
      this?.formGroup?.get(fieldName)?.hasError("nomatch") &&
      this?.formGroup?.get(fieldName)?.touched
    );
  }

  ngOnInit(): void {
  }

  redirectToEnroll(){
  }

  onSubmit(): void {

    //if (this.status === "loading") return;
    if (this.status === "loading") this.router.navigate(['registration-reset-success']);
    // TODO: Send form values to API for login call
    this.formGroup.markAllAsTouched();
    this.status = "loading";
    this.errorMessage = "";
    //const getFormVal = (name: string) => this.formGroup.get(name).value;

    // this._authService.login(<ILogin>{
    //   userName: getFormVal("username"),
    //   password: getFormVal("password"),
    //   userPool: this.userPool,
    //   country: this.CountryBranding
    // })
    //   .subscribe(
    //     (session) => {
          
    //     },
    //     (err) => {
    //       this.clearField("password");
    //       if (err.status === 401) {
    //         this.errorMessage = this.translate.instant("GeniricMessages.EmailNotMatchInfo");
    //       } else {
    //         this.errorMessage = this._brandingService.handleError(err, false);
    //       }
    //       this.status = "loaded";
    //     }
    //   );
  }

  //Controls
  
  get email() { return this.formGroup?.get("emails")?.get("email"); }
  get confirmEmail() { return this.formGroup?.get("emails")?.get("confirmEmail"); }

}
