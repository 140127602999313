import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile-device-notice',
  templateUrl: './mobile-device-notice.component.html',
  styleUrls: ['./mobile-device-notice.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MobileDeviceNoticeComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  nextPage(){
    this.router.navigate(['/legal-disclaimer']);
  }

}
