<!-- <ng-container [formGroup]="fg"> -->
    <form [formGroup]="formGroup" (change)="onSubmit()">
        <div class="row">
            <div class="col-lg">
                <div class="form-group">
                    <!-- <label class="float-right required">*</label> -->
                    <label>{{'globaltext.FirstNameLabel' | translate}}</label>
                    <input type="text" class="form-control" formControlName="firstName" [placeholder]="'globaltext.FirstNamePlaceholder' | translate">
                    <p class="explanation" *ngIf="firstName?.invalid && (firstName?.dirty || firstName?.touched)">
                        <span class="form-text bad" *ngIf="firstName?.errors?.required">{{'globaltext.FirstNameRequiredMsg' | translate}}</span>
                        <span class="form-text bad" *ngIf="firstName?.errors?.pattern || firstName?.errors?.maxlength">
                            {{'globaltext.FirstNamePatternMsg' | translate}}
                        </span>
                    </p>
                </div>
            </div>
            <div class="col-lg">
                <div class="form-group">
                    <!-- <label class="float-right required">*</label> -->
                    <label>{{'globaltext.LastNameLabel' | translate}}</label>
                    <input type="text" class="form-control" formControlName="lastName" [placeholder]="'globaltext.LastNamePlaceholder' | translate">
                    <p class="explanation" *ngIf="lastName?.invalid && (lastName?.dirty || lastName?.touched)">
                        <span class="form-text bad" *ngIf="lastName?.errors?.required">{{'globaltext.LastNameRequiredMsg' | translate}}
                        </span>
                        <span class="form-text bad" *ngIf="lastName?.errors?.pattern || lastName?.errors?.maxlength">{{'globaltext.LastNamePatternMsg' | translate}}</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg">
                <div class="form-group">
                    <!-- <label class="float-right required">*</label> -->
                    <label>{{'globaltext.EmailLabel' | translate}}</label>
                    <input type="email" class="form-control" formControlName="email"  [placeholder]="'globaltext.EmailPlaceholder' | translate" (focusout)="duplicateEmailCheck($event)">
                    <p class="explanation" *ngIf="email?.invalid && (email?.dirty || email?.touched)">
                        <span class="form-text bad" *ngIf="email?.errors?.required">{{'globaltext.EmailRequiredMsg' | translate}}
                        </span>
                        <span class="form-text bad" *ngIf="email?.errors?.pattern && !email?.errors?.maxlength">{{'globaltext.EmailPatternMsg' | translate}}</span>
                        <span class="form-text bad" *ngIf="email?.errors?.maxlength">{{'globaltext.EmailMaxLengthMsg' | translate}}
                        </span>
                        <span class="form-text bad" *ngIf="email?.errors?.isDuplicate">{{'globaltext.DuplicateEmailInDataOwner' | translate}}
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </form>
    <!-- </ng-container> -->