import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { STEP_STATUS } from '@cyber/navigator-services';
import { TranslateService } from '@ngx-translate/core';
import { MemberService } from 'src/app/services/member.service';
import { StepsHelperService } from 'src/app/services/steps-helper.service';
import { StorageService } from 'src/app/services/storage.service';
import { appConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-web-table-edit-group',
  templateUrl: './web-table-edit-group.component.html',
  styleUrls: ['./web-table-edit-group.component.scss']
})
export class WebTableEditGroupComponent implements OnInit {

  loadedTip = '';
  showNoChangesPopup = false;
  showConfirmChangesPopup = false;
  modifiedData = false;
  state = 'pending';
  SubmittedDate = new Date();

  loadButtonText = '';
  currentDataOwner: any;
  currentStepDetails: any;
  stateColor = "";
  subheadingUpdated: any;
  showSectionsBasedOnOptInEndDateReached = '0';
  hideResumeButton = false;

  constructor(public translate: TranslateService,
    public memberService: MemberService,
    private _storageService: StorageService,
    private _router: Router,
    private _stepsHelperService: StepsHelperService) {
  }

  ngOnInit(): void {
    this.currentDataOwner = this._stepsHelperService.getDataOwnerFromLocalStorage();
    this.currentStepDetails = this._stepsHelperService.getCurrentStepByRoute(this._router.url);
    this.mapToStates(this.currentStepDetails?.step_status_id);
    this.updateButtonState();

    this.showSectionsBasedOnOptInEndDateReached = this._storageService.getLocalStorage(appConstants.OptInEndDateReached);
    if (this.showSectionsBasedOnOptInEndDateReached == '1')
      this.hideResumeButton = true;
    if ((this.state == 'noChanges' || this.state == 'submittedChanges') && (this.showSectionsBasedOnOptInEndDateReached == '1')) {
      this.showSectionsBasedOnOptInEndDateReached = '0';
    }
    this.saveMyPreferrence();
  }

  async saveMyPreferrence() {
    let updateNeeded = false;
    let data = {
      'display_selection': 'web',
    }
    let customerUpdateStep = await this._stepsHelperService.getCurrentStepByBinding(appConstants.WorkflowStep.CustomerUpdate);
    if (customerUpdateStep.data != null) {
      data = JSON.parse(customerUpdateStep.data);
      if(data.display_selection != 'web')
        updateNeeded = true
      data.display_selection = 'web';
    }
    else{
      updateNeeded = true;
    }
    if(updateNeeded){
      let updateEdit = await this._stepsHelperService.updateStepStatus(appConstants.WorkflowStep.CustomerUpdate, null, data);
    }
  }

  mapToStates(stepStatusId: number) {
    let currentStatus = this.translate.instant('globalrichtext.CurrentStatus');
    switch (stepStatusId) {
      case STEP_STATUS.PENDING:
        this.state = 'pending';
        // this.stateColor = 'b-green';
        this.subheadingUpdated = currentStatus.replace('{{Selection_Type}}', this.translate.instant('globaltext.Pending'));
        break;
      case STEP_STATUS.NO_UPDATES_REQUIRED:
        this.state = 'noChanges';
        this.stateColor = 'b-yellow';
        this.subheadingUpdated = currentStatus.replace('{{Selection_Type}}', this.translate.instant('globaltext.NoUpdatesRequired'));
        break;
      case STEP_STATUS.IN_PROGRESS:
        this.state = 'confirmChanges';
        this.stateColor = 'b-yellow';
        this.subheadingUpdated = currentStatus.replace('{{Selection_Type}}', this.translate.instant('globaltext.InProgress'));
        break;
      case STEP_STATUS.UPDATED:
        this.state = 'submittedChanges';
        this.stateColor = 'b-green';
        this.subheadingUpdated = currentStatus.replace('{{Selection_Type}}', this.translate.instant('globaltext.Updated'));
        break;
    }
    this._stepsHelperService.stepDataUpdate$.next(true);
  }


  async updateStepStatusToInProgress() {
    let currentStepStatus = this._stepsHelperService.getCurrentStepByBinding(appConstants.WorkflowStep.CustomerUpdate);
    if (currentStepStatus.step_status_id != STEP_STATUS.IN_PROGRESS) {
      await this._stepsHelperService.updateStepStatus(appConstants.WorkflowStep.CustomerUpdate, STEP_STATUS.IN_PROGRESS);
      this.mapToStates(STEP_STATUS.IN_PROGRESS);
    }
    this.mapToStates(STEP_STATUS.IN_PROGRESS);
  }


  async emitOnDataChanged(emitstate: boolean) {
    if (emitstate) {
      this.state = 'confirmChanges';
      this.updateButtonState();
      await this.updateStepStatusToInProgress();
    }
  }

  updateButtonState() {
    if (this.state === 'pending') {
      this.loadButtonText = this.translate.instant('globaltext.NoChangesButton');
    }
    else if (this.state === 'confirmChanges') {
      this.loadButtonText = this.translate.instant('globaltext.ConfirmChangesButton');
    }
    else if (this.state === 'submittedChanges' || this.state == 'noChanges') {
      this.loadButtonText = this.translate.instant('globaltext.ResumeButton');
    }
  }

  mainButtonClick() {
    if (this.state === 'pending') {
      this.showNoChangesPopup = true;
    }
    else if (this.state === 'confirmChanges') {
      this.showConfirmChangesPopup = true;
    }
    else if (this.state === 'submittedChanges' || this.state == 'noChanges') {
      this.state = 'confirmChanges';
      this.updateStepStatusToInProgress();
    }
    this.updateButtonState();
  }

  async emittedNoChangesPopup(event: any) {
    if (event) {
      this.state = 'submittedChanges';
      this.updateButtonState();
      await this._stepsHelperService.updateStepStatus(appConstants.WorkflowStep.CustomerUpdate, STEP_STATUS.NO_UPDATES_REQUIRED);
      this.mapToStates(STEP_STATUS.NO_UPDATES_REQUIRED);
    }
    this.showNoChangesPopup = false;
  }

  async emittedConfirmChangesPopup(event: any) {
    if (event) {
      this.state = 'submittedChanges';
      this.updateButtonState();
      await this._stepsHelperService.updateStepStatus(appConstants.WorkflowStep.CustomerUpdate, STEP_STATUS.UPDATED);
      this.mapToStates(STEP_STATUS.UPDATED);
    }
    this.showConfirmChangesPopup = false;
  }
}
