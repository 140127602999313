<div class="center-container">
  <div class="card center-card">
    <section class="consent-header text-center">
      <div class="content-container">
        <app-svg [type]="'nnlogo'"></app-svg>
      </div>
      <h2>{{ "globaltext.FinishSetupHeading" | translate }}</h2>
    </section>
    <section class="main-content">
      <div
        class="description text-center"
        [innerHTML]="'globalrichtext.FinishSetupContent' | translate"
      ></div>
      <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <div class="row mt-2">
          <div class="col-lg">
            <div class="form-group">
              <!-- <label class="float-right required">*</label> -->
              <label>{{ "globaltext.FirstNameLabel" | translate }}</label>
              <input
                type="text"
                class="form-control"
                formControlName="firstName"
                [placeholder]="'globaltext.FirstNamePlaceholder' | translate"
              />
              <p
                class="explanation"
                *ngIf="
                  firstName?.invalid && (firstName?.dirty || firstName?.touched)
                "
              >
                <span
                  class="form-text bad"
                  *ngIf="firstName?.errors?.required"
                  >{{ "globaltext.FirstNameRequiredMsg" | translate }}</span
                >
                <span
                  class="form-text bad"
                  *ngIf="
                    firstName?.errors?.pattern || firstName?.errors?.maxlength
                  "
                >
                  {{ "globaltext.FirstNamePatternMsg" | translate }}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg">
            <div class="form-group">
              <!-- <label class="float-right required">*</label> -->
              <label>{{ "globaltext.LastNameLabel" | translate }}</label>
              <input
                type="text"
                class="form-control"
                formControlName="lastName"
                [placeholder]="'globaltext.LastNamePlaceholder' | translate"
              />
              <p
                class="explanation"
                *ngIf="
                  lastName?.invalid && (lastName?.dirty || lastName?.touched)
                "
              >
                <span class="form-text bad" *ngIf="lastName?.errors?.required"
                  >{{ "globaltext.LastNameRequiredMsg" | translate }}
                </span>
                <span
                  class="form-text bad"
                  *ngIf="
                    lastName?.errors?.pattern || lastName?.errors?.maxlength
                  "
                  >{{ "globaltext.LastNamePatternMsg" | translate }}</span
                >
              </p>
            </div>
          </div>
        </div>
        <section class="button-row mt-3 center">
          <button type="submit" class="btn save btn-primary">
            <span>{{ "globaltext.ContinueButton" | translate }}</span>
          </button>
        </section>
      </form>
    </section>
  </div>
</div>
