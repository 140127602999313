import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-card-error',
  templateUrl: './card-error.component.html',
  styleUrls: ['./card-error.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardErrorComponent implements OnInit {
  errorType!: ErrorTypes;
  errorHeadingType = '';
  errorHeading = '';
  errorMessage = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    let param = this.route.snapshot.params['error'];
    this.errorType = this.getErrorType(param);
    this.errorMessage = this.translate.instant(
      'globalrichtext.Error' + this.errorType + 'Content'
    );
    this.errorHeading = this.translate.instant(
      'globaltext.Error' + this.errorHeadingType + 'Heading'
    );

    if (this.errorType == ErrorTypes.AlreadyComplete)
      this.router.navigateByUrl('/sign-in');
  }

  private getErrorType(errorMessage: string) {
    switch (errorMessage) {
      case 'Invalid registration for logged in user.':
      case 'No DataOwnerUser found':
      case 'No DataOwner found':
      case 'No Engagement found':
      case 'Invalid code':
        this.errorHeadingType = 'Registration';
        return ErrorTypes.InvalidCode;
      case 'Registration code has already been completed.':
        this.errorHeadingType = 'Registration';
        return ErrorTypes.AlreadyComplete;
      case 'Registration code has expired.':
        this.errorHeadingType = 'Registration';
        return ErrorTypes.Expired;
      case 'deactivated multiple':
        this.errorHeadingType = 'UnauthorizedAccess';
        return ErrorTypes.DeactivatedMultiple;
      case 'deactivated':
      case 'User Deactivated':
      case 'Cannot complete a registration for a deactivated user.':
        this.errorHeadingType = 'UnauthorizedAccess';
        return ErrorTypes.Deactivated;
      case 'event access ended':
        this.errorHeadingType = 'UnauthorizedAccess';
        return ErrorTypes.AccessToEventEnded;
      case 'event access ended multiple':
        this.errorHeadingType = 'UnauthorizedAccess';
        return ErrorTypes.AccessToEventEndedMultiple;
      case 'Access expired.':
        this.errorHeadingType = 'InvalidLink';
        return ErrorTypes.LinkExpired;
      case 'This URL can be used only once':
        this.errorHeadingType = 'InvalidLink';
        return ErrorTypes.LinkAlreadyUsed;
      case 'Forbidden':
        this.errorHeadingType = 'ForbiddenAccess';
        return ErrorTypes.ForbiddenAccess;
      default:
        this.errorHeadingType = 'Unknown';
        return ErrorTypes.Unknown;
    }
  }
}

export enum ErrorTypes {
  Unknown = 'Unknown',
  InvalidCode = 'InvalidCode',
  AlreadyComplete = 'AlreadyComplete',
  Expired = 'Expired',
  Deactivated = 'Deactivated',
  DeactivatedMultiple = 'DeactivatedMultiple',
  AccessToEventEnded = 'AccessToEventEnded',
  AccessToEventEndedMultiple = 'AccessToEventEndedMultiple',
  LinkExpired = 'LinkExpired',
  LinkAlreadyUsed = 'LinkAlreadyUsed',
  ForbiddenAccess = 'ForbiddenAccess',
}
