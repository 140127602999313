import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { appConstants } from 'src/app/shared/app-constants';
import { RegistrationService } from '@cyber/navigator-services';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-redeem',
  templateUrl: './redeem.component.html',
  styleUrls: ['./redeem.component.scss'],
})
export class RedeemComponent implements OnInit {
  formGroup!: FormGroup;
  errorMessage!: string;
  status!: string;
  knownErrors = [
    'ACCESS_CODE_DOES_NOT_EXIST',
    'ACCESS_CODE_EXPIRED',
    'ACCESS_CODE_REDEEMED',
    'ZIP_CODE_MISMATCH',
  ];
  pageCms: any;

  constructor(
    private _registrationService: RegistrationService,
    private _router: Router,
    private _cookieService: CookieService,
    private translate: TranslateService,
    private loading: LoadingService
  ) {}

  ngOnInit(): void {
    this.status = 'loading';
    this.errorMessage = '';

    const cmsPageData = this.translate.instant(`page`);
    this.pageCms = cmsPageData['Redeem'];

    this.formGroup = new FormGroup({
      activationCode: new FormControl(null, appConstants.ActivationCodeRegex),
      zipCode: new FormControl(null, appConstants.ZipCodeRegex),
    });
  }
  get activationCode() {
    return this.formGroup.get('activationCode');
  }

  get zipCode() {
    return this.formGroup.get('zipCode');
  }

  setQuickCookie(name: string, value: string, expiration: Date) {
    const isLocal = window.location.hostname === 'localhost';
    const domain = this.getDomain();
    this._cookieService.set(name, value, expiration, '/', domain, !isLocal);
  }

  isIEBrowser() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      return true;
    }
    return false;
  }

  getDomain(): string {
    const isLocal = window.location.hostname === 'localhost';
    let domain = isLocal
      ? this.isIEBrowser()
        ? ''
        : 'localhost'
      : window.location.hostname;
    //Change to get dynamic sub domain/domain  for US region
    if (!isLocal) {
      const firstDot = window.location.hostname.indexOf('.');
      domain = window.location.hostname.substring(
        firstDot == -1 ? 0 : firstDot
      );
    }
    return domain;
  }

  async ValidateCode() {
    this.status = 'loading';
    if (this.activationCode?.value === null) {
      this.activationCode.markAsTouched();
    }
    if (this.zipCode?.value === null) {
      this.zipCode.markAsTouched();
    }
    if (this.formGroup.invalid) {
      return;
    }
    this.status = 'loaded';
    this.errorMessage = '';

    try {
      this.loading.show();
      var response = await this._registrationService.validateRedeemCode(
        this.activationCode?.value,
        this.zipCode?.value
      );
      this.setQuickCookie(
        appConstants.sessionCookieObject,
        response.auth_token,
        new Date(response.expiration)
      );
      this.loading.hide();
      this._router.navigate(['redeem/add-users']);
    } catch (err: any) {
      this.loading.hide();
      console.error(err);
      if (this.knownErrors.includes(err.error)) {
        this.errorMessage = err.error;
      } else {
        this.errorMessage = 'CommonError';
      }
    } finally {
      this.status = 'loaded';
    }
  }
}
