import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-postlogin',
  templateUrl: './postlogin.component.html',
  styleUrls: ['./postlogin.component.scss'],
})
export class PostloginComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
