import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-file-upload-tips',
  templateUrl: './file-upload-tips.component.html',
  styleUrls: ['./file-upload-tips.component.scss'],
})
export class FileUploadTipsComponent implements OnInit {
  @Input() pageType = '';
  public loadedTip = '';
  constructor() {}

  ngOnInit(): void {}
}
