import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { RegionalDataService } from 'src/app/services/regional-data.service';
import { appConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  formGroup: FormGroup;
  errorMessage = '';
  status = 'loaded';
  hidePassword = true;
  constructor(
    public regionalDataService: RegionalDataService,
    public router: Router) {

    this.formGroup = new FormGroup({
      password: new FormControl(null, appConstants.PasswordRegex)
    });
  }

  ngOnInit(): void {
  }

  redirectToEnroll(){
  }

  onSubmit(): void {
    //if (this.status === "loading") return;
    if (this.status === "loading") this.router.navigate(['password-reset-success']);
    // TODO: Send form values to API for login call
    this.formGroup.markAllAsTouched();
    this.status = "loading";
    this.errorMessage = "";
    //const getFormVal = (name: string) => this.formGroup.get(name).value;

    // this._authService.login(<ILogin>{
    //   userName: getFormVal("username"),
    //   password: getFormVal("password"),
    //   userPool: this.userPool,
    //   country: this.CountryBranding
    // })
    //   .subscribe(
    //     (session) => {
          
    //     },
    //     (err) => {
    //       this.clearField("password");
    //       if (err.status === 401) {
    //         this.errorMessage = this.translate.instant("GeniricMessages.EmailNotMatchInfo");
    //       } else {
    //         this.errorMessage = this._brandingService.handleError(err, false);
    //       }
    //       this.status = "loaded";
    //     }
    //   );
  }

  // clearField(fieldName: string) {
  //   this.formGroup.get(fieldName).patchValue("");
  //   this.formGroup.get(fieldName).markAsUntouched({
  //     onlySelf: true
  //   });
  //   this.formGroup.get(fieldName).markAsPristine({
  //     onlySelf: true
  //   });
  // }

  //Controls
  get email() {
    return this.formGroup.get("email");
  }
  get password() {
    return this.formGroup.get("password");
  }

}
