<div class="overlay-fill" *ngIf="show" (click)="closeTip()">
    <div class="tips-card" *ngIf="loaded" (click)="false; $event.stopPropagation();">
        <div class="content">
            <div class="heading-div">
                <span class="material-icons grey-heading">info</span>
                <span class="material-icons-outlined close-button hover-hand" (click)="closeTip()">
                    close
                </span>
            </div>
            <div class="row section" [innerHTML]="pageType | translate | safeHtml">

            </div>
        </div>
    </div>
</div>