<div class="container main-content-container">
    <div class="row justify-content-center  pt-5 pb-5 mb-5">
        <div class="col-sm-12 col-md-7 col-lg-6">
            <div class="title-section mt-5">
                <h1 class="h1-heading grey-heading">{{ 'ForgotPassword.Heading' | translate}}</h1>
                <span class="subheading grey-heading">{{ 'ForgotPassword.Subheading' | translate}}</span>
            </div>
            <div class="form-div">
                <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
                    <div class="col" formGroupName="emails">
                        <div class="form-group">
                            <label class="control-label">{{'Form.EmailLabel' | translate}}</label>
                            <input type="text" class="form-control" placeholder="{{'Form.EmailPlaceholder' | translate}}"
                                formControlName="email">
                                
                            <p class="explanation" *ngIf="email?.invalid && (email?.dirty || email?.touched)">
                                <span class="form-text bad" *ngIf="email?.errors?.required">
                                    {{'Form.EmailRequiredMsg' | translate}}</span>
                                <span class="form-text bad"
                                    *ngIf="email?.errors?.pattern && !email?.errors?.maxlength">{{'Form.EmailPatternMsg'
                                    | translate}}</span>
                                <span class="form-text bad" *ngIf="email?.errors?.maxlength">{{'Form.EmailMaxLengthMsg' |
                                    translate}}</span>
                            </p>
                        </div>
                        <div class="form-group">
                            <label class="control-label">{{'Form.ConfirmEmailLabel' | translate}}</label>
                            <input type="text" class="form-control" placeholder="{{'Form.ConfirmEmailPlaceholder' | translate}}"
                                formControlName="confirmEmail">
                            <p class="explanation" *ngIf="confirmEmail?.invalid && (confirmEmail?.dirty || confirmEmail?.touched)">
                                <span class="form-text bad" *ngIf="fieldsMatch('emails')">{{'Form.ConfirmEmailRequiredMsg' |
                                    translate}}</span>
                                <span class="form-text bad" *ngIf="confirmEmail?.errors?.required">
                                    {{'Form.ConfirmEmailRequiredMsg' | translate}}</span>
                                <span class="form-text bad"
                                    *ngIf="confirmEmail?.errors?.pattern && !confirmEmail?.errors?.maxlength">{{'Form.EmailPatternMsg'
                                    | translate}}</span>
                                <span class="form-text bad" *ngIf="confirmEmail?.errors?.maxlength">{{'Form.EmailMaxLengthMsg' |
                                    translate}}</span>
                            </p>
                        </div>
                      </div>
                    <div *ngIf="errorMessage!==''" class="row col recover-error-message">
                        {{errorMessage}}
                    </div>
                    <button type="submit" class="btn mt-20px btn-primary text-uppercase">
                        <span *ngIf="status==='loaded'">{{'ForgotPassword.ResetPasswordButton' | translate }}</span>
                        <ng-container *ngIf="status==='loading'">
                        <app-loading-spinner></app-loading-spinner>
                        </ng-container>
                    </button>
                </form>
            </div>
            <div class="helper-div mt-20px">
                <div>
                    {{'ForgotPassword.AlreadyResetPassword' | translate}}
                </div>
                <div>
                    <a class="secondary-link font-sans link-with-image" routerLink="/sign-in">
                        <i class="material-icons icon pr-1">login</i>
                        <span>{{'ForgotPassword.SignIn' |translate}}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>