<div class="container main-content-container">
    <div class="row justify-content-center ml-5 pt-5 pb-5 mb-5">
        <div class="col-10">
            <div class="row">
                <p class="steps-page-heading-2 mt-3 d-flex justify-content-center"
                [innerHTML]="'globalrichtext.ContactUsHeading' | translate | safeHtml"></p>
            </div>
            <div class="row mt-2">
                <p class="steps-page-heading tile-color mt-3 d-flex justify-content-center"
                    [innerHTML]="'globalrichtext.ContactUsSubHeading' | translate | safeHtml"></p>
            </div>
            <div class="form-div">
                <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="form-group col-4">
                            <label class="control-label">{{'globaltext.FirstNameLabel' | translate}}</label>
                            <input type="text" class="form-control"
                                placeholder="{{'globaltext.FirstNamePlaceholder' | translate}}" formControlName="firstName">

                            <p class="explanation"
                                *ngIf="firstName?.invalid && (firstName?.dirty || firstName?.touched)">
                                <span class="form-text bad" *ngIf="firstName?.errors?.required">
                                    {{'globaltext.FirstNameRequiredMsg' | translate}}</span>
                                <span class="form-text bad"
                                    *ngIf="firstName?.errors?.pattern || firstName?.errors?.maxlength">
                                    {{'globaltext.FirstNameInvalidMsg' | translate}}</span>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-4">
                            <label class="control-label">{{'globaltext.LastNameLabel' | translate}}</label>
                            <input type="text" class="form-control"
                                placeholder="{{'globaltext.LastNamePlaceholder' | translate}}" formControlName="lastName">

                            <p class="explanation" *ngIf="lastName?.invalid && (lastName?.dirty || lastName?.touched)">
                                <span class="form-text bad" *ngIf="lastName?.errors?.required">
                                    {{'globaltext.LastNameRequiredMsg' | translate}}</span>
                                <span class="form-text bad" *ngIf="lastName?.errors?.pattern || lastName?.errors?.maxlength">
                                    {{'globaltext.LastNameInvalidMsg'| translate}}</span>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-8">
                            <label class="control-label">{{'globaltext.EmailLabel' | translate}}</label>
                            <input type="text" class="form-control"
                                placeholder="{{'globaltext.EmailPlaceholder' | translate}}" formControlName="email">

                            <p class="explanation" *ngIf="email?.invalid && (email?.dirty || email?.touched)">
                                <span class="form-text bad" *ngIf="email?.errors?.required">
                                    {{'globaltext.EmailRequiredMsg' | translate}}</span>
                                <span class="form-text bad"
                                    *ngIf="email?.errors?.pattern && !email?.errors?.maxlength">{{'globaltext.EmailPatternMsg'
                                    | translate}}</span>
                                <span class="form-text bad" *ngIf="email?.errors?.maxlength">{{'globaltext.EmailMaxLengthMsg'
                                    |
                                    translate}}</span>
                            </p>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label">{{'globaltext.YourMessageLabel' | translate}}</label>
                        <textarea class="form-control" cols="5" rows="5"
                            placeholder="{{'globaltext.YourMessagePlaceholder' | translate}}" formControlName="message">
                        </textarea>
                        <p class="explanation" *ngIf="message?.invalid && (message?.dirty || message?.touched)">
                            <span class="form-text bad" *ngIf="message?.errors?.required">
                                {{'globaltext.YourMessageRequiredMsg' | translate}}</span>
                        </p>
                    </div>
                    <div *ngIf="errorMessage!==''" class="row col recover-error-message">
                        {{errorMessage}}
                    </div>
                    <button type="submit" class="btn mt-20px btn-primary text-uppercase">
                        <span *ngIf="status==='loaded'">{{'globaltext.SubmitButton' | translate }}</span>
                        <ng-container *ngIf="status==='loading'">
                            <app-loading-spinner></app-loading-spinner>
                        </ng-container>
                    </button>

                </form>

                <div class="row info-banner mt-3 p-3" [innerHTML]="'globalrichtext.ContactUsDescription' | translate">

                </div>
            </div>
        </div>
    </div>
</div>
<app-tips [show]="showThankYou" (closestate)="closeTip($event)" [pageType]="thankYouContent"></app-tips>