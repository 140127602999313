<div class="overlay-fill" *ngIf="show">
    <div class="modal-card" *ngIf="loaded">
        <div class="heading-div text-center mb-1">
            <div *ngIf="logo == 'warning'">
                <app-svg [type]="'warning'"></app-svg>                  
            </div>
            <div *ngIf="showCloseButton" class="close-popup hover-hand" (click)="closeClick()">
                <span class="material-icons-outlined">
                    close
                </span>
            </div>
        </div>
        <div class="row section" [innerHTML]="((IsGlobalDescription? 'global' : '') + 'richtext.'+ modalType) | translate: { EngagementName: data_owner_details?.engagement_entity?.display_name , OptInEndDate: opt_in_end_date } | safeHtml">
        </div>
        <div class="button-row d-flex mt-20px">
            <button class="btn btn-primary-outline text-uppercase" type="button" (click)="leftButtonClick()">
                <span>{{'globaltext.'+ leftbutton | translate}}</span>
            </button>
            <button class="btn btn-secondary text-uppercase" type="button" (click)="rightButtonClick()">
                <span>{{'globaltext.'+ rightbutton | translate}}</span>
            </button>
        </div>
    </div>
</div>