import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataOwnerEntity } from '@cyber/navigator-services';
import { TranslateService } from '@ngx-translate/core';
import { DynamicComponentHelperService } from 'src/app/services/dynamic-component-helper.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-dynamic-kendo',
  templateUrl: './dynamic-kendo.component.html',
  styleUrls: ['./dynamic-kendo.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicKendoComponent implements OnInit {
  current_page = '';
  current_page_content: any = {};
  loaded = false;
  currentStepNo = 0;
  totalSteps = 0;
  prevUrl = '';
  nextUrl = '';
  enableNextButton = false;
  dataOwnerWorkflowSteps: any;
  dataOwnerDetails!: DataOwnerEntity;
  dataOwnerId = 0;
  currentStep: any;
  showStepStatusOnly = false;
  currentStatusContent = '';
  showStepNotAvailable = false;
  pageContent = '';
  showInformationBox = false;
  totalCustomerRecordsCount = 0;
  faqs!: any[];
  cms_page_data: any;
  constructor(
    public translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public dynamicComponentHelper: DynamicComponentHelperService
  ) {
    this.updateEmittedData();
    if (!this.router.url.startsWith('/d/')) {
      this.dynamicComponentHelper.getCurrentPageFromUrl(this.router.url);
    } else {
      this.activatedRoute.params.subscribe((params) => {
        this.dynamicComponentHelper.getCurrentPageFromUrl('/' + params['page']);
      });
    }
  }

  ngOnInit(): void {}

  updateEmittedData() {
    this.dynamicComponentHelper.entireDataBS.subscribe((data: any) => {
      this.current_page = data.current_page;
      this.current_page_content = data.current_page_content;
      this.loaded = data.loaded;
      this.currentStepNo = data.currentStepNo;
      this.totalSteps = data.totalSteps;
      this.prevUrl = data.prevUrl;
      this.nextUrl = data.nextUrl;
      this.enableNextButton = data.enableNextButton;
      this.dataOwnerWorkflowSteps = data.dataOwnerWorkflowSteps;
      this.dataOwnerDetails = data.dataOwnerDetails;
      this.dataOwnerId = data.dataOwnerId;
      this.currentStep = data.currentStep;
      this.showStepStatusOnly = data.showStepStatusOnly;
      this.currentStatusContent = data.currentStatusContent;
      this.showStepNotAvailable = data.showStepNotAvailable;
      this.pageContent = data.pageContent;
      this.showInformationBox = data.showInformationBox;
      this.totalCustomerRecordsCount = data.totalCustomerRecordsCount;
      this.faqs = data.faqs;
      this.cms_page_data = data.cms_page_data;
    });
  }

  public getPageComponentsByPosition(position: string): any[] {
    let components: any[] = [];
    this.current_page_content.pagecomponents.forEach((page: any) => {
      if (page.position === position) {
        components.push(page);
      }
    });
    return components;
  }
}
