import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DataOwnerService } from '@cyber/navigator-services';
import { LoadingService } from 'src/app/services/loading.service';
import { StorageService } from 'src/app/services/storage.service';
import { appConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WelcomeComponent implements OnInit {
  constructor(private router: Router, public storageService: StorageService) {
    this.storageService.setCookie(appConstants.FirstTimeUser, '1');
  }

  async ngOnInit(): Promise<void> { }

  nextPage() {
    this.router.navigate(['/important-information']);
  }
}
