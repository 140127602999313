import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PostloginRoutingModule } from './postlogin-routing.module';
import { PostloginComponent } from './postlogin.component';
import { HeaderPostloginComponent } from 'src/app/components/header-postlogin/header-postlogin.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DashboardComponent } from 'src/app/components/dashboard/dashboard.component';
import { StepCardComponent } from 'src/app/components/step-card/step-card.component';
import { UploadInterceptor } from 'src/app/helpers/upload.interceptor';
import { UploadModule } from '@progress/kendo-angular-upload';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ProfileComponent } from 'src/app/components/profile/profile.component';
import { DynamicComponent } from 'src/app/components/dynamic/dynamic.component';
import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';
import { CoveredEntityFormComponent } from 'src/app/components/covered-entity-form/covered-entity-form.component';
import { ServicesOfferedComponent } from 'src/app/components/services-offered/services-offered.component';
import { DynamicChildComponent } from 'src/app/components/dynamic-child/dynamic-child.component';
import { SessionInterceptor } from '@cyber/navigator-services';
import { SelectEventComponent } from 'src/app/components/select-event/select-event.component';
import { WelcomeComponent } from 'src/app/components/welcome-steps/welcome/welcome.component';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { ImageComponent } from 'src/app/components/image/image.component';
import { AuthCmsFilePipe } from 'src/app/helpers/auth-cms-file.pipe';
import { CmsFileDownloadComponent } from 'src/app/components/cms-file-download/cms-file-download.component';
import { FinishSetupComponent } from 'src/app/components/finish-setup/finish-setup.component';
import { SharedKendoModule } from 'src/app/shared/shared-kendo/shared-kendo.module';
import { FormCollectionComponent } from 'src/app/components/form-collection/form-collection.component';
import { OptinComponent } from 'src/app/components/optin/optin.component';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(value: any) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

@NgModule({
  declarations: [
    SafeHtmlPipe,
    AuthCmsFilePipe,
    ImageComponent,
    CmsFileDownloadComponent,
    CoveredEntityFormComponent,
    ServicesOfferedComponent,
    StepCardComponent,
    PostloginComponent,
    HeaderPostloginComponent,
    DashboardComponent,
    ProfileComponent,
    DynamicComponent,
    DynamicChildComponent,
    SelectEventComponent,
    WelcomeComponent,
    FinishSetupComponent,
    FormCollectionComponent,
    OptinComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,

    ButtonsModule,
    PostloginRoutingModule,
    SharedKendoModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UploadInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
  exports: [],
})
export class PostloginModule {}
