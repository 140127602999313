import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DataOwnerEntity } from '@cyber/navigator-services';
import { TranslateService } from '@ngx-translate/core';
import { Popup } from 'src/app/models/popup';
import { Tip, TipsCard } from 'src/app/models/tips';
import { StorageService } from 'src/app/services/storage.service';
import { appConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalPopupComponent implements OnInit {

  popupDetails!:Popup;
  loaded=false;
  @Input() logo = "warning";
  @Input() modalType!:string;
  @Input() leftbutton!:string;
  @Input() rightbutton!:string;
  @Input() showCloseButton = false;
  @Input() show!:boolean;
  @Input() IsGlobalDescription = true;
  @Output() emitstate: EventEmitter<any> = new EventEmitter<any>();

  data_owner_details!: DataOwnerEntity;
  opt_in_end_date!: any;
  constructor(public translate: TranslateService, public storageService: StorageService) { }

  ngOnInit(): void {
    this.loadAdditionalDetails();
  }

  loadAdditionalDetails(){
    this.data_owner_details = JSON.parse(this.storageService.getLocalStorage(appConstants.DataOwner));
    this.opt_in_end_date = this.storageService.getLocalStorage(appConstants.cookieOptInEndDate);
    this.loaded = true;
  }

  rightButtonClick() {
    this.emitstate.emit(true);
  }

  leftButtonClick(){
    this.emitstate.emit(false);
  }

  closeClick(){
    this.emitstate.emit(null);
  }


}
