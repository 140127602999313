<div class="container-fluid bg-light full-height">
  <div class="pt-1">
    <app-maintenance-banner></app-maintenance-banner>
  </div>

  <div class="container main-content-container">
    <div class="pt-1" *ngIf="dashboardAlertMessage">
      <div
        class="alert-content"
        [innerHTML]="dashboardAlertMessage | safeHtml"
      ></div>
    </div>

    <div class="row welcome-row pt-1" *ngIf="userloaded">
      <h1 *ngIf="userVisitStatus == 'FirstTime'">
        {{
          "globaltext.DashboardFirstTimeUserHeading"
            | translate : { first_name: userDetails.first_name }
        }}
      </h1>
      <h1 *ngIf="userVisitStatus == 'ReturningUser'">
        {{
          "globaltext.DashboardUserNotOptedInHeading"
            | translate : { first_name: userDetails.first_name }
        }}
      </h1>
      <h1 *ngIf="userVisitStatus == 'UserFinalReportGenerated'">
        {{
          "globaltext.DashboardUsedOptedInHeading"
            | translate : { first_name: userDetails.first_name }
        }}
      </h1>
    </div>
    <div class="row pb-5 mr-3 ml-2">
      <div class="col-8" *ngIf="dataOwnerDetails">
        <div
          class="row"
          *ngFor="let step of dataOwnerWorkflowSteps; let last = last"
        >
          <div class="col-12">
            <app-step-card
              [stepDetails]="step"
              [lastItem]="last"
            ></app-step-card>
          </div>
        </div>
      </div>
      <div class="col-8" *ngIf="!dataOwnerDetails">
        <div class="mt-5 d-flex justify-content-center">
          <!-- <app-svg [type]="'loader'"></app-svg> -->
        </div>
      </div>
      <div class="col-4">
        <app-info-box
          [Content]="'richtext.DashboardKeyInformationContent' | translate"
          [Button]="'globaltext.ContactUsButton' | translate"
          [Url]="'/contact-us'"
          [OpenNewTab]="true"
        ></app-info-box>
      </div>
    </div>
  </div>
</div>
