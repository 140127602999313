<div class="container-fluid header">
  <div class="row">
    <div class="col logos-div">
      <img src="../../../assets/kroll.svg" class="logo-img" />
      <div class="logo-seperator" *ngIf="clientLogoUrl != ''"></div>
      <img
        src="../../../assets/kroll.svg"
        class="logo-img"
        *ngIf="clientLogoUrl != ''"
      />
    </div>
  </div>
</div>
