<div class="center-container">
  <div class="card center-card">
    <section class="consent-header text-center">
      <div class="content-container">
        <app-svg [type]="'nnlogo'"></app-svg>
      </div>
      <h2>{{"globaltext.SelectEventHeading" | translate }}</h2>
    </section>
    <section class="main-content text-center">
      <div class="row">
        <div class="description">
          {{"globaltext.SelectEventDescription" | translate }}
        </div>
      </div>
      <div class="row">
        <div class="col d-flex">
          <select class="form-control" [(ngModel)]="selectedDataOwnerId">
            <option value="-1">
              {{ "globaltext.SelectEventPlaceholder" | translate }}
            </option>
            <option *ngFor="let dataOwner of dataOwners" [ngValue]="dataOwner.id">
              {{ dataOwner.engagement_entity.display_name }} -
              {{ dataOwner.name }} ({{dataOwner.data_owner_identifier}})
            </option>
          </select>
        </div>
      </div>
    </section>
    <section class="button-row mt-5 center">
      <button type="button" class="btn save btn-primary" (click)="confirmSelection()">
        <span>{{ "globaltext.ContinueButton" | translate }}</span>
      </button>
    </section>
  </div>
</div>