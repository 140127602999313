import { Component, Input, OnInit } from '@angular/core';
import { Faq } from 'src/app/models/faq';

@Component({
  selector: 'app-faq-tile',
  templateUrl: './faq-tile.component.html',
  styleUrls: ['./faq-tile.component.scss']
})
export class FaqTileComponent implements OnInit {

  hidden=true;

  @Input() Title!: any;
  @Input() Description!: any;
  constructor() { }

  ngOnInit(): void {
  }

}
