<div class="container-fluid full-height">
  <div class="container main-content-container">
    <div class="row pb-5 mr-3 ml-2">
      <div class="col-8">
        <div class="mt-5">
          <div
            class="h1-heading grey-heading"
            [innerHTML]="pageCms.title | safeHtml"
          ></div>
          <div
            class="p-content mt-5"
            [innerHTML]="pageCms.pagecontent?.text | safeHtml"
          ></div>
        </div>
      </div>
      <div class="col-4"></div>
    </div>
  </div>
</div>
