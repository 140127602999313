<div class="center-container mb-5">
    <div class="card center-card">
        <section class="consent-header text-center">
            <div class="content-container">
                <app-svg [type]="'nnlogo'"></app-svg>
            </div>
            <h2>{{'globaltext.NoticeToMobileUsersHeading' | translate}}</h2>
        </section>
        <section class="main-content">
            <div class="row text-center">
                <b class="description"  [innerHTML]="'globalrichtext.NoticeToMobileUsersContent' | translate | safeHtml">
                </b>
            </div>
            <div class="bottom-section">
                <section class="button-row mt-5 center">
                    <button type="button" class="btn save btn-primary" (click)="nextPage()">
                        <span>{{'globaltext.NextButton' | translate}}</span>
                    </button>
                </section>
                <div class="progress-row mt-3 mb-3">
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle active"></div>
                    <div class="circle"></div>
                </div>
            </div>
        </section>
        
    </div>
</div>