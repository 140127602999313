import {
  CustomerService,
  DataOwnerEntity,
  STEP_STATUS,
} from '@cyber/navigator-services';
import { TranslateService } from '@ngx-translate/core';
import { StepsHelperService } from './steps-helper.service';
import { StorageService } from './storage.service';
import { appConstants } from '../shared/app-constants';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DynamicComponentHelperService {
  current_page = '';
  current_page_content: any = {};
  loaded = false;
  currentStepNo = 0;
  totalSteps = 0;
  prevUrl = '';
  nextUrl = '';
  enableNextButton = false;
  dataOwnerWorkflowSteps: any;
  dataOwnerDetails!: DataOwnerEntity;
  dataOwnerId = 0;
  currentStep: any;
  showStepStatusOnly = false;
  currentStatusContent = '';
  pageContent = '';
  showInformationBox = false;
  totalCustomerRecordsCount = 0;
  faqs!: any[];
  cms_page_data: any;
  entireData = {};
  showStepNotAvailable: boolean = false;

  public entireDataBS = new BehaviorSubject(this.entireData);
  constructor(
    private _storageService: StorageService,
    private _stepsHelperService: StepsHelperService,
    private customerService: CustomerService,
    private translate: TranslateService
  ) {
    this._stepsHelperService.stepDataUpdate$.subscribe((data: any) => {
      if (data) {
        this.updateData();
      }
    });
  }

  getUpdatedData() {
    return this.entireDataBS;
  }

  updateData() {
    this.entireData = {
      current_page: this.current_page,
      current_page_content: this.current_page_content,
      loaded: this.loaded,
      currentStepNo: this.currentStepNo,
      totalSteps: this.totalSteps,
      prevUrl: this.prevUrl,
      nextUrl: this.nextUrl,
      enableNextButton: this.enableNextButton,
      dataOwnerWorkflowSteps: this.dataOwnerWorkflowSteps,
      dataOwnerDetails: this.dataOwnerDetails,
      dataOwnerId: this.dataOwnerId,
      currentStep: this.currentStep,
      showStepStatusOnly: this.showStepStatusOnly,
      currentStatusContent: this.currentStatusContent,
      showStepNotAvailable: this.showStepNotAvailable,
      pageContent: this.pageContent,
      showInformationBox: this.showInformationBox,
      totalCustomerRecordsCount: this.totalCustomerRecordsCount,
      faqs: this.faqs,
      cms_page_data: this.cms_page_data,
    };
    this.entireDataBS.next(this.entireData);
  }

  async getCurrentPageFromUrl(route: string) {
    this.dataOwnerWorkflowSteps = JSON.parse(
      this._storageService.getLocalStorage(appConstants.DataOwnerSteps)
    );
    this.dataOwnerDetails = JSON.parse(
      this._storageService.getLocalStorage(appConstants.DataOwner)
    );

    let current_page_routes = route.split('/');
    let currentStep = this._stepsHelperService.getCurrentStepByRoute(route);

    this.totalSteps = this.dataOwnerWorkflowSteps?.length;
    this.currentStepNo =
      this._stepsHelperService.getCurrentStepIndex(currentStep);
    let prevStepDetails: any;
    let nextStepDetails: any;

    if (this.currentStepNo !== -1) {
      if (this.currentStepNo > 0) {
        prevStepDetails = this.dataOwnerWorkflowSteps[this.currentStepNo - 1];
      }
      if (this.currentStepNo < this.dataOwnerWorkflowSteps?.length - 1) {
        nextStepDetails = this.dataOwnerWorkflowSteps[this.currentStepNo + 1];
      }

      this.prevUrl = this.getStepUrls(
        prevStepDetails?.step.page_route ?? '/home',
        prevStepDetails
      );
      this.nextUrl = this.getStepUrls(
        nextStepDetails?.step.page_route ?? '/home',
        nextStepDetails
      );
    }

    this.current_page = currentStep?.step?.cms_binding;
    this.formatCurrentPageBindingName(currentStep, current_page_routes);

    this.showStepStatusOnly = false;
    await this.updateCurrentStepStatus(currentStep);

    if (this.current_page == appConstants.WorkflowStep.CustomerReview) {
      let recordsCount = await this.customerService.page('', 1, 1, '');
      this.totalCustomerRecordsCount = recordsCount.total_count;
    }

    this.currentStep = currentStep;
    this.showInformationBox = this.getShowInformationBox();

    this.translate.stream('page').subscribe((res: any) => {
      this.cms_page_data = res;
      this.current_page_content = this.cms_page_data[this.current_page];
      if (this.current_page == appConstants.WorkflowStep.CustomerReview) {
        this.updateTotalRecordsCount();
      }
      this.loaded = true;
      this.updateData();
    });
  }

  private formatCurrentPageBindingName(
    currentStep: any,
    current_page_routes: any
  ) {
    if (
      (this.current_page == appConstants.WorkflowStep.CustomerReview ||
        this.current_page == appConstants.WorkflowStep.CustomerUpdate ||
        this.current_page == appConstants.WorkflowStep.NCOA_Review ||
        this.current_page == appConstants.WorkflowStep.Exceptions_Review) &&
      (currentStep?.data != null || current_page_routes.length > 2)
    ) {
      if (currentStep?.data) {
        let currentStepData = JSON.parse(currentStep?.data);
        if (
          current_page_routes.length > 1 &&
          currentStepData.display_selection !=
            this.transform(current_page_routes[2])
        )
          this.current_page =
            this.current_page + '_' + this.transform(current_page_routes[2]);
        else
          this.current_page =
            this.current_page +
            '_' +
            this.transform(currentStepData?.display_selection);
      } else {
        this.current_page =
          this.current_page + '_' + this.transform(current_page_routes[2]);
      }
    }
  }

  private getStepUrls(url: string, stepDetails: any) {
    let generatedUrl = url;
    if (
      (stepDetails?.step?.cms_binding ==
        appConstants.WorkflowStep.CustomerReview ||
        stepDetails?.step?.cms_binding ==
          appConstants.WorkflowStep.CustomerUpdate ||
        stepDetails?.step?.cms_binding ==
          appConstants.WorkflowStep.NCOA_Review ||
        stepDetails?.step?.cms_binding ==
          appConstants.WorkflowStep.Exceptions_Review) &&
      stepDetails?.data != null
    ) {
      let stepData = JSON.parse(stepDetails?.data);
      generatedUrl = generatedUrl + '/' + stepData.display_selection;
    } else {
      if (
        (stepDetails?.step?.cms_binding ==
          appConstants.WorkflowStep.CustomerReview ||
          stepDetails?.step?.cms_binding ==
            appConstants.WorkflowStep.CustomerUpdate ||
          stepDetails?.step?.cms_binding ==
            appConstants.WorkflowStep.NCOA_Review ||
          stepDetails?.step?.cms_binding ==
            appConstants.WorkflowStep.Exceptions_Review) &&
        stepDetails?.data == null
      ) {
        generatedUrl = generatedUrl + '/web';
      }
    }
    return generatedUrl;
  }

  private getShowInformationBox() {
    return (
      (this.current_page !== 'CustomerUpdate_Web' && !this.enableNextButton) ||
      (this.current_page !== 'CustomerUpdate_File' && !this.enableNextButton)
    );
  }

  private async updateCurrentStepStatus(currentStep: any) {
    switch (currentStep?.step?.cms_binding) {
      case appConstants.WorkflowStep.Regulator_Notice:
      case appConstants.WorkflowStep.InfoCenter:
        this.translate
          .get('page.' + currentStep?.step?.cms_binding + '.pagecomponents')
          .subscribe(async (res: any) => {
            let stepData = res;
            let hasOptIn = stepData.filter(
              (item: any) =>
                item.component == 'Optin' || item.component == 'FormCollection'
            );
            if (hasOptIn.length == 0) {
              await this._stepsHelperService.updateStepStatus(
                currentStep?.step?.cms_binding,
                STEP_STATUS.VIEWED
              );
            }
          });
        break;
      case appConstants.WorkflowStep.CustomerUpdate:
      case appConstants.WorkflowStep.Exceptions_Review:
      case appConstants.WorkflowStep.NCOA_Review:
        if (currentStep.step_status_id == STEP_STATUS.NA_OPTED_OUT)
          this.showStepStatusOnly = true;
        let Content = this.translate.instant('globalrichtext.CurrentStatus');
        this.currentStatusContent = Content.replace(
          '{{Selection_Type}}',
          this.translate.instant(
            'globaltext.Status_' + currentStep.step_status_id
          )
        );
        this.showStepNotAvailable = true;
        break;
    }
  }

  private transform(value: string): string {
    if (value) {
      let first = value.substr(0, 1).toUpperCase();
      return first + value.substr(1);
    }
    return '';
  }

  private updateTotalRecordsCount() {
    let json_content = this.current_page_content;
    let string_content = JSON.stringify(json_content);
    string_content = this.checkAndReplaceValueInString(
      string_content,
      '{{total_records}}',
      this.totalCustomerRecordsCount
    );
    this.current_page_content = JSON.parse(string_content);
  }

  private checkAndReplaceValueInString(
    data: any,
    original_value: any,
    replace_value: any
  ) {
    if (data?.indexOf(original_value) >= 0) {
      data = data.replace(original_value, replace_value);
    }
    return data;
  }
}
