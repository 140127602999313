import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Customer,
  CustomerField,
  CustomerService,
  DataOwnerService,
  STEP_STATUS,
} from '@cyber/navigator-services';
import { TranslateService } from '@ngx-translate/core';
import {
  CancelEvent,
  CellClickEvent,
  CellCloseEvent,
  EditEvent,
  GridComponent,
  GridDataResult,
  RemoveEvent,
  SaveEvent,
} from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { KendoGridHelper } from 'src/app/helpers/kendo-grid-helper';
import { MemberService } from 'src/app/services/member.service';
import { StepsHelperService } from 'src/app/services/steps-helper.service';
import { appConstants } from 'src/app/shared/app-constants';
import { Guid } from 'guid-typescript';
import { DateHelper } from 'src/app/helpers/date.helper';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-web-table',
  templateUrl: './web-table.component.html',
  styleUrls: ['./web-table.component.scss'],
})
export class WebTableComponent implements OnInit {
  @Output() emitOnDataChanged: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  mode = '';
  totalItems: any;
  gridData!: GridDataResult;
  public gridState: any = {
    sort: [
      {
        field: 'CustomerIdentifier',
        dir: 'asc',
      },
    ],
    skip: 0,
    take: 25,
  };
  searchQuery!: string;
  timeout: any;
  mobileDevice!: boolean;
  editCellRowIndex: number = 0;
  editCellColumnIndex: number = 0;
  isSavingAudit!: boolean;
  booleanTrue = true;
  booleanFalse = false;
  hasChangeAudit: boolean = false;
  loadingData: boolean = true;
  isReview = true;
  customerFields!: CustomerField[];
  showConfirmDelete = false;
  recordToDelete = 0;
  emptyCustomer: any;
  public formGroupMap = new Map<number, FormGroup>();
  dataOwnerId!: any;

  @ViewChild('form') form: NgForm | undefined;
  @ViewChild('nativeForm') nativeForm!: ElementRef;
  @ViewChild(GridComponent) grid!: GridComponent;

  @Input() editable = '0';
  searchSubject: Subject<any> = new Subject();

  engagement_entity!: any;

  constructor(
    public router: Router,
    private _activatedRoute: ActivatedRoute,
    public memberService: MemberService,
    private kendoHelper: KendoGridHelper,
    private customerService: CustomerService,
    private dataOwnerService: DataOwnerService,
    public _stepsHelperService: StepsHelperService,
    public translate: TranslateService,
    public toastr: ToastrService,
    private ngZone: NgZone,
    private _dateHelper: DateHelper,
    private _storageService: StorageService
  ) {
    this.fillEmptyCustomer();
    this.fetchEngagementEntity();
    _activatedRoute.params.subscribe((params) => {
      this.mode = params['mode'] ?? '';
      if ((this.mode && this.mode === 'edit') || this.editable !== '0') {
        this.editable = '1';
        this.isReview = false;
      }
      this.makeWebMySelection();
    });
    this.loadWebTableData(0);

    this.searchSubject.pipe(debounceTime(500)).subscribe(() => {
      this.getData();
    });
  }

  fetchEngagementEntity() {
    let dataOwnerString = this._storageService.getLocalStorage(
      appConstants.DataOwner
    );
    let dataOwnerDetails = JSON.parse(dataOwnerString);
    this.engagement_entity = dataOwnerDetails?.engagement_entity;
    this.dataOwnerId = dataOwnerDetails.id;
  }

  async makeWebMySelection() {
    let data = {
      display_selection: 'web',
    };
    if (this.router.url.indexOf('data-review') > -1) {
      let currentStepDetails =
        await this._stepsHelperService.getCurrentStepByBinding(
          appConstants.WorkflowStep.CustomerReview
        );
      if (currentStepDetails.status != STEP_STATUS.VIEWED)
        await this._stepsHelperService.updateStepStatus(
          appConstants.WorkflowStep.CustomerReview,
          STEP_STATUS.VIEWED,
          data
        );
      this._stepsHelperService.stepDataUpdate$.next(true);
    }
  }

  ngOnInit(): void {
    if (this.editable !== '0') {
      this.editable = '1';
      this.isReview = false;
    }
  }

  getDataTypeOfCustomerField(field_name: string): string {
    let typeOfCustomerField = typeof this.emptyCustomer[field_name];
    if (
      typeOfCustomerField == 'object' &&
      this.emptyCustomer[field_name] instanceof Date
    )
      return 'date';
    return typeOfCustomerField;
  }

  // region api call to get data
  async loadWebTableData(pageNo: number) {
    var customeWebColumns =
      await this.dataOwnerService.fetchCustomerFileColumns();
    this.customerFields = [];
    customeWebColumns
      .sort(this.sortByDisplayOrder)
      .forEach((ele: CustomerField) => {
        ele.field_name = this.camelToSnakeCase(ele.field_name);
        this.customerFields.push(ele);
      });
    await this.getData();
  }

  sortByDisplayOrder(a: any, b: any) {
    if (a.display_order < b.display_order) {
      return -1;
    }
    if (a.display_order > b.display_order) {
      return 1;
    }
    return 0;
  }

  searchAllColumns(event: KeyboardEvent) {
    if (
      this.searchQuery != null &&
      this.searchQuery != '' &&
      this.searchQuery.length >= 3
    ) {
      this.gridState.skip = 0;
      this.searchSubject.next();
    }

    //prevent form submission
    if (event.key === 'Enter') event.preventDefault();
  }

  updateFilterTypesOfColumns(gridColumnsArray: any) {
    gridColumnsArray.forEach((column: any) => {
      let columnDataType = this.getDataTypeOfCustomerField(column.field);
      if (columnDataType == 'string') column.filter = 'text';
      else if (columnDataType == 'number') column.filter = 'numeric';
      else column.filter = columnDataType;
    });
    return gridColumnsArray;
  }

  async getData() {
    // Setting datatype to filter in kendohelper as we cannot set dynamic filter types to kendogrid
    let gridColumnsArray = this.updateFilterTypesOfColumns(
      this.grid?.columns?.toArray()
    );

    const filter = this.kendoHelper.getPageApiWhereClause(
      this.gridState?.filter,
      gridColumnsArray,
      this.searchQuery
    );
    const sort = this.kendoHelper.getSortString(this.gridState?.sort);
    try {
      this.loadingData = true;
      let response = await this.customerService.page(
        sort,
        this.gridState.skip / this.gridState.take + 1,
        this.gridState.take,
        filter
      );
      this.gridData = {
        data: response.data,
        total: response.total_count,
      };
      this.createFormArray(response.data);
    } catch (err) {
    } finally {
      this.loadingData = false;
      this.fitColumns();
    }
  }

  public search() {
    if (this.timeout) clearTimeout(this.timeout);
    this.gridState.skip = 0;
    this.timeout = setTimeout(async () => {
      await this.getData();
    }, 500);
  }

  createFormArray(data: Customer[]) {
    let index = 0;
    data.forEach((item) => {
      this.formGroupMap.set(index, this.createFormGroup(item));
      index++;
    });
  }

  formatDateTimeValue(value: any) {
    return this._dateHelper.formatShortDate(value);
  }

  // endregion

  // region Grid Open/Close/fit

  openFullWindow() {
    this.router.navigate([
      '/web-table/' + (this.editable == '1' ? 'edit' : 'view'),
    ]);
  }

  closeFullWindow() {
    if (this.editable == '1') this.router.navigate(['/data-update/web']);
    else this.router.navigate(['/data-review/web']);
  }

  private fitColumns(): void {
    this.ngZone.onStable
      .asObservable()
      .pipe(take(1))
      .subscribe(() => {
        this.kendoHelper.fitColumns(this.grid);
      });
  }

  // endregion

  // region Grid State change

  public getFormControl(index: number, field: string): any {
    if (index >= this.gridState.skip) index -= this.gridState.skip;
    let formGroup = this.formGroupMap.get(index);
    return formGroup?.controls[field];
  }

  public isValidFormGroup(index: number) {
    if (index >= this.gridState.skip) index -= this.gridState.skip;

    let formGroup = this.formGroupMap.get(index);
    formGroup?.markAllAsTouched();
    return formGroup?.valid;
  }

  public editHandler({ sender, rowIndex, dataItem }: EditEvent): void {
    //this.formGroup = this.createFormGroup(dataItem);
  }

  // Create form group with the same keys as the grid columns.
  createFormGroup(dataItem: any) {
    const group: any = {};

    group['id'] = new FormControl(dataItem.id);
    this.customerFields.forEach((column: CustomerField) => {
      let validators = [];
      if (column.is_required) {
        validators.push(Validators.required);
      }
      if (column.validation_regex) {
        validators.push(Validators.pattern(column.validation_regex));
      }

      if (this.getDataTypeOfCustomerField(column.field_name) == 'date') {
        dataItem[column.field_name] = this._dateHelper.formatShortDate(
          dataItem[column.field_name]
        );
      }

      if (validators.length > 0)
        group[column.field_name] = new FormControl(
          dataItem[column.field_name],
          validators
        );
      else
        group[column.field_name] = new FormControl(dataItem[column.field_name]);
    });
    return new FormGroup(group);
  }

  public async onStateChange(state: State) {
    this.gridState = state;
    await this.getData();
    this.fitColumns();
  }

  // endregion
  // region Cell Click and edit hanlders
  public cellClickHandler(args: CellClickEvent) {
    if (
      !args.isEdited &&
      this.editCellRowIndex !== -1 &&
      args.column.field &&
      args.column.editable &&
      !this.isReview
    ) {
      //field bound to column i.e. not the actions / breach data columns
      this.editCellRowIndex = args.rowIndex;
      this.editCellColumnIndex = args.columnIndex;

      let currentFormGroup = this.createFormGroup(args.dataItem);
      args.sender.editCell(args.rowIndex, args.columnIndex, {
        ...currentFormGroup,
      });
    }
  }

  public async cellCloseHandler(args: CellCloseEvent) {
    if (!this.isValidFormGroup(args.rowIndex)) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (
      args.dataItem != undefined &&
      args.dataItem != undefined &&
      args.dataItem[args.column.field] !==
        this.getFormControl(args.rowIndex, args.column.field).value
    ) {
      try {
        this.loadingData = true;
        //args.dataItem.values['is_original'] = false;

        args.dataItem[args.column.field] = this.getFormControl(
          args.rowIndex,
          args.column.field
        ).value;

        let currentStepStatus =
          this._stepsHelperService.getCurrentStepByBinding(
            appConstants.WorkflowStep.CustomerUpdate
          );

        if (currentStepStatus.step_status_id != STEP_STATUS.IN_PROGRESS) {
          let updateStepPromise = this._stepsHelperService.updateStepStatus(
            appConstants.WorkflowStep.CustomerUpdate,
            STEP_STATUS.IN_PROGRESS
          );
          let savePromise = await this.customerService.save(args.dataItem);
          await Promise.all([savePromise, updateStepPromise]);
        } else {
          await this.customerService.save(args.dataItem);
        }

        await this.getData();
        //await Promise.all([this.auditService.post(new AuditSaveRequest(this.environment.clientTypeId, AuditTypeIds.ConsumerUpdated, `User modified "${args.column.title}" for consumer id: ${args.dataItem.id}`)), this.getData()]);
        this.hasChangeAudit = true;
        this.emitOnDataChanged.emit(true);
      } catch {
        this.toastr.error(
          await this.translate.get('globaltext.WebTableSaveError').toPromise(),
          '',
          {
            positionClass: 'toast-bottom-center',
          }
        );
      } finally {
        this.loadingData = false;
        //If cell being closed is not the one currently being edited, reopen the one being edited after saving / reloading data
        //This is a hack to mimic a tab-like navigation in the editable grid
      }
    }

    if (
      this.editCellColumnIndex >= 0 &&
      this.editCellRowIndex >= 0 &&
      (this.editCellRowIndex !== args.rowIndex ||
        this.editCellColumnIndex !== args.column.leafIndex)
    ) {
      this.grid.editCell(this.editCellRowIndex, this.editCellColumnIndex, {
        ...this.gridData.data[this.editCellRowIndex],
      });
    }
  }

  public closeEditor() {
    this.editCellRowIndex = 0;
    this.editCellColumnIndex = 0;
    this.grid.closeCell();
    this.grid.closeRow();
  }

  public editNextCell(event: Event, column_name: any = null) {
    if (this.editCellRowIndex == -1) {
      if (this.editCellColumnIndex == -1) {
        var currentColumnIndex = this.customerFields.filter(
          (x) => x.field_name == column_name
        )[0].display_order;
        this.editCellColumnIndex = currentColumnIndex;
      }
      var isValidControl = this.getFormControl(-1, column_name)?.valid;
      if (isValidControl) {
        let nextColumnToEdit = this.customerFields.filter(
          (c: any) =>
            c.display_order > this.editCellColumnIndex && c.is_editable
        );
        if (nextColumnToEdit.length > 0) {
          this.editCellColumnIndex = nextColumnToEdit[0].display_order;
        }
      } else {
        this.getFormControl(-1, column_name)?.markAllAsTouched();
        event.preventDefault();
      }
      return;
    }

    if (!this.isValidFormGroup(this.editCellRowIndex)) {
      event.preventDefault();
      return;
    }
    let targetElement = event.target as HTMLInputElement;
    if (targetElement.reportValidity()) {
      //Only increment if form passes validation & isn't a new row
      if (this.editCellRowIndex > -1) {
        event.preventDefault();
        this.grid.closeCell();
        this.grid.closeRow();

        let nextColumnToEdit = this.customerFields.filter(
          (c: any) =>
            c.display_order > this.editCellColumnIndex && c.is_editable
        );
        if (nextColumnToEdit.length > 0) {
          this.editCellColumnIndex = nextColumnToEdit[0].display_order;
        } else {
          this.editCellRowIndex++;
          let firstColumnToEdit = this.customerFields.filter(
            (c: any) => c.is_editable
          );
          if (firstColumnToEdit.length > 0) {
            this.editCellColumnIndex = firstColumnToEdit[0].display_order;
          }
        }
        this.grid.editCell(this.editCellRowIndex, this.editCellColumnIndex, {
          ...this.gridData.data[this.editCellRowIndex],
        });
      }
    } else {
      event.preventDefault();
    }
  }
  fillEmptyCustomer() {
    this.emptyCustomer = {
      id: 0,
      sid: 0,
      first_name: '',
      last_name: '',
      customer_identifier: '',
      email: '',
      address1: '',
      address2: '',
      city: '',
      region: '',
      postal_code: '',
      text1: '',
      text2: '',
      text3: '',
      text4: '',
      text5: '',
      text6: '',
      text7: '',
      text8: '',
      text9: '',
      text10: '',
      text11: '',
      text12: '',
      text13: '',
      text14: '',
      text15: '',
      text16: '',
      text17: '',
      text18: '',
      text19: '',
      text20: '',
      text21: '',
      text22: '',
      text23: '',
      text24: '',
      text25: '',
      text26: '',
      text27: '',
      text28: '',
      text29: '',
      text30: '',
      text31: '',
      text32: '',
      text33: '',
      text34: '',
      text35: '',
      text36: '',
      text37: '',
      text38: '',
      text39: '',
      text40: '',
      date_time1: new Date(),
      date_time2: new Date(),
      date_time3: new Date(),
      date_time4: new Date(),
      date_time5: new Date(),
      date_time6: new Date(),
      date_time7: new Date(),
      date_time8: new Date(),
      date_time9: new Date(),
      date_time10: new Date(),
      number1: 0,
      number2: 0,
      number3: 0,
      number4: 0,
      number5: 0,
      number6: 0,
      number7: 0,
      number8: 0,
      number9: 0,
      number10: 0,
      boolean1: false,
      boolean2: false,
      boolean3: false,
      boolean4: false,
      boolean5: false,
      boolean6: false,
      boolean7: false,
      boolean8: false,
      boolean9: false,
      boolean10: false,
      create_time: new Date(),
      create_user_id: 0,
      modify_time: new Date(),
      modify_user_id: 0,
      delete_time: new Date(),
      delete_user_id: 0,
      notification_error_message: '',
      notification_status_id: 0,
    };
  }

  addDynamicRowToGrid() {
    let temp_form: any = {};
    this.customerFields.forEach((column: any) => {
      let data: any;
      if (column.field_name.startsWith('boolean')) data = false;
      else if (column.field_name.startsWith('number')) data = 0;
      else data = '';

      let validations = [];
      if (column.is_required) {
        validations.push(Validators.required);
      }
      if (column.validation_regex !== '') {
        validations.push(Validators.pattern(column.validation_regex));
      }
      if (validations.length > 0)
        temp_form[column.field_name] = new FormControl(
          data,
          Validators.compose(validations)
        );
      else temp_form[column.field_name] = new FormControl(data);
    });

    let formGroup = new FormGroup(temp_form);
    this.grid.addRow(formGroup);
    this.formGroupMap.set(-1, formGroup);
  }

  public addHandler() {
    this.closeEditor();
    this.editCellRowIndex = -1;
    this.editCellColumnIndex = -1;
    this.addDynamicRowToGrid();
    //this.grid.addRow(<IConsumer>{});
  }

  // Creates new record
  public async saveHandler(args: SaveEvent) {
    try {
      if (!this.isValidFormGroup(args.rowIndex)) {
        // prevent closing the edited cell if there are invalid values.
        return;
      }
      this.loadingData = true;
      this.hasChangeAudit = true;
      let formValue = this.formGroupMap.get(args.rowIndex)?.value;
      formValue['customer_identifier'] = Guid.create().toString();
      formValue['data_owner_id'] = this.dataOwnerId;
      let currentStepStatus = this._stepsHelperService.getCurrentStepByBinding(
        appConstants.WorkflowStep.CustomerUpdate
      );

      if (currentStepStatus.step_status_id != STEP_STATUS.IN_PROGRESS) {
        let updateStepPromise = this._stepsHelperService.updateStepStatus(
          appConstants.WorkflowStep.CustomerUpdate,
          STEP_STATUS.IN_PROGRESS
        );
        let savePromise = this.customerService.save(formValue);
        await Promise.all([savePromise, updateStepPromise]);
      } else {
        await this.customerService.save(formValue);
      }
      await this.getData();
      this.closeEditor();
      this.emitOnDataChanged.emit(true);
    } catch {
      this.toastr.error(
        await this.translate.get('globaltext.WebTableSaveError').toPromise(),
        '',
        {
          positionClass: 'toast-bottom-center',
        }
      );
    } finally {
      this.loadingData = false;
    }
  }

  public async cancelHandler(args: CancelEvent) {
    if (args.isNew) {
      this.editCellRowIndex = -1;
      this.editCellColumnIndex = -1;
      this.formGroupMap.delete(-1);
    }
    this.closeEditor();
  }

  public dummySaveClick() {
    if (this.isValidFormGroup(this.editCellRowIndex)) {
      this.emitOnDataChanged.emit(true);
      this.closeEditor();
    }
  }

  public async removeHandler(args: RemoveEvent) {
    this.recordToDelete = 0;
    if (!args.isNew) {
      this.recordToDelete = args.dataItem['id'];
      this.showConfirmDelete = true;
    }
  }

  public async emittedConfirmDelete(event: any) {
    this.showConfirmDelete = false;
    if (event) {
      this.loadingData = true;
      try {
        let success = await this.customerService.delete(this.recordToDelete);
        if (success) {
          this.emitOnDataChanged.emit(true);
          await this.getData();
        } else {
          this.toastr.error(
            await this.translate
              .get('globaltext.WebTableRemoveError')
              .toPromise(),
            '',
            {
              positionClass: 'toast-bottom-center',
            }
          );
        }
      } catch {
        this.toastr.error(
          await this.translate
            .get('globaltext.WebTableRemoveError')
            .toPromise(),
          '',
          {
            positionClass: 'toast-bottom-center',
          }
        );
      } finally {
        this.loadingData = false;
      }
    }
  }

  get invalid() {
    for (let formGroup of this.formGroupMap.values()) {
      if (formGroup.invalid) return true;
    }
    return false;
  }

  // endregion

  // region helpers
  camelToSnakeCase(str: any) {
    str = str[0].toLowerCase() + str.substr(1);
    str = str.replace(/[A-Z]/g, (letter: any) => `_${letter.toLowerCase()}`);
    return str;
  }

  // end region
}
