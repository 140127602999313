import { Component, OnInit } from '@angular/core';
import {
  DataOwnerEntity,
  DataOwnerWorkflowStepEntity,
  Milestones,
  SessionService,
  STEP_STATUS,
  UserEntity,
} from '@cyber/navigator-services';
import { LoadingService } from 'src/app/services/loading.service';
import { MemberService } from 'src/app/services/member.service';
import { StorageService } from 'src/app/services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { appConstants } from 'src/app/shared/app-constants';
import { DateHelper } from 'src/app/helpers/date.helper';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  userDetails!: UserEntity;
  //status = 'loaded';
  userVisitStatus = 'UserFinalReportGenerated';
  dataOwnerDetails!: DataOwnerEntity;
  dataOwnerWorkflowSteps!: DataOwnerWorkflowStepEntity[];
  userloaded: boolean = false;
  constructor(
    public memberService: MemberService,
    private _storageService: StorageService,
    private _loadingService: LoadingService,
    private _dateHelper: DateHelper,
    private _translateService: TranslateService
  ) {
    this._loadingService.show();

    this.userDetails = JSON.parse(
      this._storageService.getLocalStorage(appConstants.User)
    );

    this.userloaded = true;

    this.dataOwnerDetails = JSON.parse(
      this._storageService.getLocalStorage(appConstants.DataOwner)
    );

    let dataOwnerSteps = this._storageService.getLocalStorage(
      appConstants.DataOwnerSteps
    );
    let finalReportStatus!: DataOwnerWorkflowStepEntity | undefined;

    if (dataOwnerSteps != 'undefined') {
      this.dataOwnerWorkflowSteps = JSON.parse(dataOwnerSteps);

      finalReportStatus = this.dataOwnerWorkflowSteps?.find(
        (x: { step: { cms_binding: string } }) =>
          x.step.cms_binding == 'Notification_Report'
      );
    }

    if (finalReportStatus?.step_status_id != STEP_STATUS.NOT_YET_AVAILABLE) {
      this.userVisitStatus = 'UserFinalReportGenerated';
    } else if (
      this._storageService.getCookie(appConstants.FirstTimeUser) == '1'
    ) {
      this.userVisitStatus = 'FirstTime';
    } else {
      this.userVisitStatus = 'ReturningUser';
    }

    let optInEndDateMileStone = this.dataOwnerDetails?.milestones?.find(
      (x: { milestone_id: number }) => x.milestone_id == Milestones.OptInEnd
    );
    if (optInEndDateMileStone != null) {
      let formattedDate = this._dateHelper.formatToLocalMonthDate(optInEndDateMileStone.effective_date);
      this._storageService.setCookie('OptInEndDate', formattedDate);
    } else {
      this._storageService.setCookie(
        'OptInEndDate',
        'OptIn-End Date Not Provided'
      );
    }
    this._loadingService.hide();
  }

  public get dashboardAlertMessage() {
    const key = 'richtext.DashboardAlertMessage';
    const value = this._translateService.instant(key);
    return value === key ? null : value;
  }

  ngOnInit(): void {}
}
