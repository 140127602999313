<app-maintenance-banner></app-maintenance-banner>
<div class="container-fluid full-height">
  <div class="container main-content-container">
    <div class="row pb-5 mr-3 ml-2">
      <div class="col-8">
        <div class="mt-5">
          <div
            class="h1-heading grey-heading"
            [innerHTML]="pageCms.title | safeHtml"
          ></div>
          <div
            class="grey-message mt-3"
            [innerHTML]="pageCms.pagecontent?.text | safeHtml"
          ></div>

          <form [formGroup]="formGroup">
            <div class="form-group">
              <label class="control-label">{{
                "globaltext.AccessCodeLabel" | translate
              }}</label>
              <input
                type="text"
                class="form-control"
                name="activationCode"
                placeholder=""
                formControlName="activationCode"
              />
              <span class="grey-message">
                {{ "globaltext.AccessCodeHint" | translate }}
              </span>
              <p
                class="explanation"
                *ngIf="
                  activationCode?.invalid &&
                  (activationCode?.dirty || activationCode?.touched)
                "
              >
                <span
                  class="form-text bad"
                  *ngIf="activationCode?.errors?.required"
                >
                  {{ "globaltext.AccessCodeRequiredMsg" | translate }}</span
                >
                <span
                  class="form-text bad"
                  *ngIf="
                    activationCode?.errors?.pattern ||
                    activationCode?.errors?.maxlength
                  "
                  >{{ "globaltext.AccessCodePatternMsg" | translate }}</span
                >
              </p>
            </div>
            <div class="form-group">
              <label class="control-label">{{
                "globaltext.BusinessZipCodeLabel" | translate
              }}</label>
              <input
                type="text"
                class="form-control"
                name="zipCode"
                [placeholder]="
                  'globaltext.BusinessZipCodePlaceholder' | translate
                "
                formControlName="zipCode"
              />
              <p
                class="explanation"
                *ngIf="zipCode?.invalid && (zipCode?.dirty || zipCode?.touched)"
              >
                <span class="form-text bad" *ngIf="zipCode?.errors?.required">
                  {{ "globaltext.BusinessZipCodeRequiredMsg" | translate }}
                </span>
                <span class="form-text bad" *ngIf="zipCode?.errors?.pattern">
                  {{ "globaltext.BusinessZipCodePatternMsg" | translate }}
                </span>
              </p>
            </div>
            <div
              *ngIf="errorMessage !== ''"
              class="mt-3 row col recover-error-message"
            >
              <span
                [innerHTML]="'globalrichtext.' + errorMessage | translate"
              ></span>
            </div>
            <button
              class="btn btn-primary btn-block sign-in mt-3"
              (click)="ValidateCode()"
            >
              <span>{{ "globaltext.VerifyCodeButton" | translate }}</span>
              <!-- <ng-container *ngIf="status==='loading'">
                                <app-loading-spinner></app-loading-spinner>
                            </ng-container>
                            <ng-container *ngIf="status==='loading'">
                                <app-loading-spinner></app-loading-spinner>
                            </ng-container> -->
            </button>
          </form>
        </div>
      </div>
      <div class="col-4">
        <app-info-box
          [Content]="'globalrichtext.RedeemContent' | translate"
        ></app-info-box>
      </div>
    </div>
  </div>
</div>
