<div class="row d-flex justify-content-center mt-2 mb-2">
  <a
    class="secondary-link font-sans link-with-image"
    (click)="loadedTip = 'file-tips'"
  >
    <span class="material-icons pr-1"> info </span>
    <span [innerHTML]="'globaltext.FileQuickTipsTitle' | translate"></span>
  </a>
</div>
<app-tips
  [show]="loadedTip == 'file-tips'"
  (closestate)="loadedTip = ''"
  [pageType]="'globalrichtext.UploadFileTipsContent'"
></app-tips>
