<!-- [class]=" 'cardIcon-'+step.Status[stepDetails.state].Color"> -->
<div class="card mb-2" [class]=" 'cardIcon-'+statusColor">
    <div class="card-body row" [class.line-bar]="!lastItem && hidden" [class.line-bar-full]="!lastItem && !hidden">
        <div class="img">
            <i class="material-icons-outlined icon">{{step.icon}}</i>
        </div>
        <div class="row card-content hover-hand" (click)="toggleHidden()">
            <!-- [class]="stepDetails.is_disabled? 'cursor-notallowed':'hover-hand'"  -->
            <div class="title">
                {{step.title}}
            </div>
            <div class="status-bar row w-100">
                <div class="col-4">{{'globaltext.Status_' + stepDetails.step_status_id | translate}}</div>
                <div class="col-5">{{stepDetails?.user?.email}}</div>
                <div class="col-3">{{stepDetails?.modify_time | date : 'MMM d, y'}}</div>
            </div>
        </div>
        <a class="right-img hover-hand" (click)="toggleHidden()">
            <i class="material-icons-outlined icon" *ngIf="hidden">expand_more</i>
            <i class="material-icons-outlined icon" *ngIf="!hidden">expand_less</i>
        </a>
        <div class="expanded" *ngIf="!hidden">
            <hr />
            <div class="row pt-1 detailed-message pl-4 pr-4">
                <p class="mb-0" [innerHTML]="step.description"> </p>
            </div>
            <div class="step-error">
                <div class="button-center d-flex">
                    <button class="btn mt-1 btn-primary text-uppercase" [disabled]="disabledStep" (click)="navigateToPage()">
                        <span>{{step.action_button_text}}</span>
                    </button>
                </div>
                <div class="errorMessage mt-1" *ngIf="disabledStep" [innerHTML]="'globalrichtext.' + errorMessage |
            translate | safeHtml">
                </div>
            </div>
        </div>
    </div>
</div>