<div class="container-fluid full-height" *ngIf="loaded && !showStepStatusOnly">
  <div class="container-fluid full-height">
    <div class="container main-content-container">
      <div class="row" *ngIf="currentStepNo != -1 && totalSteps != 0">
        <p class="steps-page-heading mt-4 d-flex justify-content-center">
          {{
            "globaltext.step"
              | translate
                : {
                    current_step_no: currentStepNo + 1,
                    total_steps: totalSteps
                  }
          }}
        </p>
      </div>
      <div class="row mt-3">
        <p
          class="steps-page-heading-2 d-flex justify-content-center"
          [innerHTML]="current_page_content.title | safeHtml"
        ></p>
      </div>

      <div
        class="row"
        *ngFor="let components of getPageComponentsByPosition('above_content')"
      >
        <app-dynamic-kendo-child
          [component]="components.component"
          [currentStep]="currentStep"
        >
        </app-dynamic-kendo-child>
      </div>

      <div>
        <div
          *ngIf="current_page_content.pagecontent.bordered"
          class="row info-banner mt-3 p-3 mb-5"
          [innerHTML]="current_page_content.pagecontent.text | safeHtml"
        ></div>
        <div
          *ngIf="!current_page_content.pagecontent.bordered"
          class="row p-3 mb-3"
          [innerHTML]="current_page_content.pagecontent.text | safeHtml"
        ></div>
      </div>

      <div
        class="row"
        *ngFor="let components of getPageComponentsByPosition('below_content')"
      >
        <app-dynamic-kendo-child
          [component]="components.component"
          [currentStep]="currentStep"
        >
        </app-dynamic-kendo-child>

        <app-web-table
          *ngIf="components.component == 'WebTableReadonly'"
        ></app-web-table>
        <app-web-table-edit-group
          *ngIf="components.component == 'WebTableEditGroup'"
        ></app-web-table-edit-group>
        <app-upload-file
          *ngIf="components.component == 'UploadFile'"
        ></app-upload-file>
        <app-change-of-address-edit-group
          *ngIf="components.component == 'ReviewChangeOfAddressWeb'"
        >
        </app-change-of-address-edit-group>
        <app-review-change-of-address-file
          *ngIf="components.component == 'ReviewChangeOfAddressFile'"
        ></app-review-change-of-address-file>
        <app-customer-exception-edit-group
          *ngIf="components.component == 'ReviewCustomerExceptionWebTable'"
        ></app-customer-exception-edit-group>
        <app-review-customer-exceptions-file
          *ngIf="components.component == 'ReviewCustomerExceptionFile'"
        ></app-review-customer-exceptions-file>
      </div>

      <div class="mt-4">
        <div
          class="steps-page-heading d-flex justify-content-center grey-heading"
          *ngIf="current_page_content.faqs?.length > 0"
        >
          {{ "globaltext.FrequentlyAskedQuestions" | translate }}
        </div>
        <div class="mt-4">
          <div
            class="row"
            *ngFor="let faq of current_page_content.faqs; let last = last"
          >
            <app-faq-tile
              [Title]="faq.title"
              [Description]="faq.content"
            ></app-faq-tile>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="row"
    *ngFor="let components of getPageComponentsByPosition('bottom')"
  >
    <app-steps-navigation-buttons
      *ngIf="components.component === 'StepNavigator'"
      [nextUrl]="nextUrl"
      [prevUrl]="prevUrl"
    ></app-steps-navigation-buttons>
    <app-steps-navigation-buttons
      [nextButton]="false"
      *ngIf="components.component === 'StepBackNavigator'"
      [prevUrl]="prevUrl"
    >
    </app-steps-navigation-buttons>
    <app-steps-navigation-buttons
      [nextButtonText]="'globaltext.HomeButton' | translate"
      *ngIf="components.component === 'FinalStepNavigator'"
      [nextUrl]="'/home'"
      [prevUrl]="prevUrl"
    >
    </app-steps-navigation-buttons>
    <app-steps-navigation-buttons
      [nextUrl]="nextUrl"
      [prevUrl]="prevUrl"
      *ngIf="components.component === 'StepNavigatorNextDisabled'"
    >
    </app-steps-navigation-buttons>
  </div>
</div>

<div class="container-fluid full-height" *ngIf="loaded && showStepStatusOnly">
  <div class="container-fluid full-height">
    <div class="container main-content-container">
      <div class="row" *ngIf="currentStepNo != -1 && totalSteps != 0">
        <p class="steps-page-heading mt-4 d-flex justify-content-center">
          {{
            "globaltext.step"
              | translate
                : {
                    current_step_no: currentStepNo + 1,
                    total_steps: totalSteps
                  }
          }}
        </p>
      </div>
      <div class="row">
        <p
          class="steps-page-heading-2 mt-3 d-flex justify-content-center"
          [innerHTML]="current_page_content.title | safeHtml"
        ></p>
      </div>
      <div class="row">
        <p
          class="steps-page-heading tile-color mt-3 d-flex justify-content-center b-red"
          [innerHTML]="currentStatusContent"
        ></p>
      </div>
      <div
        class="row danger-text"
        *ngIf="showStepNotAvailable"
        [innerHTML]="'globalrichtext.StepNotAvailable' | translate | safeHtml"
      ></div>
    </div>
  </div>
  <div class="row">
    <app-steps-navigation-buttons
      [nextButtonText]="'globaltext.HomeButton' | translate"
      [backButton]="false"
      [nextUrl]="'/home'"
      [prevUrl]="prevUrl"
    >
    </app-steps-navigation-buttons>
  </div>
</div>
