<div *ngIf="fileGenerationStatus">
  <div class="d-flex justify-content-center pb-2">
    <span *ngIf="fileGenerationStatus.percentage_complete <= 100">{{
      "globaltext.FileGenerationInProgress" | translate
    }}</span>
    <span *ngIf="fileGenerationStatus.percentage_complete > 100">{{
      "globaltext.FileGenerationComplete" | translate
    }}</span>
  </div>
  <div class="d-flex justify-content-center" *ngIf="fileGenerationStatus">
    <kendo-progressbar
      [min]="-1"
      [max]="101"
      [value]="fileGenerationStatus.percentage_complete"
      [label]="{ format: 'percent' }"
    ></kendo-progressbar>
  </div>
</div>

<div class="mt-2 button-center d-flex" *ngIf="availableToDownload">
  <button
    class="btn btn-primary text-uppercase"
    (click)="downloadFile()"
    [disabled]="
      fileGenerationStatus && fileGenerationStatus.percentage_complete <= 100
    "
  >
    <span>{{ "globaltext.DownloadReportButton" | translate }}</span>
  </button>
</div>

<div class="row mt-2">
  <p
    class="mb-0 steps-page-heading d-flex justify-content-center bad"
    *ngIf="!availableToDownload"
    [innerHTML]="errorMessage | safeHtml"
  ></p>
</div>

<div class="d-flex justify-content-center mt-3" *ngIf="error != ''">
  <span
    class="danger-text download-file-error"
    [innerHTML]="'globalrichtext.ErrorDownloadingFile' | translate | safeHtml"
  ></span>
</div>
