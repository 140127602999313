<div class="row mt-2">
    <p class="steps-page-heading tile-color mt-1 d-flex justify-content-center" [class]="stateColor"
        [innerHTML]="subheadingUpdated"></p>
</div>
<div class="d-flex justify-content-center danger-text mt-3" *ngIf="showSectionsBasedOnOptInEndDateReached == '1'"
    [innerHTML]="'globalrichtext.OCROptInEndDateCrossed' | translate | safeHtml">
</div>
<div *ngIf="showSectionsBasedOnOptInEndDateReached == '0'">
    <div class="button-center mb-2 d-flex">
        <a class="btn mt-20px btn-primary text-uppercase" *ngIf="optedValue==0" (click)="showMakeSelectionPopup();">
            <span> {{'globaltext.MakeYourSelectionButton' | translate}}
            </span>
        </a>
        <a class="btn mt-20px btn-primary text-uppercase" *ngIf="optedValue!=0" (click)="showMakeSelectionPopup();">
            <span> {{'globaltext.ChangeYourSelectionButton' | translate}}
            </span>
        </a>
    </div>
</div>
<app-modal-popup [show]="showMakeSelectionPopupValue" leftbutton="NoOptOutButton" rightbutton="YesOptInButton"
    (emitstate)="emittedPendingOption($event)" [modalType]="'common_optin_popup'"
    [showCloseButton]=true [IsGlobalDescription]=false></app-modal-popup>
<app-modal-popup [show]="showOptoutConfirmationPopupValue" leftbutton="ContinueToOptOutButton" rightbutton="OptInButton"
    (emitstate)="emittedOptoutConfirmationPopup($event)" [modalType]="'common_optin_optout_confirmation'"
    [showCloseButton]=true [IsGlobalDescription]=false>
</app-modal-popup>