import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GetStartedComponent implements OnInit {
  @Input()
  engagementName!: string;
  @Output()
  finishSetup = new EventEmitter();

  termsConfirmed = false;

  constructor() {}

  ngOnInit(): void {}
}
