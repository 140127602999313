import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PreloginRoutingModule } from './prelogin-routing.module';
import { PreloginComponent } from './prelogin.component';
import { HeaderPreloginComponent } from 'src/app/components/header-prelogin/header-prelogin.component';
import { SignInComponent } from 'src/app/components/sign-in/sign-in.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from 'src/app/components/forgot-password/forgot-password.component';
import { RequestRegistrationLinkComponent } from 'src/app/components/request-registration-link/request-registration-link.component';
import { ResetPasswordComponent } from 'src/app/components/reset-password/reset-password.component';
import { ForgotPasswordSuccessComponent } from 'src/app/components/forgot-password-success/forgot-password-success.component';
import { RequestRegistrationLinkSuccessComponent } from 'src/app/components/request-registration-link-success/request-registration-link-success.component';
import { ResetPasswordSuccessComponent } from 'src/app/components/reset-password-success/reset-password-success.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RedeemComponent } from 'src/app/components/redeem/redeem.component';
import { RedeemAddUsersComponent } from 'src/app/components/redeem-add-users/redeem-add-users.component';
import { RedeemConfirmationComponent } from 'src/app/components/redeem-confirmation/redeem-confirmation.component';
import { RedeemAddUserComponent } from 'src/app/components/redeem-add-user/redeem-add-user.component';
import { AddEventComponent } from 'src/app/components/add-event/add-event.component';
import { CardErrorComponent } from 'src/app/components/card-error/card-error.component';
import { CompleteRegistrationComponent } from 'src/app/components/complete-registration/complete-registration.component';
import { GetStartedComponent } from 'src/app/components/get-started/get-started.component';
import { ImportantInformationComponent } from 'src/app/components/welcome-steps/important-information/important-information.component';
import { LegalDisclaimerComponent } from 'src/app/components/welcome-steps/legal-disclaimer/legal-disclaimer.component';
import { MobileDeviceNoticeComponent } from 'src/app/components/welcome-steps/mobile-device-notice/mobile-device-notice.component';
import { DomSanitizer } from '@angular/platform-browser';
import { DynamicHtmlComponent } from 'src/app/components/dynamic-html/dynamic-html.component';
import { BrowserCompatibilityComponent } from 'src/app/components/footer-pages/browser-compatibility/browser-compatibility.component';
import { EndUserAgreementComponent } from 'src/app/components/footer-pages/end-user-agreement/end-user-agreement.component';
import { TermsOfUseComponent } from 'src/app/components/footer-pages/terms-of-use/terms-of-use.component';
import { ContactUsComponent } from 'src/app/components/contact-us/contact-us.component';
import { NavigatorServicesModule } from '@cyber/navigator-services';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(value: any) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

@NgModule({
  declarations: [
    PreloginComponent,
    HeaderPreloginComponent,
    SignInComponent,
    ForgotPasswordComponent,
    ForgotPasswordSuccessComponent,
    RequestRegistrationLinkComponent,
    RequestRegistrationLinkSuccessComponent,
    ResetPasswordComponent,
    ResetPasswordSuccessComponent,
    RedeemComponent,
    RedeemAddUserComponent,
    RedeemAddUsersComponent,
    RedeemConfirmationComponent,
    GetStartedComponent,
    ImportantInformationComponent,
    MobileDeviceNoticeComponent,
    LegalDisclaimerComponent,
    AddEventComponent,
    CardErrorComponent,
    CompleteRegistrationComponent,
    SafeHtmlPipe,
    DynamicHtmlComponent,
    TermsOfUseComponent,
    EndUserAgreementComponent,
    BrowserCompatibilityComponent,
    ContactUsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PreloginRoutingModule,
    ReactiveFormsModule,
    NavigatorServicesModule,
  ],
  providers: [],
  exports: [],
})
export class PreloginModule {}
