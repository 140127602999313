<div class="container" *ngIf="displayOutageBanner">
  <div class="banner">
    <div class="title">
      {{ "globaltext.OutageBannerTitle" | translate }}
    </div>
    <div
      class="banner-content"
      [innerHTML]="'globalrichtext.OutageBannerContent' | translate | safeHtml"
    ></div>
  </div>
</div>
