import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationService, SessionService } from '@cyber/navigator-services';
import { LoadingService } from 'src/app/services/loading.service';
import { StorageService } from 'src/app/services/storage.service';
import { appConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-complete-registration',
  templateUrl: './complete-registration.component.html',
  styleUrls: ['./complete-registration.component.scss'],
})
export class CompleteRegistrationComponent implements OnInit {
  public loading = false;
  registrationCode: any;
  public engagementName!: string;
  public invalid = false;
  public email!: string;
  public isAdditionalEvent!: boolean;
  private isAlreadySignedUp!: boolean;
  errorText!: string;
  supportEmail!: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private registrationService: RegistrationService,
    private sessionService: SessionService,
    private loadingService: LoadingService,
    private storageService: StorageService
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this.loading = true;
      this.registrationCode = this.route.snapshot.params[
        'registrationCode'
      ] as string;
      this.loadingService.show();
      const registration = await this.registrationService.fetch(
        this.registrationCode
      );
      this.engagementName = registration.engagement_name;
      this.email = registration.email;
      this.isAdditionalEvent = registration.is_additional_event;
      this.isAlreadySignedUp = registration.is_already_signed_up;
    } catch (err: any) {
      this.loadingService.hide();
      this.invalid = true;
      if (typeof err.error === 'string') this.errorText = err.error;
      this.router.navigateByUrl(`/error/${err.error}`);
    } finally {
      if (
        this.storageService.localStorageTranslationExists(
          appConstants.LanguageEn
        )
      )
        this.loadingService.hide();
      this.loading = false;
    }
  }

  async finishSetup() {
    const state = <RegistrationState>{
      code: this.registrationCode,
      is_additional: this.isAdditionalEvent,
    };
    if (this.isAdditionalEvent) {
      this.sessionService.loginWithRedirect('/select-event', state, this.email);
    } else if (this.isAlreadySignedUp) {
      this.sessionService.loginWithRedirect('/finish-setup', state, this.email);
    } else {
      this.sessionService.register('/finish-setup', state, this.email);
    }
  }
}

export class RegistrationState {
  code!: string;
  is_additional!: boolean;
}
