import { Injectable } from '@angular/core';
import { AppConfigService, CmsContentService } from '@cyber/navigator-services';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { DateHelper } from '../helpers/date.helper';
import { NavigatorConfig } from '../navigator-config';
import { appConstants } from '../shared/app-constants';
import { LoadingService } from './loading.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  private contentLoaded = false;
  private contentLoadedBehaviorSubject = new BehaviorSubject(
    this.contentLoaded
  );

  constructor(
    public loadingService: LoadingService,
    public translateService: TranslateService,
    public cmsContentService: CmsContentService,
    public storageService: StorageService,
    public navigatorConfig: AppConfigService<NavigatorConfig>,
    private _dateHelper: DateHelper
  ) {}

  public replaceContentMonikers(Content: string): string {
    while (Content.indexOf('[icon:') > -1) {
      let icon = Content.substring(
        Content.indexOf('[icon:') + 6,
        Content.indexOf(':icon]')
      );
      Content = Content.replace(
        '[icon:' + icon + ':icon]',
        "<i class='material-icons custom-icon'>" + icon + '</i>'
      );
    }
    while (Content.indexOf('[outlineicon:') > -1) {
      let icon = Content.substring(
        Content.indexOf('[outlineicon:') + 13,
        Content.indexOf(':outlineicon]')
      );
      Content = Content.replace(
        '[outlineicon:' + icon + ':outlineicon]',
        "<i class='material-icons-outlined custom-icon'>" + icon + '</i>'
      );
    }
    return Content;
  }

  public async loadCmsContent(reload: boolean = false) {
    this.contentLoadedBehaviorSubject.next(false);

    let lang = 'en';
    let currentEngagementId = 0;
    let currentVariationId = 0;
    let dataOwnerDetails: any;
    let dataOwnerDetailsString = this.storageService.getLocalStorage(
      appConstants.DataOwner
    );

    if (dataOwnerDetailsString != '' && dataOwnerDetailsString != undefined) {
      dataOwnerDetails = JSON.parse(dataOwnerDetailsString);
      currentEngagementId = dataOwnerDetails?.engagement_id;
      currentVariationId = dataOwnerDetails?.engagement_cms_variation_id;
    }

    let contentCacheKey =
      appConstants.ContentLastModified +
      '-' +
      currentEngagementId +
      '-' +
      currentVariationId;
    let cacheLastModified =
      this.storageService.getLocalStorage(contentCacheKey);

    if (cacheLastModified == null || cacheLastModified == '') {
      reload = true;
    } else {
      let lastModifiedDate =
        await this.cmsContentService.getEngagementContentLastModified();
      if (
        this._dateHelper.isFirstDateAfterSecondDate(
          lastModifiedDate,
          cacheLastModified
        )
      )
        reload = true;
    }

    if (
      !reload &&
      this.storageService.localStorageTranslationExists(`language-${lang}`)
    ) {
      let data = this.storageService.getLocalStorage(`language-${lang}`);
      this.translateService.setTranslation(lang, JSON.parse(data));
      this.contentLoadedBehaviorSubject.next(true);
      this.loadingService.hide();
    } else {
      this.cmsContentService
        .getPageContent(lang)
        .then((jsonData: any) => {
          let jsonString = JSON.stringify(jsonData.data);
          //replace engagement name from localstorage

          jsonString = jsonString.replace(
            /{{FromEmail}}/g,
            appConstants.FromEmail
          );
          jsonString = jsonString.replace(
            /{{ContactEmail}}/g,
            appConstants.ContactEmail
          );

          let imageAssetIds = [];

          let appConfig = this.navigatorConfig.getConfig();
          if (jsonString.indexOf(appConfig.cmsAssetUrl) > -1) {
            let cmsAssetUrl =
              '<a href=\\\\"\\' +
              appConfig.cmsAssetUrl +
              '/(.*?)/(.*?)\\\\"(?:.*?)[ ]*(.*?)>(.*?)</a>*';
            let replaceWith =
              '<a href=\\"cms-file/$1/$2\\" target=\\"_blank\\">$4</a>';

            let regexCmsAssetUrl = new RegExp(cmsAssetUrl);
            while (jsonString.match(regexCmsAssetUrl))
              jsonString = jsonString.replace(regexCmsAssetUrl, replaceWith);

            let cmsAssetImgUrl =
              '<img src=\\\\"\\' +
              appConfig.cmsAssetUrl +
              '/(.*?)/(?:.*?)\\\\"(?:.*?)[ ]+(.*?)/>*';
            let replaceWithImg = '<img src=\\"$1\\" $2 />';

            let regexCmsAssetImgUrl = new RegExp(cmsAssetImgUrl);
            while (jsonString.match(regexCmsAssetImgUrl)) {
              let regexMatches = jsonString.match(regexCmsAssetImgUrl);
              if (regexMatches) imageAssetIds.push(regexMatches[1]);
              jsonString = jsonString.replace(
                regexCmsAssetImgUrl,
                replaceWithImg
              );
            }
          }
          jsonString = this.replaceContentMonikers(jsonString);
          if (
            dataOwnerDetailsString != '' &&
            dataOwnerDetailsString != undefined
          ) {
            jsonString = jsonString.replace(
              /{{EngagementName}}/g,
              dataOwnerDetails?.engagement_entity?.display_name
            );

            //replace optin enddate from cookie
            let optinEndDate = this.storageService.getLocalStorage(
              appConstants.cookieOptInEndDate
            );
            jsonString = jsonString.replace(/{{OptInEndDate}}/g, optinEndDate);

            let optinStartDate = this.storageService.getLocalStorage(
              appConstants.cookieOptInStartDate
            );
            jsonString = jsonString.replace(
              /{{OptInStartDate}}/g,
              optinStartDate
            );
          }

          this.storageService.setLocalStorage(`language-${lang}`, jsonString);
          this.storageService.setLocalStorage(
            contentCacheKey,
            jsonData.last_modified_date
          );
          this.replaceImageAssets(imageAssetIds, lang);

          this.translateService.setTranslation(lang, JSON.parse(jsonString));
          this.contentLoadedBehaviorSubject.next(true);
          this.loadingService.hide();
        })
        .catch((error: any) => {
          console.error(error);
        });
    }
  }

  async replaceImageAssets(assetIds: string[], lang: string) {
    let translationContent = this.storageService.getLocalStorage(
      `language-${lang}`
    );
    assetIds.forEach(async (assetId: string) => {
      let response = await this.replaceImageAsset(assetId, lang);
      let originalValue = new RegExp(`${assetId}`);
      let valueToReplace = response;
      translationContent = translationContent.replace(
        originalValue,
        valueToReplace
      );
      this.storageService.setLocalStorage(
        `language-${lang}`,
        translationContent
      );
      this.translateService.setTranslation(
        lang,
        JSON.parse(translationContent)
      );
    });
  }
  async replaceImageAsset(assetId: string, lang: string) {
    const dataBlob = await this.cmsContentService.getAssetById(assetId);
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(dataBlob);
    });
  }

  public getContentState() {
    return this.contentLoadedBehaviorSubject;
  }
}
