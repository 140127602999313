import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AppConfigService,
  BaseAppConfig,
  CmsContentService,
} from '@cyber/navigator-services';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-cms-file-download',
  templateUrl: './cms-file-download.component.html',
  styleUrls: ['./cms-file-download.component.scss'],
})
export class CmsFileDownloadComponent implements OnInit {
  constructor(
    public activatedRoute: ActivatedRoute,
    public cmsContentService: CmsContentService,
    public appConfigService: AppConfigService<BaseAppConfig>,
    public http: HttpClient,
    public loading: LoadingService
  ) {
    this.activatedRoute.params.subscribe(async (data: any) => {
      if (data['id']) {
        this.loading.show();
        await this.downloadFile(data['id'], data['filename']);
      }
    });
  }
  async downloadFile(assetId: any, fileName: string) {
    var arrayBuffer: any = await this.cmsContentService.getAssetById(assetId);
    var link = document.createElement('a');
    var file = new Blob([arrayBuffer]);
    let downloadLink = document.createElement('a');
    downloadLink.setAttribute('download', fileName);
    downloadLink.href = window.URL.createObjectURL(file);
    downloadLink.click();
    this.loading.hide();
    window.close();
  }

  ngOnInit(): void {}
}
