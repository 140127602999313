import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss']
})
export class LoadingModalComponent implements OnInit {

  show = false;
  constructor(public loadingService: LoadingService) {
    this.loadingService.getLoading().subscribe((data:any)=>{
      this.show = data;
    });
   }

  ngOnInit(): void {
  }

}
