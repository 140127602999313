import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-prelogin',
  templateUrl: './header-prelogin.component.html',
  styleUrls: ['./header-prelogin.component.scss']
})
export class HeaderPreloginComponent implements OnInit {

  clientLogoUrl = '';
  constructor() { }

  ngOnInit(): void {
    //this.clientLogoUrl = "../../../assets/logo-kroll.png";
  }

}
