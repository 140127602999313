import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { appConstants } from 'src/app/shared/app-constants';
import { RegistrationService } from '@cyber/navigator-services';
import { RedeemUserWrapper } from 'src/app/models/redeem';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-redeem-add-users',
  templateUrl: './redeem-add-users.component.html',
  styleUrls: ['./redeem-add-users.component.scss'],
})
export class RedeemAddUsersComponent implements OnInit {
  // formGroup: FormGroup;
  showAddUser: boolean = true;
  status!: string;
  errorMessage!: string;
  public users: RedeemUserWrapper[] = [
    {
      id: 1,
      first_name: '',
      last_name: '',
      email: '',
      isValid: false,
    },
  ];

  public formGroup: FormGroup = new FormGroup({
    userDetailsComponent: new FormControl(''),
  });

  timeOutTimerId: any;
  timedOut = false;
  idleState = 'Not started.';
  public sessionCheckTimerId: any;
  eventsSubject: Subject<void> = new Subject<void>();
  pageCms: any;

  //@ViewChild(RedeemAddUserComponent)
  //private redeemAddUserComponent!: RedeemAddUserComponent;
  constructor(
    private _registrationService: RegistrationService,
    private _router: Router,
    private _cookieService: CookieService,
    private translate: TranslateService,
    private loading: LoadingService
  ) {}

  ngOnInit(): void {
    const cmsPageData = this.translate.instant(`page`);
    this.pageCms = cmsPageData['Redeem_AddUsers'];
    this.errorMessage = '';
  }

  addUser() {
    if (this.users.length < 3) {
      this.users.push({
        id: this.users.length + 1,
        first_name: '',
        last_name: '',
        email: '',
        isValid: false,
      });
    }
    if (this.users.length > 2) {
      this.showAddUser = false;
    }
  }

  removeUser(i: number) {
    this.users.splice(i, 1);
    this.showAddUser = true;
  }

  updateUserDetails(user: RedeemUserWrapper) {
    this.users[user.id - 1] = user;
  }
  getCookie(name: string): string {
    return this._cookieService.get(name);
  }

  validateEmails(data: RedeemUserWrapper[]) {
    const unique = [new Set(data.map((item) => item.email?.toLowerCase()))];
    if (unique.length > 0 && unique[0].size != data.length) {
      return false;
    } else return true;
  }

  async submit() {
    var data = this.users;
    this.eventsSubject.next();
    if (data.filter((x) => x.isValid == false).length > 0) {
      return;
    }
    var emailCheck = this.validateEmails(data);
    this.status = 'loaded';
    this.errorMessage = '';
    if (emailCheck) {
      const token = this._cookieService.get(appConstants.sessionCookieObject);
      if (token) {
        try {
          this.loading.show();
          await this._registrationService.redeemRegisterUsers(token, data);
          this.loading.hide();
          this._router.navigate(['redeem/confirmation']);
        } catch (err: any) {
          if (err.error === 'Invalid AuthToken')
            this.errorMessage = 'RedeemUsersInvalidAccessCode';
          else this.errorMessage = 'RedeemUsersErrorAddingUsers';
        } finally {
          this.loading.hide();
          this.status = 'loaded';
        }
      } else {
        this.errorMessage = 'RedeemUsersInvalidAlreadyUsersAccessCode';
      }
    } else {
      this.status = 'loaded';
      this.errorMessage = 'UniqueEmailError';
    }
  }
}
