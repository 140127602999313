import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DigicertService } from 'src/app/services/digicert.service';

@Component({
  selector: 'app-digicert',
  templateUrl: './digicert.component.html',
  styleUrls: ['./digicert.component.scss'],
})
export class DigicertComponent implements OnInit, AfterViewInit {
  elementIdStatic = 'DigiCertClickID_';

  public sealCode!: string;
  elementIdDynamic!: string;
  testSealClass = '';

  constructor(private digiService: DigicertService) {}

  ngOnInit() {
    this.getSealCodeByUrl();
    this.digiService.digiCertHeadScripts(this.sealCode);
  }

  ngAfterViewInit() {}

  getSealCodeByUrl() {
    const url = window.location.host.toLowerCase();
    this.testSealClass = '';
    if (url.endsWith('krollnotification.com')) {
      this.sealCode = 'I6ImwkPn';
    } else {
      this.testSealClass = 'allow-test-seal';
      this.sealCode = 'I6ImwkPn';
    }
    this.elementIdDynamic = this.elementIdStatic + this.sealCode;
  }
}
