import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RegionalDataService } from 'src/app/services/regional-data.service';
import { appConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-forgot-password-success',
  templateUrl: './forgot-password-success.component.html',
  styleUrls: ['./forgot-password-success.component.scss']
})
export class ForgotPasswordSuccessComponent implements OnInit {

  constructor(public regionalDataService: RegionalDataService) {

  }

  ngOnInit(): void {
  }
  

}
