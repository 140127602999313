<div class="center-container" *ngIf="loading; else loaded">
  <div class="card center-card">
    <app-loading-spinner></app-loading-spinner>
  </div>
</div>
<ng-template #loaded>
  <ng-container>
    <app-get-started
      *ngIf="isAdditionalEvent === false"
      [engagementName]="engagementName"
      (finishSetup)="finishSetup()"
    ></app-get-started>
    <app-add-event
      *ngIf="isAdditionalEvent === true"
      [engagementName]="engagementName"
      (finishSetup)="finishSetup()"
    ></app-add-event>
  </ng-container>
</ng-template>
