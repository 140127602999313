import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dynamic-kendo-child',
  templateUrl: './dynamic-kendo-child.component.html',
  styleUrls: ['./dynamic-kendo-child.component.scss']
})
export class DynamicKendoChildComponent implements OnInit {


  @Input() component!: any;
  @Input() currentStep!: any;
  constructor() { }

  ngOnInit(): void {
  }

}
