import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss']
})
export class SvgComponent implements OnInit {
  svgPath !: string;
  @Input() type!:string;
  constructor() {
   }

  ngOnInit(): void {
    this.svgPath = `../../assets/${this.type}.svg`;
  }

}
