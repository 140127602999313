import { Injectable } from '@angular/core';
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private _cookieService: CookieService) { }

  setCookie(name: string, value: string) {
    const isLocal = window.location.hostname === "localhost";
    const domain = this.getDomain();
    this._cookieService.set(
      name,
      value,
      new Date(new Date().getTime() + (1000 * 60 * 15)),
      "/",
      domain,
      !isLocal
    );
  }

  removeCookie(key: string) {
    this._cookieService.delete(key);
  }

  setLocalStorage(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  getLocalStorage(key: string): any {
    return localStorage.getItem(key);
  }

  removeLocalStorage(key: string) {
    localStorage.removeItem(key);
  }

  localStorageTranslationExists(key: string): boolean {
    return localStorage.getItem(key) != null && localStorage.getItem(key) != 'undefined' && localStorage.getItem(key) != '' && localStorage.getItem(key) != '{}';
  }

  clearLocalStorage() {
    localStorage.clear();
  }

  setCookieForLongerDuration(name: string, value: string) {
    const isLocal = window.location.hostname === "localhost";
    const domain = this.getDomain();
    this._cookieService.set(
      name,
      value,
      new Date(new Date().getTime() + (1000 * 60 * 60 * 24)),
      "/",
      domain,
      !isLocal
    );
  }

  setSessionStorage(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  getSessionStorage(key: string): any {
    return sessionStorage.getItem(key) ?? '';
  }

  sessionStorageTranslationExists(key: string): boolean {
    return sessionStorage.getItem(key) != null && sessionStorage.getItem(key) != 'undefined' && sessionStorage.getItem(key) != '' && sessionStorage.getItem(key) != '{}';
  }
  removeSessionStorage(key: string) {
    sessionStorage.removeItem(key);
  }

  clearSessionStorage() {
    sessionStorage.clear();
  }

  getDomain(): string {
    const isLocal = window.location.hostname === "localhost";
    let domain = isLocal ? (this.isIEBrowser() ? "" : "localhost") : window.location.hostname;
    //Change to get dynamic sub domain/domain  for US region
    if (!isLocal) {
      const firstDot = window.location.hostname.indexOf('.');
      domain = window.location.hostname.substring(firstDot == -1 ? 0 : firstDot);
    }
    return domain;
  }

  isIEBrowser() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      return true;
    }
    return false;
  }


  getCookie(name: string): string {
    return this._cookieService.get(name);
  }

  cookieExists(name: string): boolean {
    return this._cookieService.check(name);
  }
}
