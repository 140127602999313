<div class="center-container">
  <div class="card center-card">
    <section class="consent-header text-center">
      <div class="content-container">
        <app-svg [type]="'nnlogo'"></app-svg>
      </div>
      <h2>{{ "globaltext.AddEventHeading" | translate }}</h2>
    </section>
    <section class="main-content text-center">
      <div class="row">
        <div
          class="description"
          [innerHTML]="
            'globalrichtext.AddEventContent'
              | translate: { EngagementName: engagementName }
              | safeHtml
          "
        ></div>
      </div>
      <div class="row">
        <div class="col d-flex">
          <input
            type="checkbox"
            class="hover-hand custom-checkbox justify-content-center"
            id="checkbox"
            [(ngModel)]="termsConfirmed"
          />
          <div
            class="description"
            [innerHTML]="
              'globalrichtext.TermsAcceptanceContent'
                | translate
                | safeHtml
            "
          ></div>
        </div>
      </div>
    </section>
    <section class="button-row mt-5 center">
      <button
        type="button"
        class="btn save btn-primary"
        [class.disabled]="!termsConfirmed"
        (click)="finishSetup.emit()"
      >
        <span>{{
          "globaltext.ConfirmAddEventToMyAccountButton" | translate
        }}</span>
      </button>
    </section>
  </div>
</div>
