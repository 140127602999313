import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-switch-to-file-upload',
  templateUrl: './switch-to-file-upload.component.html',
  styleUrls: ['./switch-to-file-upload.component.scss'],
})
export class SwitchToFileUploadComponent implements OnInit {

  
  constructor() { }

  ngOnInit(): void {
  }

}
