<div
  class="d-flex justify-content-center danger-text mt-3"
  *ngIf="showSectionsBasedOnOptInEndDateReached == '1'"
  [innerHTML]="
    'globalrichtext.CustomerUpdateOptInEndDateCrossed' | translate | safeHtml
  "
></div>
<div [class]="showSectionsBasedOnOptInEndDateReached == '0' ? '' : 'd-none'">
  <div
    *ngIf="state !== 'fileSaved' && state !== 'noChangesToMake'"
    class="row subheading-18p tile-color mt-1"
    [innerHTML]="switchToWebReview | translate | safeHtml"
  ></div>
  <div class="button-center d-flex mt-2" *ngIf="loadButtonText">
    <a
      class="btn btn-primary text-uppercase"
      (click)="loadButtonClick()"
      [class]="!hideResumeButton ? '' : 'd-none'"
    >
      <span>{{ loadButtonText }}</span>
    </a>
  </div>

  <div class="row mt-2">
    <p
      class="steps-page-heading tile-color mt-3 d-flex justify-content-center"
      [class]="stateColor"
      [innerHTML]="subheadingUpdated"
    ></p>
  </div>
  <div class="row confirmation-file" *ngIf="state === 'selectionPending'">
    <p
      class="steps-page-heading tile-color"
      [innerHTML]="selectionPendingTitle"
    ></p>
    <div class="two-buttons">
      <a
        class="btn mt-20px btn-primary-outline text-uppercase"
        (click)="makeChangesClick(false)"
      >
        <span>{{ "globaltext.NoButton" | translate }}</span>
      </a>
      <a
        class="btn mt-20px btn-primary text-uppercase"
        (click)="makeChangesClick(true)"
      >
        <span>{{ "globaltext.YesButton" | translate }}</span>
      </a>
    </div>
  </div>
  <div
    class="file-upload-div mt-5 d-flex"
    [class]="state == 'inProgress' ? '' : 'd-none'"
  >
    <div class="uploadfile-div">
      <div
        class="close-button hover-hand"
        *ngIf="showFileUpload"
        (click)="closeFileUpload()"
      >
        <div class="group-items-center">
          <span class="material-icons-outlined"> close </span>
          <span>
            {{ "globaltext.CloseButton" | translate }}
          </span>
        </div>
      </div>
      <div class="image-and-close-button">
        <app-svg [type]="'file-upload'"></app-svg>
      </div>
      <div class="steps-page-heading">Upload File</div>
      <div class="wrapper">
        <div
          class="custom-dropzone"
          kendoUploadDropZone="myZone"
          *ngIf="showFileUpload"
        >
          <p>Drag file here or browse</p>
        </div>
        <kendo-upload
          #upload
          zoneId="myZone"
          [saveUrl]="uploadSaveUrl"
          [removeUrl]="uploadRemoveUrl"
          [multiple]="false"
          (select)="onSelectEvent($event)"
          (success)="onUpload($event)"
          [restrictions]="allowedFileFormat"
          (remove)="onRemoveEvent($event, upload)"
        >
          <kendo-upload-messages
            select="Browse"
            dropFilesHere=""
          ></kendo-upload-messages>
          <kendo-uploaddropzone-messages externalDropFilesHere="test">
          </kendo-uploaddropzone-messages>
        </kendo-upload>
      </div>
      <a
        class="btn mt-2 mb-2 btn-primary text-uppercase"
        *ngIf="fileUploaded"
        (click)="saveFileClick(upload)"
      >
        <span>{{ "globaltext.SaveButton" | translate }}</span>
      </a>
    </div>
  </div>
  <div *ngIf="state == 'inProgress' || state === 'selectionPending'">
    <div class="row d-flex justify-content-center mt-2 mb-2">
      <a
        class="secondary-link font-sans link-with-image"
        (click)="loadedTip = 'file-tips'"
      >
        <span class="material-icons pr-1"> info </span>
        <span [innerHTML]="'globaltext.FileQuickTipsTitle' | translate"></span>
      </a>
    </div>
    <app-tips
      [show]="loadedTip == 'file-tips'"
      (closestate)="loadedTip = ''"
      [pageType]="pageType"
    ></app-tips>
  </div>

  <div class="row" *ngIf="fileUploadErrorMsg">
    <b class="danger-text d-flex justify-content-center">{{
      "globaltext.FileUploadFailed" | translate
    }}</b>
    <ul class="no-list">
      <li *ngFor="let error of fileUploadErrorMsg">
        <span class="d-flex justify-content-center danger-text">{{
          error
        }}</span>
      </li>
    </ul>
  </div>

  <div class="d-flex justify-content-center mt-3" *ngIf="showDefaultErrorMsg">
    <span
      class="danger-text download-file-error"
      [innerHTML]="'globalrichtext.ErrorUploadingFile' | translate | safeHtml"
    ></span>
  </div>
  <app-customer-file-details></app-customer-file-details>
</div>
<app-modal-popup
  [show]="showNoChangesPopup"
  leftbutton="CloseButton"
  rightbutton="ConfirmButton"
  (emitstate)="emittedNoChangesPopup($event)"
  [modalType]="'ConfirmNoWebChangesContent'"
></app-modal-popup>
<app-modal-popup
  [show]="showConfirmChangesPopup"
  leftbutton="CloseButton"
  rightbutton="ContinueButton"
  (emitstate)="emittedConfirmChangesPopup($event, upload)"
  [modalType]="modalType"
></app-modal-popup>
