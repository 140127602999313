import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Tip, TipsCard } from 'src/app/models/tips';

@Component({
  selector: 'app-tips',
  templateUrl: './tips.component.html',
  styleUrls: ['./tips.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TipsComponent implements OnInit {

  tipDetails!:TipsCard;
  loaded=false;
  showOverlay=false;
  @Input() show!:boolean;
  @Input() pageType!:string;
  @Output() closestate: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(public translate: TranslateService) {
   }

  ngOnInit(): void {
    this.loaded=true;
  }


  closeTip(){
    this.closestate.emit(true);
  }

}
