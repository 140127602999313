import { Component, Input, OnInit } from '@angular/core';
import { OutageService } from '@cyber/navigator-services';

@Component({
  selector: 'app-maintenance-banner',
  templateUrl: './maintenance-banner.component.html',
  styleUrls: ['./maintenance-banner.component.scss'],
})
export class MaintenanceBannerComponent implements OnInit {
  @Input() message = '';
  displayOutageBanner = false;

  constructor(private outageService: OutageService) {}

  ngOnInit(): void {
    this.outageService.outageObservable$.subscribe((outageInfo) => {
      this.displayOutageBanner = outageInfo?.display_outage;
    });
  }
}
