<div class="row mt-2">
    <p class="steps-page-heading tile-color mt-1 d-flex justify-content-center" [class]="stateColor"
        [innerHTML]="subheadingUpdated"></p>
</div>
<div class="d-flex justify-content-center danger-text mt-3" *ngIf="showSectionsBasedOnOptInEndDateReached == '1'"
    [innerHTML]="'globalrichtext.OCROptInEndDateCrossed' | translate | safeHtml">
</div>
<div *ngIf="showSectionsBasedOnOptInEndDateReached == '0'">

    <div class="button-center mb-2 d-flex">
        <a class="btn mt-20px btn-primary text-uppercase" *ngIf="optedValue==0" (click)="showMakeSelectionPopup();">
            <span> {{'globaltext.MakeYourSelectionButton' | translate}}
            </span>
        </a>
        <a class="btn mt-20px btn-primary text-uppercase" *ngIf="optedValue!=0" (click)="showMakeSelectionPopup();">
            <span> {{'globaltext.ChangeYourSelectionButton' | translate}}
            </span>
        </a>
    </div>
    <form [formGroup]="fg" *ngIf="editable">
        <div class="row thirdparty-form">
            <div class="d-flex justify-content-center h1-heading grey-heading">
                {{'text.FormCollectionTitle' | translate}}
            </div>
            <div class="mt-2 grey-message mb-4">
                {{'text.FormCollectionDescription' | translate}}
            </div>
            <div class="form-group">
                <label class="grey-message">{{'globaltext.NameOfCoveredEntityLabel' | translate}}</label>
                <input type="text" class="form-control"
                    placeholder="{{'globaltext.NameOfCoveredEntityPlaceholder' | translate}}"
                    formControlName="nameOfCoveredEntity">
                <span class="helptext">{{'globaltext.NameOfCoveredEntityHelpText' | translate}}
                </span>
                <p class="explanation"
                    *ngIf="nameOfCoveredEntity?.invalid && (nameOfCoveredEntity?.dirty || nameOfCoveredEntity?.touched)">
                    <span class="form-text bad"
                        *ngIf="nameOfCoveredEntity?.errors?.required">{{'globaltext.NameOfCoveredEntityRequiredMsg'
                        | translate}}</span>
                    <span class="form-text bad"
                        *ngIf="nameOfCoveredEntity?.errors?.pattern || nameOfCoveredEntity?.errors?.maxlength">{{'globaltext.NameOfCoveredEntityInvalidMsg'
                        | translate}}</span>
                </p>
            </div>
            <div class="form-group mt-1">
                <label class="grey-message">{{'globaltext.StreeAddress1Label' | translate}}</label>
                <input type="text" class="form-control"
                    placeholder="{{'globaltext.StreeAddress1Placeholder' | translate}}"
                    formControlName="streetAddress1">
                <p class="explanation"
                    *ngIf="streetAddress1?.invalid && (streetAddress1?.dirty || streetAddress1?.touched)">
                    <span class="form-text bad"
                        *ngIf="streetAddress1?.errors?.required">{{'globaltext.StreeAddress1RequiredMsg' |
                        translate}}</span>
                    <span class="form-text bad"
                        *ngIf="streetAddress1?.errors?.pattern || streetAddress1?.errors?.maxlength">{{'globaltext.AddressInvalidMsg'
                        |
                        translate}}</span>
                </p>
            </div>
            <div class="form-group mt-1">
                <span class="label-row">
                    <label class="grey-message mt-10">{{'globaltext.StreeAddress2Label' | translate}}</label>
                    <span class=" optional grey-message">({{'globaltext.Optional' | translate}})</span>
                </span>
                <input type="text" class="form-control"
                    placeholder="{{'globaltext.StreeAddress2Placeholder' | translate}}"
                    formControlName="streetAddress2">
                <p class="explanation"
                    *ngIf="streetAddress2?.invalid && (streetAddress2?.dirty || streetAddress2?.touched)">
                    <span class="form-text bad"
                        *ngIf="streetAddress2?.errors?.required">{{'globaltext.StreeAddress2RequiredMsg' |
                        translate}}</span>
                    <span class="form-text bad"
                        *ngIf="streetAddress2?.errors?.pattern || streetAddress2?.errors?.maxlength">{{'globaltext.AddressInvalidMsg'
                        |
                        translate}}</span>
                </p>
            </div>
            <div class="form-group mt-1">
                <label class="grey-message">{{'globaltext.CityLabel' | translate}}</label>
                <input type="text" class="form-control" placeholder="{{'globaltext.CityPlaceholder' | translate}}"
                    formControlName="city">
                <p class="explanation" *ngIf="city?.invalid && (city?.dirty || city?.touched)">
                    <span class="form-text bad" *ngIf="city?.errors?.required">{{'globaltext.CityRequiredMsg' |
                        translate}}</span>
                    <span class="form-text bad"
                        *ngIf="city?.errors?.pattern || city?.errors?.maxlength">{{'globaltext.CityInvalidMsg' |
                        translate}}</span>

                </p>
            </div>
            <div class="mt-1 no-margin-row row">
                <div class="col-6">
                    <div class="form-group mt-1">
                        <label>{{'globaltext.StateLabel' | translate}}</label>
                        <select class="form-control" formControlName="state" (change)="checkSelectedValue(state)"
                            [ngClass]="{ 'is-invalid': (state?.touched) && state?.errors }">
                            <option value="-1">{{'globaltext.Select' | translate}}</option>
                            <option *ngFor="let s of states" [value]="s?.id">{{s?.name}}</option>
                        </select>
                        <p class="explanation" *ngIf="state?.invalid && (state?.dirty || state?.touched)">
                            <span class="form-text bad" *ngIf="state?.errors?.required">{{'globaltext.StateRequiredMsg'
                                |
                                translate}}</span>
                        </p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group mt-1">
                        <label class="grey-message">{{'globaltext.ZipCodeLabel' | translate}}</label>
                        <input type="text" class="form-control"
                            placeholder="{{'globaltext.ZipCodePlaceholder' | translate}}" formControlName="zipCode">
                        <p class="explanation" *ngIf="zipCode?.invalid && (zipCode?.dirty || zipCode?.touched)">
                            <span class="form-text bad"
                                *ngIf="zipCode?.errors?.required">{{'globaltext.ZipCodeRequiredMsg'
                                | translate}}</span>
                            <span class="form-text bad"
                                *ngIf="zipCode?.errors?.pattern">{{'globaltext.ZipCodeInvalidMsg'
                                | translate}}</span>

                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row thirdparty-form mt-5">
            <div class="d-flex justify-content-center h1-heading grey-heading">
                {{'text.FormCollectionTitle2' | translate}}
            </div>
            <div class="mt-2 grey-message mb-4">
            </div>
            <div class="mt-1 no-margin-row row">
                <div class="col-6">
                    <div class="form-group mt-1">
                        <label class="grey-message">{{'globaltext.FirstNameLabel' | translate}}</label>
                        <input type="text" class="form-control"
                            placeholder="{{'globaltext.FirstNamePlaceholder' | translate}}" formControlName="firstName">
                        <p class="explanation" *ngIf="firstName?.invalid && (firstName?.dirty || firstName?.touched)">
                            <span class="form-text bad"
                                *ngIf="firstName?.errors?.required">{{'globaltext.FirstNameRequiredMsg' |
                                translate}}</span>
                            <span class="form-text bad"
                                *ngIf="firstName?.errors?.pattern || firstName?.errors?.maxlength">{{'globaltext.FirstNameInvalidMsg'
                                |
                                translate}}</span>
                        </p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group mt-1">
                        <label class="grey-message">{{'globaltext.LastNameLabel' | translate}}</label>
                        <input type="text" class="form-control"
                            placeholder="{{'globaltext.LastNamePlaceholder' | translate}}" formControlName="lastName">
                        <p class="explanation" *ngIf="lastName?.invalid && (lastName?.dirty || lastName?.touched)">
                            <span class="form-text bad"
                                *ngIf="lastName?.errors?.required">{{'globaltext.LastNameRequiredMsg' |
                                translate}}</span>
                            <span class="form-text bad"
                                *ngIf="lastName?.errors?.pattern || lastName?.errors?.maxlength">{{'globaltext.LastNameInvalidMsg'
                                |
                                translate}}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="form-group mt-1">
                <label class="grey-message">{{'globaltext.EmailLabel' | translate}}</label>
                <input type="text" class="form-control" placeholder="{{'globaltext.EmailPlaceholder' | translate}}"
                    formControlName="email">
                <p class="explanation" *ngIf="email?.invalid && (email?.dirty || email?.touched)">
                    <span class="form-text bad" *ngIf="email?.errors?.required">{{'globaltext.EmailRequiredMsg' |
                        translate}}</span>
                    <span class="form-text bad" *ngIf="email?.errors?.pattern">{{'globaltext.EmailPatternMsg' |
                        translate}}</span>
                </p>
            </div>
            <div class="mt-1 no-margin-row row">
                <div class="col-6">
                    <div class="form-group mt-1">
                        <label class="grey-message">{{'globaltext.PrimaryPhoneLabel' | translate}}</label>
                        <input type="text" class="form-control"
                            placeholder="{{'globaltext.PrimaryPhonePlaceholder' | translate}}"
                            (keyup)="formatAsPhone(primaryPhone, $event)" formControlName="primaryPhone">
                        <p class="explanation"
                            *ngIf="primaryPhone?.invalid && (primaryPhone?.dirty || primaryPhone?.touched)">
                            <span class="form-text bad"
                                *ngIf="primaryPhone?.errors?.required">{{'globaltext.PrimaryPhoneRequiredMsg' |
                                translate}}</span>
                            <span class="form-text bad"
                                *ngIf="primaryPhone?.errors?.pattern || secondaryPhone?.errors?.maxlength">{{'globaltext.PrimaryPhoneInvalidMsg'
                                |
                                translate}}</span>
                        </p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group mt-1">
                        <label class="grey-message">{{'globaltext.PrimaryPhoneTypeLabel' | translate}}</label>
                        <select class="form-control" formControlName="primaryPhoneType"
                            (change)="checkSelectedValue(primaryPhoneType)"
                            [ngClass]="{ 'is-invalid': (primaryPhoneType?.touched) && primaryPhoneType?.errors }">
                            <option value="-1">{{'globaltext.Select' | translate}}</option>
                            <option *ngFor="let phoneType of  phoneTypes" [value]="phoneType.id">{{phoneType.name}}
                            </option>
                        </select>
                        <p class="explanation"
                            *ngIf="primaryPhoneType?.invalid && (primaryPhoneType?.dirty || primaryPhoneType?.touched)">
                            <span class="form-text bad"
                                *ngIf="primaryPhoneType?.errors?.required">{{'globaltext.PrimaryPhoneTypeRequiredMsg' |
                                translate}}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="mt-1 no-margin-row row">
                <div class="col-6">
                    <div class="form-group mt-1">
                        <span class="label-row">
                            <label class="grey-message mt-10">{{'globaltext.SecondaryPhoneLabel' | translate}}</label>
                            <span class=" optional grey-message">({{'globaltext.Optional' | translate}})</span>
                        </span>
                        <input type="text" class="form-control" (keyup)="formatAsPhone(secondaryPhone, $event)"
                            placeholder="{{'globaltext.SecondaryPhonePlaceholder' | translate}}"
                            formControlName="secondaryPhone">
                        <p class="explanation"
                            *ngIf="secondaryPhone?.invalid && (secondaryPhone?.dirty || secondaryPhone?.touched)">
                            <span class="form-text bad"
                                *ngIf="secondaryPhone?.errors?.required">{{'globaltext.SecondaryPhoneRequiredMsg' |
                                translate}}</span>
                            <span class="form-text bad"
                                *ngIf="secondaryPhone?.errors?.pattern || secondaryPhone?.errors?.maxlength">{{'globaltext.SecondaryPhoneInvalidMsg'
                                |
                                translate}}</span>
                        </p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group mt-1">
                        <span class="label-row">
                            <label class="grey-message mt-10">{{'globaltext.SecondaryPhoneTypeLabel' |
                                translate}}</label>
                            <span class=" optional grey-message">({{'globaltext.Optional' | translate}})</span>
                        </span>
                        <select class="form-control" formControlName="secondaryPhoneType"
                            [ngClass]="{ 'is-invalid': (secondaryPhoneType?.touched) && secondaryPhoneType?.errors }">
                            <option value="-1">{{'globaltext.Select' | translate}}</option>
                            <option *ngFor="let phoneType of  phoneTypes" [value]="phoneType.id">{{phoneType.name}}
                            </option>
                        </select>

                    </div>
                </div>
            </div>
        </div>
        <div class="button-center d-flex mt-5">
            <a class="btn mt-20px btn-primary text-uppercase" (click)="onSubmit()">
                <span>{{'globaltext.SubmitButton' | translate}}</span>
            </a>
        </div>
    </form>
</div>

<div class="row" *ngIf="optedValue==10 && !editable">
    <div class="row thirdparty-readonly">
        <div class="d-flex justify-content-center h1-heading grey-heading">
            {{'text.FormCollectionTitle' | translate}}:
        </div>
        <div class="w-40 mt-1">
            <div class="row mt-1">
                <div class="col-6 h6-heading">
                    {{'globaltext.NameOfCoveredEntityLabel' | translate}}:
                </div>
                <div class="col-6 grey-message">
                    {{nameOfCoveredEntity?.value}}
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-6 h6-heading">
                    {{'globaltext.StreeAddress1Label' | translate}}:
                </div>
                <div class="col-6 grey-message">
                    {{streetAddress1?.value}}
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-6 h6-heading">
                    {{'globaltext.StreeAddress2Label' | translate}}:
                </div>
                <div class="col-6 grey-message">
                    {{streetAddress2?.value}}
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-6 h6-heading">
                    {{'globaltext.CityLabel' | translate}}:
                </div>
                <div class="col-6 grey-message">
                    {{city?.value}}
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-6 h6-heading">
                    {{'globaltext.StateLabel' | translate}}:
                </div>
                <div class="col-6 grey-message">
                    {{formData?.stateName}}
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-6 h6-heading">
                    {{'globaltext.ZipCodeLabel' | translate}}:
                </div>
                <div class="col-6 grey-message">
                    {{zipCode?.value}}
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center h1-heading grey-heading mt-4">
            {{'text.FormCollectionTitle2' | translate}}:
        </div>
        <div class="w-40 mt-1">
            <div class="row mt-1">
                <div class="col-6 h6-heading">
                    {{'globaltext.FirstNameLabel' | translate}}:
                </div>
                <div class="col-6 grey-message">
                    {{firstName?.value}}
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-6 h6-heading">
                    {{'globaltext.LastNameLabel' | translate}}:
                </div>
                <div class="col-6 grey-message">
                    {{lastName?.value}}
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-6 h6-heading">
                    {{'globaltext.EmailLabel' | translate}}:
                </div>
                <div class="col-6 grey-message">
                    {{email?.value}}
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-6 h6-heading">
                    {{'globaltext.PrimaryPhoneLabel' | translate}}:
                </div>
                <div class="col-6 grey-message">
                    {{primaryPhone?.value}}
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-6 h6-heading">
                    {{'globaltext.PrimaryPhoneTypeLabel' | translate}}:
                </div>
                <div class="col-6 grey-message">
                    {{formData?.primaryPhoneTypeName}}
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-6 h6-heading">
                    {{'globaltext.SecondaryPhoneLabel' | translate}}:
                </div>
                <div class="col-6 grey-message">
                    {{secondaryPhone?.value}}
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-6 h6-heading">
                    {{'globaltext.SecondaryPhoneTypeLabel' | translate}}:
                </div>
                <div class="col-6 grey-message">
                    {{formData?.secondaryPhoneTypeName}}
                </div>
            </div>
        </div>
    </div>
    <div class="button-center d-flex mt-5" *ngIf="showSectionsBasedOnOptInEndDateReached == '0'">
        <a class="btn mt-20px btn-primary text-uppercase" (click)="onEdit()">
            <span>{{'globaltext.EditButton' | translate}}</span>
        </a>
    </div>
</div>
<app-modal-popup [show]="showMakeSelectionPopupValue" leftbutton="NoOptOutButton" rightbutton="YesOptInButton"
    (emitstate)="emittedPendingOption($event)" [modalType]="'form_collection_optin_pending_action'"
    [showCloseButton]=true [IsGlobalDescription]=false></app-modal-popup>
<app-modal-popup [show]="showOptoutConfirmationPopupValue" leftbutton="ContinueToOptOutButton" rightbutton="OptInButton"
    (emitstate)="emittedOptoutConfirmationPopup($event)" [modalType]="'form_collection_optin_optout_confirmation'"
    [showCloseButton]=true [IsGlobalDescription]=false>
</app-modal-popup>