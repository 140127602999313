<div class="container main-content-container">
  <div class="row justify-content-center ml-5 pt-5 pb-5 mb-5">
    <div class="col-10">
      <div class="title-section">
        <h1 class="h1-heading grey-heading">
          {{ "globaltext.ProfileHeading" | translate }}
        </h1>
        <span class="subheading grey-heading">{{
          "globaltext.ProfileSubHeading" | translate
        }}</span>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <span class="subheading grey-heading">{{
            "globaltext.AccountDetailsHeading" | translate
          }}</span>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-8">
          <label class="control-label">{{
            "globaltext.EventNameLabel" | translate
          }}</label>
          <input
            type="text"
            class="form-control"
            readonly="true"
            [value]="
              currentDataOwner?.engagement_entity?.display_name +
              ' - ' +
              currentDataOwner?.name +
              ' (' +
              currentDataOwner?.data_owner_identifier +
              ')'
            "
          />
        </div>
      </div>
      <button
        type="submit"
        class="btn mt-20px btn-primary text-uppercase"
        (click)="changeEventClicked()"
        *ngIf="dataOwners.length > 1"
      >
        <span *ngIf="status === 'loaded'">{{
          "globaltext.ChangeEventButton" | translate
        }}</span>
        <ng-container *ngIf="status === 'loading'">
          <app-loading-spinner></app-loading-spinner>
        </ng-container>
      </button>
      <div class="row mt-5">
        <div class="col-12">
          <span class="subheading grey-heading">{{
            "globaltext.PersonalInformationHeading" | translate
          }}</span>
        </div>
      </div>
      <div class="form-div">
        <form
          [formGroup]="changeNameFormGroup"
          (ngSubmit)="onChangeNameSubmit()"
        >
          <div class="row">
            <div class="form-group col-6">
              <label class="control-label">{{
                "globaltext.FirstNameLabel" | translate
              }}</label>
              <input
                type="text"
                class="form-control"
                placeholder="{{
                  'globaltext.FirstNamePlaceholder' | translate
                }}"
                formControlName="firstName"
              />

              <p
                class="explanation"
                *ngIf="
                  firstName?.invalid && (firstName?.dirty || firstName?.touched)
                "
              >
                <span class="form-text bad" *ngIf="firstName?.errors?.required">
                  {{ "globaltext.FirstNameRequiredMsg" | translate }}</span
                >
                <span
                  class="form-text bad"
                  *ngIf="
                    firstName?.errors?.pattern || firstName?.errors?.maxlength
                  "
                >
                  {{ "globaltext.FirstNameInvalidMsg" | translate }}</span
                >
              </p>
            </div>
            <div class="form-group col-6">
              <label class="control-label">{{
                "globaltext.LastNameLabel" | translate
              }}</label>
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'globaltext.LastNamePlaceholder' | translate }}"
                formControlName="lastName"
              />

              <p
                class="explanation"
                *ngIf="
                  lastName?.invalid && (lastName?.dirty || lastName?.touched)
                "
              >
                <span class="form-text bad" *ngIf="lastName?.errors?.required">
                  {{ "globaltext.LastNameRequiredMsg" | translate }}</span
                >
                <span
                  class="form-text bad"
                  *ngIf="
                    lastName?.errors?.pattern || lastName?.errors?.maxlength
                  "
                >
                  {{ "globaltext.LastNameInvalidMsg" | translate }}</span
                >
              </p>
            </div>
          </div>
          <div
            *ngIf="errorMessage !== ''"
            class="row col recover-error-message"
          >
            {{ errorMessage }}
          </div>
          <button type="submit" class="btn mt-20px btn-primary text-uppercase">
            <span *ngIf="changeNameStatus === 'loaded'">{{
              "globaltext.ChangeNameButton" | translate
            }}</span>
            <ng-container *ngIf="changeNameStatus === 'loading'">
              <app-loading-spinner></app-loading-spinner>
            </ng-container>
          </button>
        </form>
      </div>

      <div class="row mt-5 d-none">
        <div class="col-12">
          <span class="subheading grey-heading">{{
            "globaltext.EmailAddressHeading" | translate
          }}</span>
        </div>
      </div>
      <div class="row d-none">
        <div class="form-group col-6">
          <label class="control-label">{{
            "globaltext.CurrentEmailLabel" | translate
          }}</label>
          <input
            type="text"
            class="form-control"
            readonly="true"
            placeholder="{{ 'globaltext.EmailPlaceholder' | translate }}"
          />
        </div>
      </div>
      <div class="row d-none" *ngIf="pendingEmailVerification">
        <div class="col-6">
          <div class="warning-div">
            <div class="left">
              <app-svg [type]="'red-warning'"></app-svg>
            </div>
            <div class="right ml-2">
              <b>{{
                "globaltext.CurrentEmailPendingVerificationLabel" | translate
              }}</b>
              <p class="mt-1">
                {{
                  "globaltext.CurrentEmailPendingVerificationError" | translate
                }}
              </p>
              <button
                type="submit"
                class="btn mt-20px btn-primary text-uppercase"
              >
                <span *ngIf="status === 'loaded'">{{
                  "globaltext.ResendEmailVerificationButton" | translate
                }}</span>
                <ng-container *ngIf="status === 'loading'">
                  <app-loading-spinner></app-loading-spinner>
                </ng-container>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="form-div d-none">
        <form
          [formGroup]="changeEmailFormGroup"
          (ngSubmit)="onChangeEmailSubmit()"
        >
          <div class="row">
            <div class="form-group col-6">
              <label class="control-label">{{
                "globaltext.NewEmailLabel" | translate
              }}</label>
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'globaltext.EmailPlaceholder' | translate }}"
                formControlName="email"
              />

              <p
                class="explanation"
                *ngIf="email?.invalid && (email?.dirty || email?.touched)"
              >
                <span class="form-text bad" *ngIf="email?.errors?.required">
                  {{ "globaltext.EmailRequiredMsg" | translate }}</span
                >
                <span
                  class="form-text bad"
                  *ngIf="email?.errors?.pattern && !email?.errors?.maxlength"
                  >{{ "globaltext.EmailPatternMsg" | translate }}</span
                >
                <span class="form-text bad" *ngIf="email?.errors?.maxlength">{{
                  "globaltext.EmailMaxLengthMsg" | translate
                }}</span>
              </p>
            </div>
            <div class="form-group col-6">
              <label class="control-label">{{
                "globaltext.ConfirmNewEmailLabel" | translate
              }}</label>
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'globaltext.EmailPlaceholder' | translate }}"
                formControlName="confirmEmail"
              />

              <p
                class="explanation"
                *ngIf="
                  confirmEmail?.invalid &&
                  (confirmEmail?.dirty || confirmEmail?.touched)
                "
              >
                <span
                  class="form-text bad"
                  *ngIf="confirmEmail?.errors?.required"
                >
                  {{ "globaltext.EmailRequiredMsg" | translate }}</span
                >
                <span class="form-text bad" *ngIf="fieldsMatch()">{{
                  "globaltext.ConfirmEmailMatchMsg" | translate
                }}</span>
                <span
                  class="form-text bad"
                  *ngIf="
                    confirmEmail?.errors?.pattern &&
                    !confirmEmail?.errors?.maxlength
                  "
                  >{{ "globaltext.EmailPatternMsg" | translate }}</span
                >
                <span
                  class="form-text bad"
                  *ngIf="confirmEmail?.errors?.maxlength"
                  >{{ "globaltext.EmailMaxLengthMsg" | translate }}</span
                >
              </p>
            </div>
          </div>
          <div
            *ngIf="errorMessage !== ''"
            class="row col recover-error-message"
          >
            {{ errorMessage }}
          </div>
          <button type="submit" class="btn mt-20px btn-primary text-uppercase">
            <span *ngIf="status === 'loaded'">{{
              "globaltext.ChangeEmailButton" | translate
            }}</span>
            <ng-container *ngIf="status === 'loading'">
              <app-loading-spinner></app-loading-spinner>
            </ng-container>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="modal" *ngIf="showChangeEvent">
  <div class="card changeClientPopupStyle">
    <section class="consent-header">
      <div class="">
        <!-- <div class="circle themed-secondary-bg"></div> -->
        <app-svg [type]="'warning'"></app-svg>
      </div>
      <h6 class="mt-3 primary-color">
        {{ "globaltext.ChangeEventButton" | translate }}
      </h6>
    </section>
    <section class="main-content">
      <div class="row">
        <div class="col">
          <p class="description">
            {{ "globaltext.ChangeEventDescription" | translate }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <select class="form-control" [(ngModel)]="selectedDataOwnerId">
            <option
              *ngFor="let dataOwner of dataOwners"
              [ngValue]="dataOwner.id"
            >
              {{ dataOwner.engagement_entity.display_name }} -
              {{ dataOwner.name }} ({{ dataOwner.data_owner_identifier }})
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <section class="button-row center mt-5 mb-3">
            <button
              (click)="closeChangeEvent()"
              type="button"
              class="btn btn-primary-outline"
            >
              {{ "globaltext.CancelButton" | translate }}
            </button>
            <button
              (click)="updateEvent()"
              type="button"
              class="btn save btn-primary"
            >
              <span>{{ "globaltext.ConfirmButton" | translate }}</span>
            </button>
          </section>
        </div>
      </div>
    </section>
  </div>
</div>
