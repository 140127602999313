import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  DataOwnerWorkflowStepEntity,
  STEP_STATUS,
} from '@cyber/navigator-services';
import { TranslateService } from '@ngx-translate/core';
import { StepsHelperService } from 'src/app/services/steps-helper.service';
import { StorageService } from 'src/app/services/storage.service';
import { appConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-step-card',
  templateUrl: './step-card.component.html',
  styleUrls: ['./step-card.component.scss'],
})
export class StepCardComponent implements OnInit {
  hidden = true;
  @Input() stepDetails!: DataOwnerWorkflowStepEntity;
  @Input() lastItem = false;
  stepUrl: any;
  statusColor: any;
  disabledStep = false;
  errorMessage = '';
  step: any;

  constructor(
    public translate: TranslateService,
    public router: Router,
    public storageService: StorageService,
    public stepHelperService: StepsHelperService
  ) {}

  ngOnInit(): void {
    this.loadStepDetails();
  }

  loadStepDetails() {
    let currentStep = this.stepDetails;
    let current_page = currentStep?.step.cms_binding;

    let key = 'steps.' + current_page;
    this.step = this.translate.instant(key);
    this.stepUrl = '/' + this.stepDetails.step.page_route;

    if (
      (current_page == appConstants.WorkflowStep.CustomerReview ||
        current_page == appConstants.WorkflowStep.CustomerUpdate ||
        current_page == appConstants.WorkflowStep.NCOA_Review ||
        current_page == appConstants.WorkflowStep.Exceptions_Review) &&
      currentStep?.data != null
    ) {
      let currentStepData = JSON.parse(currentStep?.data);
      current_page =
        current_page + '_' + this.transform(currentStepData?.display_selection);
      this.storageService.setCookieForLongerDuration(
        appConstants.sessionSelectedViewOrEditMode,
        this.stepDetails.data
      );
      this.stepUrl = this.stepUrl + '/' + currentStepData?.display_selection;
    } else {
      if (
        (current_page == appConstants.WorkflowStep.CustomerReview ||
          current_page == appConstants.WorkflowStep.CustomerUpdate ||
          current_page == appConstants.WorkflowStep.NCOA_Review ||
          current_page == appConstants.WorkflowStep.Exceptions_Review) &&
        currentStep?.data == null
      ) {
        this.stepUrl = this.stepUrl + '/web';
      }
    }

    this.statusColor = this.setStatusCardColor(this.stepDetails.step_status_id);
    this.handleStepStatus();
  }

  transform(value: string): string {
    if (value) {
      let first = value.substr(0, 1).toUpperCase();
      return first + value.substr(1);
    }
    return '';
  }

  handleStepStatus() {
    let optinPeriodEnded =
      this.storageService.getLocalStorage(appConstants.OptInEndDateReached) ==
      '1';

    let changeOfAddressPeriodEnded =
      this.storageService.getLocalStorage(
        appConstants.ChangeOfAddressEndDateReached
      ) == '1';

    let exceptionReviewPeriodEnded =
      this.storageService.getLocalStorage(
        appConstants.ExceptionReviewEndDateReached
      ) == '1';

    let statusId = this.stepDetails.step_status_id;

    let reviewStep = this.stepHelperService.getCurrentStepByBinding(
      appConstants.WorkflowStep.CustomerReview
    );
    let optInStep = this.stepHelperService.getCurrentStepByBinding(
      appConstants.WorkflowStep.NotificationOptIn
    );

    switch (this.stepDetails?.step.cms_binding) {
      case appConstants.WorkflowStep.NotificationOptIn:
        if (
          reviewStep?.step_status_id != STEP_STATUS.VIEWED &&
          reviewStep?.step_status_id != STEP_STATUS.DOWNLOADED
        ) {
          this.disabledStep = true;
          this.errorMessage = 'CompleteReviewToOptIn';
        }
        if (optinPeriodEnded) {
          this.disabledStep = true;
          this.errorMessage = 'NotificationOptInEndDateCrossed';
        }
        break;
      case appConstants.WorkflowStep.CustomerUpdate:
        if (optInStep?.step_status_id != STEP_STATUS.OPTED_IN) {
          this.disabledStep = true;
          this.errorMessage = 'StepNotAvailableUntilNotificationOptIn';
        }
        if (optinPeriodEnded) {
          this.disabledStep = true;
          this.errorMessage = 'CustomerUpdateOptInEndDateCrossed';
        }
        break;
      case appConstants.WorkflowStep.OCR_OptIn:
        if (optinPeriodEnded) {
          this.disabledStep = true;
          this.errorMessage = 'OCROptInEndDateCrossed';
        }
        break;
      case appConstants.WorkflowStep.Regulator_Notice:
        if (optinPeriodEnded) {
          this.translate
            .get('page.Regulator_Notice.pagecomponents')
            .subscribe(async (res: any) => {
              let stepData = res;
              let hasOptIn = stepData.filter(
                (item: any) =>
                  item.component == 'Optin' ||
                  item.component == 'FormCollection'
              );
              if (hasOptIn.length > 0) {
                this.disabledStep = true;
                this.errorMessage = 'OCROptInEndDateCrossed';
              }
            });
        }
        break;
      case appConstants.WorkflowStep.Notification_Report:
        if (statusId == STEP_STATUS.NOT_YET_AVAILABLE) {
          this.disabledStep = true;
          this.errorMessage = 'FinalReportNotYetAvailable';
        }
        if (
          statusId == STEP_STATUS.NA_OPTED_OUT ||
          optInStep?.step_status_id != STEP_STATUS.OPTED_IN
        ) {
          this.disabledStep = true;
          this.errorMessage = 'StepNotAvailableUntilNotificationOptIn';
        }
        break;
      case appConstants.WorkflowStep.NCOA_Review:
      case appConstants.WorkflowStep.Exceptions_Review:
        if (optInStep?.step_status_id != STEP_STATUS.OPTED_IN) {
          this.disabledStep = true;
          this.errorMessage = 'StepNotAvailableUntilNotificationOptIn';
        } else if (statusId == STEP_STATUS.NOT_YET_AVAILABLE) {
          this.disabledStep = true;
          this.errorMessage = 'NcoaDataNotYetAvailable';
        }
        if (
          this.stepDetails?.step.cms_binding ===
            appConstants.WorkflowStep.NCOA_Review &&
          changeOfAddressPeriodEnded
        ) {
          this.disabledStep = true;
          this.errorMessage = 'ChangeOfAddressReviewEndedNextStepNotAvailable';
        }
        if (
          this.stepDetails?.step.cms_binding ===
            appConstants.WorkflowStep.Exceptions_Review &&
          exceptionReviewPeriodEnded
        ) {
          this.disabledStep = true;
          this.errorMessage = 'ExceptionReviewEndedNextStepNotAvailable';
        }
    }
  }

  setStatusCardColor(statusId: number) {
    if (appConstants.StepStatusColors.Green.indexOf(statusId) > -1) {
      return 'green';
    }
    if (appConstants.StepStatusColors.Yellow.indexOf(statusId) > -1) {
      return 'yellow';
    }
    if (appConstants.StepStatusColors.Red.indexOf(statusId) > -1) {
      return 'red';
    }
    if (appConstants.StepStatusColors.Gray.indexOf(statusId) > -1) {
      return 'gray';
    }
    return 'default';
  }

  navigateToPage() {
    this.router.navigate([this.stepUrl]);
  }

  toggleHidden() {
    this.hidden = !this.hidden;
  }
}
