import { NgModule, Pipe, PipeTransform } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloginModule } from './layouts/prelogin/prelogin.module';
import { PostloginModule } from './layouts/postlogin/postlogin.module';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { TranslationResolverService } from './services/translation-resolver.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { SessionPopupComponent } from './components/session-popup/session-popup.component';
import { LoadingModalComponent } from './components/loading-modal/loading-modal.component';
import {
  CmsContentService,
  NavigatorServicesModule,
} from '@cyber/navigator-services';
import { NavigatorConfig } from './navigator-config';
import { from, Observable } from 'rxjs';
import { StorageService } from './services/storage.service';
import { ToastrModule } from 'ngx-toastr';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { MaintenanceWrapperComponent } from './components/maintenance-wrapper/maintenance-wrapper.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export class CustomLoader implements TranslateLoader {
  constructor(
    public cmsContentService: CmsContentService,
    public storageService: StorageService
  ) {}
  getTranslation(lang: string): Observable<any> {
    return from(
      Promise.resolve(this.storageService.getLocalStorage(`language-${lang}`))
    );
  }
}

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(value: any) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    SessionPopupComponent,
    LoadingModalComponent,
    MaintenanceWrapperComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
        //useFactory: HttpLoaderFactory,
        deps: [HttpClient, StorageService, CmsContentService],
      },
    }),
    AppRoutingModule,
    PreloginModule,
    PostloginModule,
    SharedModule,
    NavigatorServicesModule.forRoot<NavigatorConfig>(),
    ToastrModule.forRoot(),
    HttpClientModule,
  ],
  exports: [],
  providers: [
    TranslationResolverService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
