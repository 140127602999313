<div class="center-container">
    <div class="card center-card small-card">
        <section class="consent-header text-center">
            <div class="content-container">
                <app-svg [type]="'nnlogo'"></app-svg>
            </div>
            <h2 class="danger-text">{{errorHeading}}</h2>
        </section>
        <section class="main-content text-center">
            <div class="row">
                <div class="description"  [innerHTML]="errorMessage | safeHtml">
                </div>
            </div>
        </section>
    </div>
</div>