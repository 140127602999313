import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppConfigService, BaseAppConfig, SessionService } from '@cyber/navigator-services';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-header-postlogin',
  templateUrl: './header-postlogin.component.html',
  styleUrls: ['./header-postlogin.component.scss'],
})
export class HeaderPostloginComponent implements OnInit {

  FinalLinks = [
    {
      Routerlink: '/home',
      Icon: 'home',
      Key: 'Home',
    },
    {
      Routerlink: '/profile',
      Icon: 'account_circle',
      Key: 'Profile',
    }
  ]
  DashBoardMenuLinks = this.FinalLinks;

  clientLogoUrl = '';

  removeDashboardLinksForTheseUrls = ['/finish-setup','/welcome', '/important-information', '/mobile-device-notice', '/legal-disclaimer', '/select-event']
  noLogoForTheseUrls = ['/finish-setup','/select-event'];

  constructor(public router: Router,
    public sessionService: SessionService
    ) {
    
    if(!this.noLogoForTheseUrls.includes(this.router.url))
      this.clientLogoUrl = 'logo.png?slug=true';

    if(this.removeDashboardLinksForTheseUrls.includes(this.router.url))
      this.DashBoardMenuLinks = [];

  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd){
        if(this.removeDashboardLinksForTheseUrls.includes(event.url))
          this.DashBoardMenuLinks = [];
        else
          this.DashBoardMenuLinks = this.FinalLinks;
      }
    });
  }
}
