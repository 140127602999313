<div *ngIf="fileGenerationStatus">
  <div class="d-flex justify-content-center pb-2">
    <span *ngIf="fileGenerationStatus.percentage_complete <= 100">{{
      "globaltext.NcoaFileGenerationInProgress" | translate
    }}</span>
    <span *ngIf="fileGenerationStatus.percentage_complete > 100">{{
      "globaltext.NcoaFileGenerationComplete" | translate
    }}</span>
  </div>
  <div class="d-flex justify-content-center" *ngIf="fileGenerationStatus">
    <kendo-progressbar
      [min]="-1"
      [max]="101"
      [value]="fileGenerationStatus.percentage_complete"
      [label]="{ format: 'percent' }"
    ></kendo-progressbar>
  </div>
</div>

<div class="button-center d-flex">
  <button
    class="btn mt-20px btn-primary text-uppercase"
    [disabled]="
      fileGenerationStatus && fileGenerationStatus.percentage_complete <= 100
    "
    (click)="downloadFile()"
  >
    <span>{{ "globaltext.DownloadButton" | translate }}</span>
  </button>
</div>
<div class="d-flex justify-content-center mt-3" *ngIf="error != ''">
  <span
    class="danger-text download-file-error"
    [innerHTML]="'globalrichtext.ErrorDownloadingFile' | translate | safeHtml"
  ></span>
</div>
