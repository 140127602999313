<div class="container main-content-container">
    <div class="row justify-content-center  pt-5 pb-5 mb-5">
        <div class="col-sm-12 col-md-7 col-lg-6">
            <div class="title-section mt-5">
                <h1 class="h1-heading grey-heading">{{ 'ForgotPasswordSuccess.Heading' | translate}}</h1>
                <span class="subheading grey-heading">{{ 'ForgotPasswordSuccess.Subheading' | translate}}</span>
            </div>
            <div class="form-div">
                <p class="grey-message">{{ 'ForgotPasswordSuccess.Description1' | translate}}</p>
                <p class="grey-message">{{ 'ForgotPasswordSuccess.Description2' | translate}}</p>
                <button class="btn mt-20px btn-primary text-uppercase" routerLink="/sign-in">
                    <span>{{'Login.SignInButton' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</div>