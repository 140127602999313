
export interface IRegion {
    RegionalLanguages: any[];
}

export const USRegion:IRegion = {
    RegionalLanguages:[{code:"en", lang:"Default",default:false}, {code:"en-US", lang:"English",default:true}]
}

export const CARegion:IRegion = {
    RegionalLanguages:[{code:"en-CA", lang:"English",default:true},{code:"fr-CA", lang:"French",default:true}]
}

export const UKRegion:IRegion = {
    RegionalLanguages:[{code:"en-GB", lang:"English",default:true}]
}


export const regionConstants = new Map<string, IRegion>();
regionConstants.set("USRegion", USRegion);
regionConstants.set("UKRegion", UKRegion);
regionConstants.set("CARegion", CARegion);