import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StepsHelperService } from 'src/app/services/steps-helper.service';

@Component({
  selector: 'app-webtable-or-file-selection',
  templateUrl: './webtable-or-file-selection.component.html',
  styleUrls: ['./webtable-or-file-selection.component.scss']
})
export class WebtableOrFileSelectionComponent implements OnInit {

  pageDetails:any;
  loadedTip='';
  constructor(
    public translate: TranslateService,
    public router:Router,
    public stepsHelperService: StepsHelperService) { }

  ngOnInit(): void {
  }

  async navigateTo(page: string){
    this.router.navigateByUrl('/data-review/'+page);
  }

  closeTip(event: any) {
    this.loadedTip = '';
  }


}
