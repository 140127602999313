import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup, NgForm, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {
  ChangeOfAddress,
  ChangeOfAddressService,
  Customer,
  CustomerChangeOfAddress,
  CustomerField,
  CustomerService,
  DataOwnerService,
  STEP_STATUS,
} from '@cyber/navigator-services';
import { TranslateService } from '@ngx-translate/core';
import {
  GridDataResult,
  GridComponent,
  EditEvent,
  CellClickEvent,
  CellCloseEvent,
  SaveEvent,
  CancelEvent,
  RemoveEvent,
  ColumnBase,
  ColumnComponent,
} from '@progress/kendo-angular-grid';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  State,
} from '@progress/kendo-data-query';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { DateHelper } from 'src/app/helpers/date.helper';
import { KendoGridHelper } from 'src/app/helpers/kendo-grid-helper';
import { MemberService } from 'src/app/services/member.service';
import { StepsHelperService } from 'src/app/services/steps-helper.service';
import { StorageService } from 'src/app/services/storage.service';
import { appConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-review-change-of-address-web',
  templateUrl: './review-change-of-address-web.component.html',
  styleUrls: ['./review-change-of-address-web.component.scss'],
})
export class ReviewChangeOfAddressWebComponent implements OnInit {
  @Output() emitOnDataChanged: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  mode = '';
  totalItems: any;
  gridData!: GridDataResult;
  public gridState: any = {
    sort: [
      {
        field: 'changeOfAddress.CustomerIdentifier',
        dir: 'asc',
      },
    ],
    skip: 0,
    take: 25,
  };
  searchQuery!: string;
  timeout: any;
  mobileDevice!: boolean;
  editCellRowIndex: number = 0;
  editCellColumnIndex: number = 0;
  isSavingAudit!: boolean;
  booleanTrue = true;
  booleanFalse = false;
  hasChangeAudit: boolean = false;
  loadingData: boolean = true;
  isReview = true;
  customerFields!: CustomerField[];
  standardFields: CustomerField[] = [
    <CustomerField>{
      caption: 'SID',
      field_name: 'sid',
      display_order: 1,
      is_editable: false,
    },
    <CustomerField>{
      caption: 'ClientID',
      field_name: 'customer_identifier',
      display_order: 2,
      is_editable: false,
    },
    <CustomerField>{
      caption: 'Manual Exception Type',
      field_name: 'manual_exception_type',
      display_order: 3,
      is_editable: false,
    },
    <CustomerField>{
      caption: 'Address Update',
      field_name: 'address_update',
      display_order: 4,
      is_editable: false,
    },
    <CustomerField>{
      caption: 'First Name',
      field_name: 'first_name',
      display_order: 5,
      is_editable: true,
    },
    <CustomerField>{
      caption: 'Middle Name',
      field_name: 'middle_name',
      display_order: 6,
      is_editable: true,
    },
    <CustomerField>{
      caption: 'Last Name',
      field_name: 'last_name',
      display_order: 7,
      is_editable: true,
    },
    <CustomerField>{
      caption: 'Suffix',
      field_name: 'suffix',
      display_order: 8,
      is_editable: true,
    },
    <CustomerField>{
      caption: 'New Address 1',
      field_name: 'new_address1',
      display_order: 9,
      is_editable: true,
    },
    <CustomerField>{
      caption: 'New Address 2',
      field_name: 'new_address2',
      display_order: 10,
      is_editable: true,
    },
    <CustomerField>{
      caption: 'New City',
      field_name: 'new_city',
      display_order: 11,
      is_editable: true,
    },
    <CustomerField>{
      caption: 'New State',
      field_name: 'new_state',
      display_order: 12,
      is_editable: true,
    },
    <CustomerField>{
      caption: 'New Zip',
      field_name: 'new_zip',
      display_order: 13,
      is_editable: true,
    },
    <CustomerField>{
      caption: 'Address 1',
      field_name: 'address1',
      display_order: 14,
      is_editable: false,
    },
    <CustomerField>{
      caption: 'Address 2',
      field_name: 'address2',
      display_order: 15,
      is_editable: false,
    },
    <CustomerField>{
      caption: 'City',
      field_name: 'city',
      display_order: 16,
      is_editable: false,
    },
    <CustomerField>{
      caption: 'State',
      field_name: 'state',
      display_order: 17,
      is_editable: false,
    },
    <CustomerField>{
      caption: 'Zip',
      field_name: 'zip',
      display_order: 18,
      is_editable: false,
    },
    <CustomerField>{
      caption: 'SSN',
      field_name: 'social_security_number',
      display_order: 20,
      is_editable: false,
    },
    <CustomerField>{
      caption: 'Email',
      field_name: 'email',
      display_order: 21,
      is_editable: true,
    },
    <CustomerField>{
      caption: 'Primary Group',
      field_name: 'primary_group',
      display_order: 22,
      is_editable: true,
    },
    <CustomerField>{
      caption: 'Secondary Group',
      field_name: 'secondary_group',
      display_order: 23,
      is_editable: true,
    },
    <CustomerField>{
      caption: 'VText1',
      field_name: 'v_text1',
      display_order: 24,
      is_editable: true,
    },
    <CustomerField>{
      caption: 'VText2',
      field_name: 'v_text2',
      display_order: 25,
      is_editable: true,
    },
    <CustomerField>{
      caption: 'VText3',
      field_name: 'v_text3',
      display_order: 26,
      is_editable: true,
    },
    <CustomerField>{
      caption: 'VText4',
      field_name: 'v_text4',
      display_order: 27,
      is_editable: true,
    },
    <CustomerField>{
      caption: 'VText5',
      field_name: 'v_text5',
      display_order: 28,
      is_editable: true,
    },
    <CustomerField>{
      caption: 'VText6',
      field_name: 'v_text6',
      display_order: 29,
      is_editable: true,
    },
  ];
  showConfirmDelete = false;
  recordToDelete = 0;
  emptyCustomerChangeOfAddress = <CustomerChangeOfAddress>{
    id: '',
    customer_identifier: '',
    sid: '',
    manual_exception_type: '',
    address_update: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    suffix: '',
    new_address1: '',
    new_address2: '',
    new_city: '',
    new_state: '',
    new_zip: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    social_security_number: '',
    email: '',
    primary_group: '',
    secondary_group: '',
    v_text1: '',
    v_text2: '',
    v_text3: '',
    v_text4: '',
    v_text5: '',
    v_text6: '',
    text1: '',
    text2: '',
    text3: '',
    text4: '',
    text5: '',
    text6: '',
    text7: '',
    text8: '',
    text9: '',
    text10: '',
    text11: '',
    text12: '',
    text13: '',
    text14: '',
    text15: '',
    text16: '',
    text17: '',
    text18: '',
    text19: '',
    text20: '',
    text21: '',
    text22: '',
    text23: '',
    text24: '',
    text25: '',
    text26: '',
    text27: '',
    text28: '',
    text29: '',
    text30: '',
    text31: '',
    text32: '',
    text33: '',
    text34: '',
    text35: '',
    text36: '',
    text37: '',
    text38: '',
    text39: '',
    text40: '',
    date_time1: new Date(),
    date_time2: new Date(),
    date_time3: new Date(),
    date_time4: new Date(),
    date_time5: new Date(),
    date_time6: new Date(),
    date_time7: new Date(),
    date_time8: new Date(),
    date_time9: new Date(),
    date_time10: new Date(),
    number1: 0,
    number2: 0,
    number3: 0,
    number4: 0,
    number5: 0,
    number6: 0,
    number7: 0,
    number8: 0,
    number9: 0,
    number10: 0,
    boolean1: false,
    boolean2: false,
    boolean3: false,
    boolean4: false,
    boolean5: false,
    boolean6: false,
    boolean7: false,
    boolean8: false,
    boolean9: false,
    boolean10: false,
  };
  public formGroupMap = new Map<number, FormGroup>();

  @ViewChild('form') form: NgForm | undefined;
  @ViewChild('nativeForm') nativeForm!: ElementRef;
  @ViewChild(GridComponent) grid!: GridComponent;

  @Input() editable = '1';
  searchSubject: Subject<any> = new Subject();

  engagement_entity!: any;

  constructor(
    public router: Router,
    private _activatedRoute: ActivatedRoute,
    public memberService: MemberService,
    private kendoHelper: KendoGridHelper,
    private changeOfAddressService: ChangeOfAddressService,
    private dataOwnerService: DataOwnerService,
    public _stepsHelperService: StepsHelperService,
    public translate: TranslateService,
    public toastr: ToastrService,
    private ngZone: NgZone,
    private _dateHelper: DateHelper,
    private _storageService: StorageService
  ) {
    this.fetchEngagementEntity();
    this.loadWebTableData(0);
    _activatedRoute.params.subscribe((params) => {
      this.mode = params['mode'] ?? '';
      if ((this.mode && this.mode === 'edit') || this.editable !== '0') {
        this.editable = '1';
        this.isReview = false;
      }
      this.makeWebMySelection();
    });
    this.searchSubject.pipe(debounceTime(500)).subscribe(() => {
      this.getData();
    });
  }

  fetchEngagementEntity() {
    let dataOwnerString = this._storageService.getLocalStorage(
      appConstants.DataOwner
    );
    let dataOwnerDetails = JSON.parse(dataOwnerString);
    this.engagement_entity = dataOwnerDetails?.engagement_entity;
  }

  ngOnInit(): void {
    if (this.editable !== '0') {
      this.editable = '1';
      this.isReview = false;
    }
  }

  // region api call to get data
  async loadWebTableData(pageNo: number) {
    var customerWebColumns =
      await this.dataOwnerService.fetchCustomerFileColumns();

    this.customerFields = [...this.standardFields];
    customerWebColumns
      .filter(
        (cf) =>
          cf.field_name.toLowerCase().startsWith('text') ||
          cf.field_name.toLowerCase().startsWith('datetime') ||
          cf.field_name.toLowerCase().startsWith('number') ||
          cf.field_name.toLowerCase().startsWith('boolean')
      )
      .forEach((ele: CustomerField) => {
        let snakeCaseFieldName = this.camelToSnakeCase(ele.field_name);
        if (
          !this.customerFields.find(
            (cf) => cf.field_name === snakeCaseFieldName
          )
        ) {
          ele.display_order = ele.display_order + 100; //offset from standard fields
          ele.field_name = snakeCaseFieldName;
          ele.is_editable = false;
          this.customerFields.push(ele);
        }
      });
    this.customerFields = this.customerFields.sort(
      (a, b) => a.display_order - b.display_order
    );
    await this.getData();
  }

  searchAllColumns(event: KeyboardEvent) {
    if (
      this.searchQuery != null &&
      this.searchQuery != '' &&
      this.searchQuery.length >= 3
    ) {
      this.gridState.skip = 0;
      this.searchSubject.next();
    }

    //prevent form submission
    if (event.key === 'Enter') event.preventDefault();
  }

  updateFilterTypesOfColumns(gridColumnsArray: any) {
    gridColumnsArray.forEach((column: any) => {
      let columnDataType = this.getDataTypeOfCustomerField(column.field);
      if (columnDataType == 'string') column.filter = 'text';
      else if (columnDataType == 'number') column.filter = 'numeric';
      else column.filter = columnDataType;
    });
    return gridColumnsArray;
  }

  async getData() {
    // Setting datatype to filter in kendohelper as we cannot set dynamic filter types to kendogrid
    let gridColumnsArray = this.updateFilterTypesOfColumns(
      this.grid?.columns?.toArray()
    );

    const filter = this.getPageApiWhereClause(
      this.gridState?.filter,
      gridColumnsArray,
      this.searchQuery
    );
    const sort = this.kendoHelper.getSortString(this.gridState?.sort);
    try {
      this.loadingData = true;
      let response =
        await this.changeOfAddressService.pageCustomerChangeOfAddresses(
          sort,
          this.gridState.skip / this.gridState.take + 1,
          this.gridState.take,
          filter
        );
      this.gridData = {
        data: response.data,
        total: response.total_count,
      };
      this.createFormArray(response.data);
    } catch (err) {
    } finally {
      this.loadingData = false;
      this.fitColumns();
    }
  }

  public search() {
    if (this.timeout) clearTimeout(this.timeout);
    this.gridState.skip = 0;
    this.timeout = setTimeout(async () => {
      await this.getData();
    }, 500);
  }

  createFormArray(data: ChangeOfAddress[]) {
    let index = 0;
    data.forEach((item) => {
      this.formGroupMap.set(index, this.createFormGroup(item));
      index++;
    });
  }

  formatDateTimeValue(value: any) {
    return this._dateHelper.formatShortDate(value);
  }

  // endregion

  // region Grid Open/Close/fit

  openFullWindow() {
    this.router.navigate(['/ncoa-table/full']);
  }

  closeFullWindow() {
    this.router.navigate(['/ncoa-review/web']);
  }

  private fitColumns(): void {
    this.ngZone.onStable
      .asObservable()
      .pipe(take(1))
      .subscribe(() => {
        this.kendoHelper.fitColumns(this.grid);
      });
  }

  // endregion

  // region Grid State change

  public getFormControl(index: number, field: string): any {
    if (index >= this.gridState.skip) index -= this.gridState.skip;
    let formGroup = this.formGroupMap.get(index);
    return formGroup?.controls[field];
  }

  public isValidFormGroup(index: number) {
    if (index >= this.gridState.skip) index -= this.gridState.skip;

    let formGroup = this.formGroupMap.get(index);
    formGroup?.markAllAsTouched();
    return formGroup?.valid;
  }

  public editHandler({ sender, rowIndex, dataItem }: EditEvent): void {
    //this.formGroup = this.createFormGroup(dataItem);
  }

  // Create form group with the same keys as the grid columns.
  createFormGroup(dataItem: any) {
    const group: any = {};

    group['id'] = new FormControl(dataItem.id);
    this.customerFields.forEach((column: CustomerField) => {
      let validators = [];
      if (column.is_required) {
        validators.push(Validators.required);
      }
      if (column.validation_regex) {
        validators.push(Validators.pattern(column.validation_regex));
      }

      if (this.getDataTypeOfCustomerField(column.field_name) == 'date') {
        dataItem[column.field_name] = this._dateHelper.formatShortDate(
          dataItem[column.field_name]
        );
      }

      if (validators.length > 0)
        group[column.field_name] = new FormControl(
          dataItem[column.field_name],
          validators
        );
      else
        group[column.field_name] = new FormControl(dataItem[column.field_name]);
    });
    return new FormGroup(group);
  }

  public async onStateChange(state: State) {
    this.gridState = state;
    await this.getData();
    this.fitColumns();
  }

  // endregion
  // region Cell Click and edit hanlders
  public cellClickHandler(args: CellClickEvent) {
    if (
      !args.isEdited &&
      this.editCellRowIndex !== -1 &&
      args.column.field &&
      args.column.editable &&
      !this.isReview
    ) {
      //field bound to column i.e. not the actions / breach data columns
      this.editCellRowIndex = args.rowIndex;
      this.editCellColumnIndex = args.columnIndex;

      let currentFormGroup = this.createFormGroup(args.dataItem);
      args.sender.editCell(args.rowIndex, args.columnIndex, {
        ...currentFormGroup,
      });
    }
  }

  public async cellCloseHandler(args: CellCloseEvent) {
    if (!this.isValidFormGroup(args.rowIndex)) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (
      args.dataItem != undefined &&
      args.dataItem != undefined &&
      args.dataItem[args.column.field] !==
        this.getFormControl(args.rowIndex, args.column.field).value
    ) {
      try {
        this.loadingData = true;
        //args.dataItem.values['is_original'] = false;

        args.dataItem[args.column.field] = this.getFormControl(
          args.rowIndex,
          args.column.field
        ).value;

        let currentStepStatus =
          this._stepsHelperService.getCurrentStepByBinding(
            appConstants.WorkflowStep.NCOA_Review
          );

        if (currentStepStatus.step_status_id != STEP_STATUS.IN_PROGRESS) {
          let updateStepPromise = this._stepsHelperService.updateStepStatus(
            appConstants.WorkflowStep.NCOA_Review,
            STEP_STATUS.IN_PROGRESS
          );
          let savePromise = this.changeOfAddressService.save(args.dataItem);
          await Promise.all([savePromise, updateStepPromise]);
        } else {
          await this.changeOfAddressService.save(args.dataItem);
        }

        await this.getData();

        this.hasChangeAudit = true;
        this.emitOnDataChanged.emit(true);
      } catch {
        this.toastr.error(
          await this.translate.get('globaltext.WebTableSaveError').toPromise(),
          '',
          {
            positionClass: 'toast-bottom-center',
          }
        );
      } finally {
        this.loadingData = false;
        //If cell being closed is not the one currently being edited, reopen the one being edited after saving / reloading data
        //This is a hack to mimic a tab-like navigation in the editable grid
      }
    }

    if (
      this.editCellColumnIndex >= 0 &&
      this.editCellRowIndex >= 0 &&
      (this.editCellRowIndex !== args.rowIndex ||
        this.editCellColumnIndex !== args.column.leafIndex)
    ) {
      this.grid.editCell(this.editCellRowIndex, this.editCellColumnIndex, {
        ...this.gridData.data[this.editCellRowIndex],
      });
    }
  }

  public closeEditor() {
    this.editCellRowIndex = 0;
    this.editCellColumnIndex = 0;
    this.grid.closeCell();
    this.grid.closeRow();
  }

  public editNextCell(event: Event) {
    if (!this.isValidFormGroup(this.editCellRowIndex)) {
      event.preventDefault();
      return;
    }
    let targetElement = event.target as HTMLInputElement;
    if (targetElement.reportValidity()) {
      //Only increment if form passes validation & isn't a new row
      if (this.editCellRowIndex > -1) {
        event.preventDefault();
        this.grid.closeCell();
        this.grid.closeRow();

        let nextColumnToEdit = this.customerFields.filter(
          (c: any) =>
            c.display_order > this.editCellColumnIndex && c.is_editable
        );
        if (nextColumnToEdit.length > 0) {
          this.editCellColumnIndex = nextColumnToEdit[0].display_order;
        } else {
          this.editCellRowIndex++;
          let firstColumnToEdit = this.customerFields.filter(
            (c: any) => c.is_editable
          );
          if (firstColumnToEdit.length > 0) {
            this.editCellColumnIndex = firstColumnToEdit[0].display_order;
          }
        }
        this.grid.editCell(this.editCellRowIndex, this.editCellColumnIndex, {
          ...this.gridData.data[this.editCellRowIndex],
        });
      }
    } else {
      event.preventDefault();
    }
  }

  public async cancelHandler(args: CancelEvent) {
    if (args.isNew) {
      this.editCellRowIndex = -1;
      this.editCellColumnIndex = -1;
      this.formGroupMap.delete(-1);
    }
    this.closeEditor();
  }

  public dummySaveClick() {
    if (this.isValidFormGroup(this.editCellRowIndex)) {
      this.emitOnDataChanged.emit(true);
      this.closeEditor();
    }
  }

  get invalid() {
    for (let formGroup of this.formGroupMap.values()) {
      if (formGroup.invalid) return true;
    }
    return false;
  }

  // endregion

  // region helpers
  camelToSnakeCase(str: any) {
    str = str[0].toLowerCase() + str.substr(1);
    str = str.replace(/[A-Z]/g, (letter: any) => `_${letter.toLowerCase()}`);
    return str;
  }

  getDataTypeOfCustomerField(field_name: string): string {
    let typeOfCustomerField = typeof (this.emptyCustomerChangeOfAddress as any)[
      field_name
    ];
    if (
      typeOfCustomerField == 'object' &&
      (this.emptyCustomerChangeOfAddress as any)[field_name] instanceof Date
    )
      return 'date';
    return typeOfCustomerField;
  }

  getPageApiWhereClause(
    gridFilter: CompositeFilterDescriptor,
    gridColumns: ColumnBase[],
    searchText: string
  ) {
    let filter = this.getFullFilterString(gridFilter);
    if (searchText && searchText.length >= 3) {
      let searchFilter = this.getSearchAllColumnsFilter(
        searchText,
        gridColumns
      );
      if (filter) {
        filter = `${filter} AND (${searchFilter})`;
      } else {
        filter = searchFilter;
      }
    }
    return filter;
  }

  getFullFilterString(filter: CompositeFilterDescriptor) {
    let result = undefined;
    if (filter) {
      for (var i = 0; i < filter.filters.length; i++) {
        let compositeFilter = filter.filters[i] as CompositeFilterDescriptor;
        for (var j = 0; j < compositeFilter.filters.length; j++) {
          let singleFilter = compositeFilter.filters[j] as FilterDescriptor;
          let field = 'customer.' + singleFilter.field;
          if (
            this.standardFields.find(
              (cf) => cf.field_name == singleFilter.field
            )
          ) {
            field = 'changeOfAddress.' + singleFilter.field;
          }
          if (!result) {
            result = this.kendoHelper.getFilterString(
              this.kendoHelper.getDataFieldName(field),
              singleFilter.operator as string,
              singleFilter.value
            );
          } else {
            result += ` ${
              compositeFilter.logic
            } ${this.kendoHelper.getFilterString(
              this.kendoHelper.getDataFieldName(field),
              singleFilter.operator as string,
              singleFilter.value
            )}`;
          }
        }
      }
    }
    return result;
  }

  getSearchAllColumnsFilter(text: any, columns: ColumnBase[]): string {
    let searchFilter = '';
    let numberRegex = new RegExp('[0-9]{1,}[.0-9]?');
    for (let column of columns) {
      let columnComponent = column as ColumnComponent;
      if (columnComponent.field) {
        let field = 'customer.' + columnComponent.field;
        if (
          this.standardFields.find(
            (cf) => cf.field_name == columnComponent.field
          )
        ) {
          field = 'changeOfAddress.' + columnComponent.field;
        }
        let columnName = this.kendoHelper.getDataFieldName(field);
        if (columnComponent.filter === 'text') {
          searchFilter +=
            (searchFilter ? ' OR ' : '') +
            this.kendoHelper.getFilterString(columnName, 'contains', text);
        }
        if (columnComponent.filter === 'numeric') {
          if (numberRegex.test(text) && parseInt(text) > 0)
            searchFilter +=
              (searchFilter ? ' OR ' : '') +
              this.kendoHelper.getFilterString(
                columnName,
                'eq',
                parseInt(text)
              );
        }
      }
    }
    return searchFilter;
  }

  async makeWebMySelection() {
    let data = {
      display_selection: 'web',
    };
    if (this.router.url.indexOf('ncoa-review') > -1) {
      let currentStep = await this._stepsHelperService.getCurrentStepByBinding(
        appConstants.WorkflowStep.NCOA_Review
      );
      if (currentStep.data != null) {
        data = JSON.parse(currentStep.data);
        data.display_selection = 'web';
      }
      await this._stepsHelperService.updateStepStatus(
        appConstants.WorkflowStep.NCOA_Review,
        currentStep.step_status_id === STEP_STATUS.PENDING
          ? STEP_STATUS.VIEWED
          : null,
        data
      );
    }
  }

  public async removeHandler(args: RemoveEvent) {
    this.recordToDelete = 0;
    if (!args.isNew) {
      this.recordToDelete = args.dataItem['id'];
      this.showConfirmDelete = true;
    }
  }

  public async emittedConfirmDelete(event: any) {
    this.showConfirmDelete = false;
    if (event) {
      this.loadingData = true;
      try {
        await this.changeOfAddressService.delete(this.recordToDelete);
        this.emitOnDataChanged.emit(true);
        await this.getData();
      } catch {
        this.toastr.error(
          await this.translate
            .get('globaltext.WebTableRemoveError')
            .toPromise(),
          '',
          {
            positionClass: 'toast-bottom-center',
          }
        );
      } finally {
        this.loadingData = false;
      }
    }
  }
}
