import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-dynamic-html',
  templateUrl: './dynamic-html.component.html',
  styleUrls: ['./dynamic-html.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DynamicHtmlComponent implements OnInit {
  constructor(public activatedRoute: ActivatedRoute) { }
  @Input() type: string = '';
  ngOnInit(): void {
    var params = this.activatedRoute.snapshot.params;
    if (params && params["type"]) {
      this.type = params["type"];
    }
  }
}