<div class="row mt-5" *ngIf="fileDetails != '' && fileDetails != null">
  <div class="col-12">
    <div class="row">
      <div class="file-details-label">
        {{ "globaltext.LastUploadedFileDetailsLabel" | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="table file-details-table">
          <div class="row table-heading">
            <div class="col-3">FILE NAME</div>
            <div class="col-3">FILE STATUS</div>
            <div class="col-3">UPLOADED DATE</div>
            <div class="col-3">FILE SIZE</div>
          </div>
          <div class="row table-content">
            <div class="col-3">
              {{ fileDetails?.Name }}
            </div>
            <div class="col-3">
              {{ fileStatus }}
            </div>
            <div class="col-3">
              {{ fileDetails?.UploadedDate }}
            </div>
            <div class="col-3">
              {{ fileDetails?.Size }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12" *ngIf="showErrorGrid">
        <kendo-grid
          #grid
          [data]="gridData"
          [pageSize]="gridState.take"
          [skip]="gridState.skip"
          [sort]="gridState.sort"
          [pageable]="true"
          [sortable]="{ mode: 'single' }"
          [navigable]="true"
          [resizable]="false"
          [loading]="loadingError"
          (dataStateChange)="onStateChange($event)"
        >
          <kendo-grid-column
            class="text-uppercase"
            [autoSize]="false"
            field="fileError.error_message"
            title="ERROR MESSAGE"
            [width]="90"
            [filterable]="false"
            [sortable]="false"
          >
            <ng-template kendoGridCellTemplate let-dataItem>
              <div>{{ dataItem.error_message }}</div>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
        <div
          class="mt-2 error-message"
          [innerHTML]="
            'globalrichtext.CustomerFileErrorMessage' | translate | safeHtml
          "
        ></div>
      </div>
    </div>
  </div>
</div>
