import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  DataOwnerEntity,
  DataOwnerService,
  DataOwnerUser,
  RegistrationService,
  SessionService,
  UserEntity,
} from '@cyber/navigator-services';
import { TranslateService } from '@ngx-translate/core';
import { Faq } from 'src/app/models/faq';
import { LoadingService } from 'src/app/services/loading.service';
import { RegionalDataService } from 'src/app/services/regional-data.service';
import { StepsHelperService } from 'src/app/services/steps-helper.service';
import { StorageService } from 'src/app/services/storage.service';
import { appConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  changeNameFormGroup: FormGroup;
  changeEmailFormGroup: FormGroup;
  errorMessage = '';
  status = 'loaded';
  showThankYou = false;
  pendingEmailVerification = true;
  showChangeEvent = false;

  dataOwners: DataOwnerEntity[] = [];
  currentDataOwner!: DataOwnerEntity;
  selectedDataOwnerId!: any;
  dataOwnerUser!: UserEntity;
  changeNameStatus = 'loaded';

  constructor(
    public regionalDataService: RegionalDataService,
    private _sessionService: SessionService,
    private _storageService: StorageService,
    private _registrationService: RegistrationService,
    public stepsHelperService: StepsHelperService,
    public loadingService: LoadingService,
    public router: Router
  ) {
    this.currentDataOwner =
      this.stepsHelperService.getDataOwnerFromLocalStorage();
    this.selectedDataOwnerId = this.currentDataOwner?.id;
    this.changeNameFormGroup = new FormGroup({
      firstName: new FormControl(null, appConstants.FirstNameRegex),
      lastName: new FormControl(null, appConstants.LastNameRegex),
    });

    this.changeEmailFormGroup = new FormGroup(
      {
        email: new FormControl(null, appConstants.EmailRegex),
        confirmEmail: new FormControl(null, appConstants.EmailRegex),
      },
      this.emailMatcher
    );

    let userDetailsString = this._storageService.getLocalStorage(
      appConstants.User
    );
    this.dataOwnerUser = JSON.parse(userDetailsString);
    this.setFormData();
  }

  async ngOnInit(): Promise<void> {
    this.loadingService.show();
    this.dataOwners = await this._sessionService.getRegisteredDataOwners();
    this.loadingService.hide();
  }

  setFormData() {
    this.changeNameFormGroup.patchValue({
      firstName: this.dataOwnerUser?.first_name,
      lastName: this.dataOwnerUser?.last_name,
    });
  }

  emailMatcher(c: AbstractControl): any {
    if (
      !c.get('confirmEmail')?.hasError('required') &&
      !c.get('confirmEmail')?.hasError('pattern')
    ) {
      if (c.get('email')?.value !== c.get('confirmEmail')?.value) {
        c.get('confirmEmail')?.setErrors({ invalid: true });
        return { nomatch: true };
      } else {
        // Remove all errors if confirm email dont have pattern or required error and both values matches.
        c.get('confirmEmail')?.setErrors(null);
        return;
      }
    }
  }

  onChangeNameSubmit(): void {
    if (this.changeNameStatus === 'loading') return;
    this.changeNameFormGroup.markAllAsTouched();
    this.errorMessage = '';
    if (!this.changeNameFormGroup.valid) return;
    this.loadingService.show();
    this.changeNameStatus = 'loading';
    this.dataOwnerUser.first_name = this.firstName?.value;
    this.dataOwnerUser.last_name = this.lastName?.value;
    this._registrationService
      .updateUserName(this.firstName?.value, this.lastName?.value)
      .then((response: any) => {
        this._storageService.setLocalStorage(
          appConstants.User,
          JSON.stringify(this.dataOwnerUser)
        );
        this.changeNameStatus = 'loaded';
        this.loadingService.hide();
      });
  }

  onChangeEmailSubmit(): void {
    if (this.status === 'loading') return;
    // TODO: Send form values to API for login call
    this.changeEmailFormGroup.markAllAsTouched();
    this.errorMessage = '';

    if (this.changeEmailFormGroup.valid) {
      this.status = 'loading';
    }
  }

  closeChangeEvent() {
    this.showChangeEvent = false;
  }

  async changeEventClicked() {
    this.showChangeEvent = true;
  }

  async updateEvent() {
    if (this.selectedDataOwnerId != this.currentDataOwner.id) {
      this.closeChangeEvent();
      this.loadingService.show();
      await this._sessionService
        .switchDataOwner(this.selectedDataOwnerId)
        .then((response: any) => {
          this.loadingService.hide();
          this.router.navigateByUrl('/settingup?url=/home');
        });
    }
    this.closeChangeEvent();
  }

  fieldsMatch() {
    return (
      this.changeEmailFormGroup.hasError('nomatch') &&
      this.changeEmailFormGroup.touched
    );
  }

  //Controls
  get firstName() {
    return this.changeNameFormGroup.get('firstName');
  }
  get lastName() {
    return this.changeNameFormGroup.get('lastName');
  }

  get email() {
    return this.changeEmailFormGroup.get('email');
  }

  get confirmEmail() {
    return this.changeEmailFormGroup.get('confirmEmail');
  }
}
