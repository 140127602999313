import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { STEP_STATUS } from '@cyber/navigator-services';
import { TranslateService } from '@ngx-translate/core';
import { OfferedServices } from 'src/app/models/offered-services';
import { StepsHelperService } from 'src/app/services/steps-helper.service';
import { StorageService } from 'src/app/services/storage.service';
import { appConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-services-offered',
  templateUrl: './services-offered.component.html',
  styleUrls: ['./services-offered.component.scss'],
})
export class ServicesOfferedComponent implements OnInit {
  currentStep: any;
  stateColor = 'gray';
  loadedTip = '';
  showMakeSelectionPopupValue = false;
  showOptoutConfirmationPopupValue = false;
  optedValue: any;
  subheadingUpdated = '';
  ServicesList: OfferedServices[] = [];
  currentDataOwner: any;
  currentDataOwnerId: any;
  minorServiceList: OfferedServices[] = [];
  adultServiceList: OfferedServices[] = [];
  showSectionsBasedOnOptInEndDateReached = '0';
  optInStartDateReached = '0';
  optInStartDate: string = '';

  constructor(
    public translate: TranslateService,
    private _storageService: StorageService,
    private _stepsHelperService: StepsHelperService,
    private _router: Router
  ) {
    this.currentStep = this._stepsHelperService.getCurrentStepByRoute(
      this._router.url
    );
    this.currentDataOwner =
      this._stepsHelperService.getDataOwnerFromLocalStorage();
    this.getServiceList();
  }

  ngOnInit(): void {
    this.optedValue = this.currentStep?.step_status_id;
    this.showSectionsBasedOnOptInEndDateReached =
      this._storageService.getLocalStorage(appConstants.OptInEndDateReached);
    this.optInStartDateReached = this._storageService.getLocalStorage(
      appConstants.OptInStartDateReached
    );

    this.getStateColor();
  }

  closeTip(event: any) {
    this.loadedTip = '';
  }

  showMakeSelectionPopup() {
    this.showMakeSelectionPopupValue = true;
  }

  async emittedPendingOption(selected: any) {
    this.showMakeSelectionPopupValue = false;

    if (selected == null) return;
    if (selected) {
      this.optedValue = STEP_STATUS.OPTED_IN;
      await this._stepsHelperService.updateStepStatus(
        appConstants.WorkflowStep.NotificationOptIn,
        STEP_STATUS.OPTED_IN
      );
      this.resetDepedentStepsWhenOptedIn();
    } else {
      this.showOptoutConfirmationPopupValue = true;
    }
    this.getStateColor();
  }

  async emittedOptoutConfirmationPopup(selected: any) {
    this.showOptoutConfirmationPopupValue = false;

    if (selected == null) return;
    if (selected) {
      this.optedValue = STEP_STATUS.OPTED_IN;
      await this._stepsHelperService.updateStepStatus(
        appConstants.WorkflowStep.NotificationOptIn,
        STEP_STATUS.OPTED_IN
      );

      await this.resetDepedentStepsWhenOptedIn();
    } else {
      this.optedValue = STEP_STATUS.OPTED_OUT;
      await this._stepsHelperService.updateStepStatus(
        appConstants.WorkflowStep.NotificationOptIn,
        STEP_STATUS.OPTED_OUT
      );
      await this._stepsHelperService.updateStepStatus(
        appConstants.WorkflowStep.CustomerUpdate,
        STEP_STATUS.NA_OPTED_OUT
      );
      await this._stepsHelperService.updateStepStatus(
        appConstants.WorkflowStep.Notification_Report,
        STEP_STATUS.NA_OPTED_OUT
      );
      const ncoaReviewStep = this._stepsHelperService.getCurrentStepByBinding(
        appConstants.WorkflowStep.NCOA_Review
      );
      if (ncoaReviewStep.step_status_id !== STEP_STATUS.NOT_YET_AVAILABLE) {
        await this._stepsHelperService.updateStepStatus(
          appConstants.WorkflowStep.NCOA_Review,
          STEP_STATUS.NA_OPTED_OUT
        );
      }
      const exceptionReviewStep =
        this._stepsHelperService.getCurrentStepByBinding(
          appConstants.WorkflowStep.Exceptions_Review
        );
      if (
        exceptionReviewStep.step_status_id !== STEP_STATUS.NOT_YET_AVAILABLE
      ) {
        await this._stepsHelperService.updateStepStatus(
          appConstants.WorkflowStep.Exceptions_Review,
          STEP_STATUS.NA_OPTED_OUT
        );
      }
    }
    this.getStateColor();
  }

  async resetDepedentStepsWhenOptedIn() {
    let customerUpdateStep = this._stepsHelperService.getCurrentStepByBinding(
      appConstants.WorkflowStep.CustomerUpdate
    );
    if (
      customerUpdateStep?.step_status_id == STEP_STATUS.NA_OPTED_OUT ||
      customerUpdateStep?.step_status_id == STEP_STATUS.NOT_YET_AVAILABLE
    ) {
      await this._stepsHelperService.updateStepStatus(
        appConstants.WorkflowStep.CustomerUpdate,
        STEP_STATUS.PENDING
      );
    }
    let notificationReportStep =
      this._stepsHelperService.getCurrentStepByBinding(
        appConstants.WorkflowStep.Notification_Report
      );
    if (notificationReportStep?.step_status_id == STEP_STATUS.NA_OPTED_OUT) {
      await this._stepsHelperService.updateStepStatus(
        appConstants.WorkflowStep.Notification_Report,
        STEP_STATUS.PENDING
      );
    }
    let ncoaReviewStep = this._stepsHelperService.getCurrentStepByBinding(
      appConstants.WorkflowStep.NCOA_Review
    );
    if (ncoaReviewStep?.step_status_id == STEP_STATUS.NA_OPTED_OUT) {
      await this._stepsHelperService.updateStepStatus(
        appConstants.WorkflowStep.NCOA_Review,
        STEP_STATUS.PENDING
      );
    }
  }

  getStateColor() {
    let currentStatus = this.translate.instant('globalrichtext.CurrentStatus');

    switch (this.optedValue) {
      case STEP_STATUS.OPTED_OUT:
        {
          this.stateColor = 'b-red';
          this.subheadingUpdated = currentStatus.replace(
            '{{Selection_Type}}',
            this.translate.instant('globaltext.OptedOut')
          );
        }
        break;
      case STEP_STATUS.OPTED_IN:
        {
          this.stateColor = 'b-green';
          this.subheadingUpdated = currentStatus.replace(
            '{{Selection_Type}}',
            this.translate.instant('globaltext.OptedIn')
          );
        }
        break;
      default:
        {
          this.stateColor = 'b-gray';
          this.subheadingUpdated = currentStatus.replace(
            '{{Selection_Type}}',
            this.translate.instant('globaltext.PendingSelection')
          );
        }
        break;
    }
    this._stepsHelperService.stepDataUpdate$.next(true);
  }

  getServiceList() {
    let current_page_content: any;
    this.adultServiceList = [];
    this.minorServiceList = [];
    this.translate.stream('page').subscribe((res: any) => {
      let cms_page_data = res;
      current_page_content = cms_page_data[this.currentStep?.step?.cms_binding];

      if (
        current_page_content?.pageservices != null &&
        current_page_content?.pageservices != '' &&
        current_page_content?.pageservices != undefined
      ) {
        let services = this.getOfferedServices();
        current_page_content?.pageservices.forEach((s: any) => {
          let resultService = services.find(
            (item: OfferedServices) => item.binding == s.service
          );
          if (resultService != undefined) {
            if (resultService?.group == 'adult') {
              this.adultServiceList.push(resultService);
            } else {
              this.minorServiceList.push(resultService);
            }
          }
        });
      }
    });
  }

  getOfferedServices(): OfferedServices[] {
    let servicesList: OfferedServices[] = [
      {
        name: 'Single Bureau Credit Report',
        group: 'adult',
        content_binding: 'service_single_bureau_credit_report',
        icon: 'single-burea-credit-monitoring',
        binding: 'single_bureau_credit_report',
      },
      {
        name: 'Single Bureau Credit Monitoring',
        group: 'adult',
        content_binding: 'service_single_bureau_credit_monitoring',
        icon: 'single-burea-credit-report',
        binding: 'single_bureau_credit_monitoring',
      },
      {
        name: 'Three Bureau Credit Monitoring',
        group: 'adult',
        content_binding: 'service_three_bureau_credit_monitoring',
        icon: 'three-burea-credit-monitoring',
        binding: 'three_bureau_credit_monitoring',
      },
      {
        name: 'Web Watcher Internet Monitoring',
        group: 'adult',
        content_binding: 'service_web_watcher_internet_monitoring',
        icon: 'web-watcher-internet-monitoring',
        binding: 'web_watcher_internet_monitoring',
      },
      {
        name: 'Public Persona',
        group: 'adult',
        content_binding: 'service_public_persona',
        icon: 'public-persona',
        binding: 'public_persona',
      },
      {
        name: 'Quick Cash Scan',
        group: 'adult',
        content_binding: 'service_quick_cash',
        icon: 'quick-cash',
        binding: 'quick_cash',
      },
      {
        name: '$1 Million Identity Fraud Loss Reimbursement',
        group: 'adult',
        content_binding: 'service_1m_idenity_fraud',
        icon: '1m-idenity-fraud',
        binding: '1m_idenity_fraud',
      },
      {
        name: 'Consumer Consultation',
        group: 'adult',
        content_binding: 'service_consumer_consultation',
        icon: 'consumer-consultation',
        binding: 'consumer_consultation',
      },
      {
        name: 'Consumer Notification',
        group: 'adult',
        content_binding: 'service_consumer_notification',
        icon: 'consumer-notification',
        binding: 'consumer_notification',
      },
      {
        name: 'Identity Restoration',
        group: 'adult',
        content_binding: 'service_identity_restoration',
        icon: 'identity-restoration',
        binding: 'identity_restoration',
      },
      {
        name: 'Call Center Support',
        group: 'adult',
        content_binding: 'service_call_center_support',
        icon: 'call-center-support',
        binding: 'call_center_support',
      },
      {
        name: 'Minor Monitoring',
        group: 'minor',
        content_binding: 'service_minor_monitoring',
        icon: 'minor-monitoring',
        binding: 'minor_monitoring',
      },
      {
        name: 'Minor Consumer Notification',
        group: 'minor',
        content_binding: 'service_consumer_notification_minor',
        icon: 'consumer-notification',
        binding: 'consumer_notification_minor',
      },
      {
        name: 'Minor Consumer Consultation',
        group: 'minor',
        content_binding: 'service_consumer_consultation_minor',
        icon: 'consumer-consultation',
        binding: 'consumer_consultation_minor',
      },
      {
        name: 'Minor Call Center Support',
        group: 'minor',
        content_binding: 'service_call_center_support_minor',
        icon: 'call-center-support',
        binding: 'call_center_support_minor',
      },
      {
        name: 'Minor Identity Restoration',
        group: 'minor',
        content_binding: 'service_identity_restoration_minor',
        icon: 'identity-restoration',
        binding: 'identity_restoration_minor',
      },
    ];

    return servicesList;
  }
}
