import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ContentService } from 'src/app/services/content.service';

@Component({
  selector: 'app-important-information',
  templateUrl: './important-information.component.html',
  styleUrls: ['./important-information.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImportantInformationComponent implements OnInit {


  Content='';
  constructor(private router: Router,
    public contentService: ContentService,
    public translate: TranslateService ) {
  }

  ngOnInit(): void {
  }

  nextPage(){
    this.router.navigate(['/mobile-device-notice']);
  }

  get UpdatedContent(){
    this.Content = this.translate.instant('globalrichtext.ImportantInformationContent');
    return this.contentService.replaceContentMonikers(this.Content);
  }
}