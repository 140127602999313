<div class="container-fluid full-height">
  <div class="container main-content-container">
    <div class="row pb-5 mr-3 ml-2">
      <div class="col-8">
        <div class="mt-5">
          <div
            class="h1-heading grey-heading"
            [innerHTML]="pageCms.title | safeHtml"
          ></div>
          <div
            class="grey-message mt-3"
            [innerHTML]="pageCms.pagecontent?.text | safeHtml"
          ></div>
        </div>
        <div class="row">
          <div class="col">
            <form #userForm="ngForm">
              <section *ngFor="let user of users; let i = index">
                <div class="col-12">
                  <div class="row">
                    <div class="col-4">
                      <span
                        ><b>USER {{ i + 1 }} of 3</b></span
                      >
                    </div>
                    <div class="col-8">
                      <span *ngIf="i > 0" class="f-right">
                        <button
                          type="button"
                          class="btn-remove d-flex"
                          (click)="removeUser(i)"
                        >
                          <i class="material-icons icon pr-1"
                            >person_remove_alt_1</i
                          >
                          Remove User
                        </button>
                      </span>
                    </div>
                  </div>
                  <div class="row d-inline">
                    <app-redeem-add-user
                      [events]="eventsSubject.asObservable()"
                      [userDetails]="user"
                    >
                    </app-redeem-add-user>
                  </div>
                </div>
              </section>
            </form>
          </div>
        </div>
        <div>
          <button
            *ngIf="showAddUser"
            type="button"
            class="btn-secondary-add d-flex"
            (click)="addUser()"
          >
            <i class="material-icons icon pr-1">person_add</i>
            Add an Additional User
          </button>
        </div>
        <div *ngIf="errorMessage !== ''" class="row col recover-error-message" [innerHTML]="'globalrichtext.' + errorMessage | translate | safeHtml">
        </div>
        <div class="col-sm-4">
          <button
            type="submit"
            class="btn btn-primary btn-block"
            (click)="submit()"
          >
            <span>SUBMIT</span>
            <ng-container *ngIf="status === 'loading'">
              <app-loading-spinner></app-loading-spinner>
            </ng-container>
          </button>
        </div>
      </div>

      <div class="col-4">
        <app-info-box
          [Content]="'globalrichtext.RedeemAddUser' | translate"
        ></app-info-box>
      </div>
    </div>
  </div>
</div>
