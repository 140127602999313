import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-session-popup',
  templateUrl: './session-popup.component.html',
  styleUrls: ['./session-popup.component.scss']
})
export class SessionPopupComponent implements OnInit {

  @Input() showPopup = false;
  @Output() emitPopupStatus = new EventEmitter();

  sessionExtendClicked = false;
  constructor() { }

  ngOnInit(): void {
    this.sessionExtendClicked = false;
  }

  closeSessionWarningModel(){
    if(!this.sessionExtendClicked){
      this.sessionExtendClicked = true;
      this.emitPopupStatus.emit(true);
    }
  }

}
