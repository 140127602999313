<div class="row mt-3 p-3 options-div">
    <div class="col-3 gray-box-section">
        <div class="gray-div hover-hand" (click)="navigateTo('web')">
            <app-svg [type]="'review-web-table'"></app-svg>
            <b class="small-heading-primary">{{'globaltext.interactive_web_table' | translate}}</b>
        </div>
        <div>
            <a class="mt-3 secondary-link font-sans link-with-image">
                <span class="material-icons pr-1">
                    info
                    </span>
                <span (click)="loadedTip='globalrichtext.DataReviewWebTableInfo'">{{'globaltext.web_table_info' | translate}}</span>
            </a>
        </div>
    </div>
    <div class="col-1 d-flex justify-content-center">
        <b class="steps-page-heading tile-color">OR</b>
    </div>
    <div class="col-3 gray-box-section">
        <div class="col-3 gray-box-section"  (click)="navigateTo('file')">
            <div class="gray-div hover-hand">
                <app-svg [type]="'review-file'"></app-svg>
                <b class="small-heading-primary">{{'globaltext.file_exchange' | translate}}</b>
            </div>
        </div>
        <div>
            <a class="mt-3 secondary-link font-sans link-with-image">
                <span class="material-icons pr-1">
                    info
                    </span>
                <span (click)="loadedTip='globalrichtext.DataReviewFileExchangeInfo'">{{'globaltext.file_exchange_info' | translate}}</span>
            </a>
        </div>
    </div>
</div>
<app-tips [show]="loadedTip!=''" (closestate)="closeTip($event)" [pageType]="loadedTip"></app-tips>