import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  CustomerDownloadProcessingStatus,
  CustomerService,
  STEP_STATUS,
} from '@cyber/navigator-services';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from 'src/app/services/loading.service';
import { StepsHelperService } from 'src/app/services/steps-helper.service';
import { appConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-download-report',
  templateUrl: './download-report.component.html',
  styleUrls: ['./download-report.component.scss'],
})
export class DownloadReportComponent implements OnInit {
  currentDataOwner: any;
  currentStep!: any;
  availableToDownload = false;
  public loadedTip = '';
  error = '';
  finalReportStatus: any;
  errorMessage = '';
  fileType: string = 'xlsx';
  interval: any;
  fileGenerationStatus!: CustomerDownloadProcessingStatus;

  constructor(
    private _stepsHelperService: StepsHelperService,
    private _router: Router,
    private _customerService: CustomerService,
    private _loadingService: LoadingService,
    private _translationService: TranslateService
  ) {
    this.currentDataOwner =
      this._stepsHelperService.getDataOwnerFromLocalStorage();
    this.currentStep = this._stepsHelperService.getCurrentStepByRoute(
      this._router.url
    );
    if (
      this.currentStep.step_status_id != STEP_STATUS.NOT_YET_AVAILABLE &&
      this.currentStep.step_status_id != STEP_STATUS.NA_OPTED_OUT
    ) {
      this.availableToDownload = true;
    } else {
      if (this.currentStep.step_status_id == STEP_STATUS.NOT_YET_AVAILABLE)
        this.errorMessage = this._translationService.instant(
          'globalrichtext.FinalReportNotYetAvailable'
        );

      if (this.currentStep.step_status_id == STEP_STATUS.NA_OPTED_OUT)
        this.errorMessage = this._translationService.instant(
          'globalrichtext.StepNotAvailable'
        );
    }
  }

  async ngOnInit(): Promise<void> {
    this._loadingService.show();
    try {
      this.fileGenerationStatus =
        await this._customerService.getFinalReportGenerationStatus();
    } catch (err: any) {
      console.error(err);
    } finally {
      this._loadingService.hide();
    }

    this.pollForStatusUntilComplete();
  }

  async downloadFile() {
    this.error = '';
    this._loadingService.show();
    try {
      this.fileType = (
        await this._customerService.getDownloadType()
      ).toLowerCase();
      if (this.fileType === 'xlsx') {
        let response = await this._customerService.downloadFinalReport();
        this.processFileDownload(response);
      } else if (this.fileType === 'zip') {
        if (!this.fileGenerationStatus) {
          await this._customerService.startFinalReportGeneration();
          this.fileGenerationStatus =
            await this._customerService.getFinalReportGenerationStatus();
          this.pollForStatusUntilComplete();
        } else if (this.fileGenerationStatus?.percentage_complete > 100) {
          let response = await this._customerService.downloadZip(
            this.fileGenerationStatus.download_id
          );
          this.processFileDownload(response);
        }
      }
    } catch {
      this.error = 'Error while downloading file. Please contact support';
    }
    this._loadingService.hide();
  }

  async processFileDownload(response: any) {
    let downloadLink = document.createElement('a');
    downloadLink.setAttribute('download', 'DataFile.' + this.fileType);
    downloadLink.href = window.URL.createObjectURL(response.body);
    downloadLink.click();
    await this._stepsHelperService.updateStepStatus(
      appConstants.WorkflowStep.Notification_Report,
      STEP_STATUS.DOWNLOADED
    );
    this._stepsHelperService.stepDataUpdate$.next(true);
  }

  pollForStatusUntilComplete() {
    this.interval = setInterval(async () => {
      try {
        this.fileGenerationStatus =
          await this._customerService.getFinalReportGenerationStatus();
        if (
          !this.fileGenerationStatus || //No status was returned -- no files actively being processed
          this.fileGenerationStatus.percentage_complete > 100 //File is complete, no need to keep polling
        ) {
          clearInterval(this.interval);
        }
      } catch (err: any) {
        console.error(err);
      }
    }, 15 * 1000);
  }
}
