import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-footer-prelogin',
  templateUrl: './footer-prelogin.component.html',
  styleUrls: ['./footer-prelogin.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterPreloginComponent implements OnInit {
  copyrightYear = 0;
  constructor() { }

  ngOnInit(): void {
    this.copyrightYear = new Date().getFullYear();
  }

}
