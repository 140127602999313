import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '@cyber/navigator-services';
@Component({
  selector: 'app-login',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  errorMessage = '';
  status = 'loaded';
  constructor(public sessionService: SessionService, public router: Router) {
    this.sessionService.isAuthenticated$.subscribe((isAuthenticated) => {
      if (isAuthenticated === false) {
        this.sessionService.loginWithRedirect('/settingup?url=/home');
      } else {
        this.router.navigateByUrl('/settingup?url=/home');
      }
    });
  }

  ngOnInit(): void {}

  redirectToEnroll() {}
}
