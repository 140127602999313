import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-review-web-table-tips',
  templateUrl: './review-web-table-tips.component.html',
  styleUrls: ['./review-web-table-tips.component.scss']
})
export class ReviewWebTableTipsComponent implements OnInit {

  public loadedTip = "";
  constructor() { }

  ngOnInit(): void {
  }

}
