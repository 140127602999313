import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
@Injectable({
  providedIn: "root"
})
export class DigicertService {

  constructor(private router: Router) {
  }

  digiCertHeadScripts(sealCode: string) {
    const head = document.getElementsByTagName("head")[0];
    const digiCertScriptTag = document.createElement("script");

    digiCertScriptTag.innerHTML = `
      var __dcid = __dcid || [];
      __dcid.push(["DigiCertClickID_` + sealCode + `", "3", "m", "black", "` + sealCode + `"]);
      (function () {
        var cid = document.createElement("script");
        cid.async = true;
        cid.src = "//seal.digicert.com/seals/cascade/seal.min.js";
        var s = document.getElementsByTagName("script");
        var ls = s[(s.length - 1)];
        ls.parentNode.insertBefore(cid, ls.nextSibling);
      }());`;

    head.insertBefore(digiCertScriptTag, head.firstChild);
  }
}
