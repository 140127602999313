import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { OutageGuard } from '@cyber/navigator-services';
import { SettingUpEnvironmentComponent } from './components/setting-up-environment/setting-up-environment.component';
import { SessionGuard } from './guards/session-guard';
import { PostloginComponent } from './layouts/postlogin/postlogin.component';
import { PreloginComponent } from './layouts/prelogin/prelogin.component';
import { TranslationResolverService } from './services/translation-resolver.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home', //AuthGuard will redirect to sign in if not authenticated
    pathMatch: 'full',
  },
  {
    path: '',
    component: PreloginComponent,
    loadChildren: () =>
      import('./layouts/prelogin/prelogin.module').then(
        (m) => m.PreloginModule
      ),
    resolve: { model: TranslationResolverService },
  },
  {
    path: '',
    component: PostloginComponent,
    loadChildren: () =>
      import('./layouts/postlogin/postlogin.module').then(
        (m) => m.PostloginModule
      ),
    resolve: { model: TranslationResolverService },
    canActivate: [OutageGuard],
    canActivateChild: [OutageGuard],
  },
  {
    path: 'settingup',
    component: SettingUpEnvironmentComponent,
    canActivate: [AuthGuard, SessionGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollOffset: [0, 0],
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
