import { Component, OnInit } from '@angular/core';
import {
  CustomerDownloadProcessingStatus,
  CustomerExceptionService,
  STEP_STATUS,
} from '@cyber/navigator-services';
import { LoadingService } from 'src/app/services/loading.service';
import { StepsHelperService } from 'src/app/services/steps-helper.service';
import { appConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-review-customer-exceptions-file',
  templateUrl: './review-customer-exceptions-file.component.html',
  styleUrls: ['./review-customer-exceptions-file.component.scss'],
})
export class ReviewCustomerExceptionsFileComponent implements OnInit {
  public loadedTip = '';
  error = '';
  currentDataOwner: any;
  fileType: string = 'xlsx';
  interval: any;
  fileGenerationStatus!: CustomerDownloadProcessingStatus;
  constructor(
    private _stepsHelperService: StepsHelperService,
    private _customerExceptionService: CustomerExceptionService,
    private _loadingService: LoadingService
  ) {}

  async ngOnInit(): Promise<void> {
    this._loadingService.show();
    try {
      this.currentDataOwner =
        this._stepsHelperService.getDataOwnerFromLocalStorage();
      await this.makeFileAsMySelection();
      this.fileGenerationStatus =
        await this._customerExceptionService.getFileGenerationStatus();
    } catch (err: any) {
      console.error(err);
    } finally {
      this._loadingService.hide();
    }

    this.pollForStatusUntilComplete();
  }

  async makeFileAsMySelection() {
    let data = {
      display_selection: 'file',
    };
    let currentStep = await this._stepsHelperService.getCurrentStepByBinding(
      appConstants.WorkflowStep.Exceptions_Review
    );
    if (currentStep.data != null) {
      data = JSON.parse(currentStep.data);
      data.display_selection = 'file';
    }
    await this._stepsHelperService.updateStepStatus(
      appConstants.WorkflowStep.Exceptions_Review,
      null,
      data
    );
  }

  async downloadFile() {
    this.error = '';
    this._loadingService.show();
    try {
      this.fileType = (
        await this._customerExceptionService.getDownloadType()
      ).toLowerCase();
      if (this.fileType === 'xlsx') {
        let response = await this._customerExceptionService.downloadFile();
        this.processFileDownload(response);
      } else if (this.fileType === 'zip') {
        if (!this.fileGenerationStatus) {
          await this._customerExceptionService.startDataFileGeneration();
          this.fileGenerationStatus =
            await this._customerExceptionService.getFileGenerationStatus();
          this.pollForStatusUntilComplete();
        } else if (this.fileGenerationStatus?.percentage_complete > 100) {
          let response = await this._customerExceptionService.downloadZip(
            this.fileGenerationStatus.download_id
          );
          this.processFileDownload(response);
        }
      }
    } catch {
      this.error = 'Error while downloading file. Please contact support';
    }
    this._loadingService.hide();
  }

  async processFileDownload(response: any) {
    let downloadLink = document.createElement('a');
    downloadLink.setAttribute('download', 'DataFile.' + this.fileType);
    downloadLink.href = window.URL.createObjectURL(response.body);
    downloadLink.click();
    let currentStep = await this._stepsHelperService.getCurrentStepByBinding(
      appConstants.WorkflowStep.Exceptions_Review
    );

    if (
      currentStep.step_status_id !== STEP_STATUS.NO_UPDATES_REQUIRED &&
      currentStep.step_status_id !== STEP_STATUS.UPDATED &&
      currentStep.step_status_id !== STEP_STATUS.IN_PROGRESS
    ) {
      await this._stepsHelperService.updateStepStatus(
        appConstants.WorkflowStep.Exceptions_Review,
        STEP_STATUS.DOWNLOADED
      );
      this._stepsHelperService.stepDataUpdate$.next(true);
    }
  }

  pollForStatusUntilComplete() {
    this.interval = setInterval(async () => {
      try {
        this.fileGenerationStatus =
          await this._customerExceptionService.getFileGenerationStatus();
        if (
          !this.fileGenerationStatus || //No status was returned -- no files actively being processed
          this.fileGenerationStatus.percentage_complete > 100 //File is complete, no need to keep polling
        ) {
          clearInterval(this.interval);
        }
      } catch (err: any) {
        console.error(err);
      }
    }, 15 * 1000);
  }
}
