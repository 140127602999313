import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivityService, DataOwnerWorkflowStep, Region, RegionService, STEP_STATUS } from '@cyber/navigator-services';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from 'src/app/services/loading.service';
import { MemberService } from 'src/app/services/member.service';
import { StepsHelperService } from 'src/app/services/steps-helper.service';
import { StorageService } from 'src/app/services/storage.service';
import { appConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-form-collection',
  templateUrl: './form-collection.component.html',
  styleUrls: ['./form-collection.component.scss']
})
export class FormCollectionComponent implements OnInit {
  currentStep: any;
  fg!: FormGroup;
  optedValue: number = 0;
  editable = false;
  states!: Region[];
  phoneTypes!: any[];
  showMakeSelectionPopupValue = false;
  showOptoutConfirmationPopupValue = false;
  subheadingUpdated = "";
  stateColor = "";
  public loadedTip = "";
  formData: any;
  showSectionsBasedOnOptInEndDateReached = '0';
  constructor(public translate: TranslateService,
    private _storageService: StorageService,
    private _router: Router,
    private _stepsHelperService: StepsHelperService,
    private _activityService: ActivityService,
    private memberService: MemberService,
    private _regionService: RegionService,
    private _loadingService: LoadingService) {
    this._loadingService.show();
    this._regionService.fetchRegionForCountryId(appConstants.countryId).then((data: any) => {
      this.states = data;
      this._loadingService.hide();
    });
    this.currentStep = this._stepsHelperService.getCurrentStepByRoute(this._router.url);
  }

  ngOnInit(): void {
    this.optedValue = this.currentStep.step_status_id;
    this.showSectionsBasedOnOptInEndDateReached = this._storageService.getLocalStorage(appConstants.OptInEndDateReached);
    this.getFormDropDownValues();
    this.getStateColor();
    if (this.optedValue === STEP_STATUS.OPTED_IN_PENDING_SUBMISSION)
      this.editable = true;
    this.fg = new FormGroup({
      nameOfCoveredEntity: new FormControl('', appConstants.CoveredEntityNameRegex),
      streetAddress1: new FormControl('', appConstants.AddressRegex),
      streetAddress2: new FormControl('', appConstants.Address2Regex),
      city: new FormControl('', appConstants.CityRegex),
      state: new FormControl('-1', [Validators.required]),
      zipCode: new FormControl('', appConstants.ZipCodeRegex),
      firstName: new FormControl('', appConstants.FirstNameRegex),
      lastName: new FormControl('', appConstants.LastNameRegex),
      email: new FormControl('', appConstants.EmailRegex),
      primaryPhone: new FormControl('', appConstants.PhoneNumberRegex),
      primaryPhoneType: new FormControl('-1', [Validators.required]),
      secondaryPhone: new FormControl('', appConstants.SecondaryPhoneNumberRegex),
      secondaryPhoneType: new FormControl('-1'),

    });
    this.setFormData();
  }

  setFormData() {
    if (this.currentStep?.data == null || this.currentStep?.data == '' || this.currentStep?.data == undefined || this.currentStep?.data == 'undefined')
      return;
    this.formData = JSON.parse(this.currentStep?.data);
    this.fg.patchValue({
      nameOfCoveredEntity: this.formData.nameOfCoveredEntity,
      streetAddress1: this.formData.streetAddress1,
      streetAddress2: this.formData.streetAddress2,
      city: this.formData.city,
      state: this.formData.state,
      zipCode: this.formData.zipCode,
      firstName: this.formData.firstName,
      lastName: this.formData.lastName,
      email: this.formData.email,
      primaryPhone: this.formData.primaryPhone,
      primaryPhoneType: this.formData.primaryPhoneType == null || this.formData.primaryPhoneType == undefined ? '-1' : this.formData.primaryPhoneType,
      secondaryPhone: this.formData.secondaryPhone,
      secondaryPhoneType: this.formData.secondaryPhoneType == null || this.formData.secondaryPhoneType == undefined ? '-1' : this.formData.secondaryPhoneType,
    });

  }
  showMakeSelectionPopup() {
    this.showMakeSelectionPopupValue = true;
  }

  async emittedPendingOption(selected: any) {
    this.showMakeSelectionPopupValue = false;

    if (selected == null) return;
    if (selected) {
      this.optedValue = STEP_STATUS.OPTED_IN_PENDING_SUBMISSION;
      await this._stepsHelperService.updateStepStatus(this.currentStep?.step?.cms_binding, this.optedValue);
      this.editable = true;
    }
    else {
      this.showOptoutConfirmationPopupValue = true;
      this.editable = false;
    }
    this.getStateColor();
  }

  async emittedOptoutConfirmationPopup(selected: any) {
    this.showOptoutConfirmationPopupValue = false;

    if (selected == null) return;
    if (selected) {
      this.optedValue = STEP_STATUS.OPTED_IN_PENDING_SUBMISSION;
      await this._stepsHelperService.updateStepStatus(this.currentStep?.step?.cms_binding, this.optedValue);
      this.editable = true;
    }
    else {
      this.optedValue = STEP_STATUS.OPTED_OUT;
      this.formData = '';
      this.fg.reset();

      this.fg.patchValue({
        state: -1,
        primaryPhoneType: -1,
        secondaryPhoneType: -1
      });
      await this._stepsHelperService.updateStepStatus(this.currentStep?.step?.cms_binding, this.optedValue, '{}');
      this.editable = false;
    }
    this.getStateColor();
  }

  getFormDropDownValues() {
    this.translate.stream(['globaltext.PhoneTypes']).subscribe((res: any) => {
      this.phoneTypes = this.getTypes(res['globaltext.PhoneTypes']);
    });
  }

  private getTypes(typesString: any) {
    let typesList: any[] = [];
    let types = typesString?.split("|");
    if (types != null) {
      types.forEach((item: string) => {
        let type = item?.split("=");
        if (type.length == 2)
          typesList.push({ id: type[0], name: type[1] });
      });
    }
    return typesList;
  }

  getStateColor() {
    let currentStatus = this.translate.instant('globalrichtext.CurrentStatus');

    switch (this.optedValue) {
      case STEP_STATUS.OPTED_OUT: {
        this.stateColor = 'b-red';
        this.subheadingUpdated = currentStatus.replace('{{Selection_Type}}', this.translate.instant('globaltext.OptedOut'));
      }
        break;
      case STEP_STATUS.OPTED_IN_PENDING_SUBMISSION: {
        this.stateColor = 'b-yellow';
        this.subheadingUpdated = currentStatus.replace('{{Selection_Type}}', this.translate.instant('globaltext.OptedInPendingForm'));
      }
        break;
      case STEP_STATUS.OPTED_IN: {
        this.stateColor = 'b-green';
        this.subheadingUpdated = currentStatus.replace('{{Selection_Type}}', this.translate.instant('globaltext.OptedIn'));
      }
        break;
      case STEP_STATUS.OPTED_IN_SUBMITTED: {
        this.stateColor = 'b-green';
        this.subheadingUpdated = currentStatus.replace('{{Selection_Type}}', this.translate.instant('globaltext.OptedInSubmitted'));
      }
        break;
      default: {
        this.stateColor = 'b-gray';
        this.subheadingUpdated = currentStatus.replace('{{Selection_Type}}', this.translate.instant('globaltext.PendingSelection'));
      }
        break;
    }
    this._stepsHelperService.stepDataUpdate$.next(true);
  }

  async onSubmit() {
    this.fg.markAllAsTouched();
    this.checkSelectedValue(this.primaryPhoneType);
    this.checkSelectedValue(this.state);
    if (!this.fg.valid)
    {
      this.memberService.errorInForm.next(true);
      return;
    }
    this.formData = this.fg.value;
    this.states.filter((x: any) => {
      if (x.id == this.state?.value)
        this.formData.stateName = x.name
    });
    this.phoneTypes.filter((x: any) => {
      if (x.id == this.primaryPhoneType?.value)
        this.formData.primaryPhoneTypeName = x.name
    });
    this.phoneTypes.filter((x: any) => {
      if (x.id == this.secondaryPhoneType?.value)
        this.formData.secondaryPhoneTypeName = x.name
    });
    if (this.formData.secondaryPhoneType?.value == -1) {
      this.formData.secondaryPhoneType?.setValue("");
    }
    this.optedValue = STEP_STATUS.OPTED_IN_SUBMITTED;
    this.editable = false;
    await this._stepsHelperService.updateStepStatus(this.currentStep?.step?.cms_binding, this.optedValue, this.formData);
    this.getStateColor();
  }


  onEdit() {
    this.currentStep = this._stepsHelperService.getCurrentStepByRoute(this._router.url);
    this.setFormData();
    this.editable = true;
  }

  checkSelectedValue(formControl: any) {
    var control = formControl;
    if (formControl?.value == "-1") {
      formControl?.setErrors({ required: true });
    }
  }

  formatAsPhone(formControl: any, keyCode: any) {
    var control = formControl;
    var value = formControl?.value;
    // //Checking keyCode to make sure that the backspace or delete key weren’t pressed.
    if (keyCode.keyCode !== 8 && keyCode.keyCode !== 46) {
      const input = value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
      let newVal = '';
      const zip = input.substring(0, 3);
      const middle = input.substring(3, 6);
      const last = input.substring(6, 10);

      if (input.length > 6) {
        newVal = `(${zip}) ${middle}-${last}`;
      } else if (input.length > 3) {
        newVal = `(${zip}) ${middle}`;
      } else if (input.length > 0) {
        newVal = `(${zip}`;
      }

      control?.setValue(newVal);
    }
  }
  
  get nameOfCoveredEntity() {
    return this.fg.get("nameOfCoveredEntity");
  }

  get streetAddress1() {
    return this.fg.get("streetAddress1");
  }
  get streetAddress2() {
    return this.fg.get("streetAddress2");
  }
  get city() {
    return this.fg.get("city");
  }
  get state() {
    return this.fg.get("state");
  }
  get zipCode() {
    return this.fg.get("zipCode");
  }
  get firstName() {
    return this.fg.get("firstName");
  }
  get lastName() {
    return this.fg.get("lastName");
  }
  get email() {
    return this.fg.get("email");
  }
  get primaryPhone() {
    return this.fg.get("primaryPhone");
  }
  get primaryPhoneType() {
    return this.fg.get("primaryPhoneType");
  }
  get secondaryPhone() {
    return this.fg.get("secondaryPhone");
  }
  get secondaryPhoneType() {
    return this.fg.get("secondaryPhoneType");
  }
}
