import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { RoleGuard } from '@cyber/navigator-services';
import { CmsFileDownloadComponent } from 'src/app/components/cms-file-download/cms-file-download.component';
import { DashboardComponent } from 'src/app/components/dashboard/dashboard.component';
import { DynamicKendoComponent } from 'src/app/components/dynamic-kendo/dynamic-kendo.component';
import { DynamicComponent } from 'src/app/components/dynamic/dynamic.component';
import { FinishSetupComponent } from 'src/app/components/finish-setup/finish-setup.component';
import { ProfileComponent } from 'src/app/components/profile/profile.component';
import { ReviewChangeOfAddressFileComponent } from 'src/app/components/review-change-of-address-file/review-change-of-address-file.component';
import { ReviewChangeOfAddressWebComponent } from 'src/app/components/review-change-of-address-web/review-change-of-address-web.component';
import { ReviewCustomerExceptionsWebComponent } from 'src/app/components/review-customer-exceptions-web/review-customer-exceptions-web.component';
import { SelectEventComponent } from 'src/app/components/select-event/select-event.component';
import { WebTableComponent } from 'src/app/components/web-table/web-table.component';
import { ImportantInformationComponent } from 'src/app/components/welcome-steps/important-information/important-information.component';
import { LegalDisclaimerComponent } from 'src/app/components/welcome-steps/legal-disclaimer/legal-disclaimer.component';
import { MobileDeviceNoticeComponent } from 'src/app/components/welcome-steps/mobile-device-notice/mobile-device-notice.component';
import { WelcomeComponent } from 'src/app/components/welcome-steps/welcome/welcome.component';
import { SessionGuard } from 'src/app/guards/session-guard';
import { appConstants } from 'src/app/shared/app-constants';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    component: DashboardComponent,
    canActivate: [
      AuthGuard,
      SessionGuard,
      RoleGuard.forRoles(appConstants.DataOwner),
    ],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [
      AuthGuard,
      SessionGuard,
      RoleGuard.forRoles(appConstants.DataOwner),
    ],
  },
  {
    path: 'info-center',
    component: DynamicComponent,
    canActivate: [
      AuthGuard,
      SessionGuard,
      RoleGuard.forRoles(appConstants.DataOwner),
    ],
  },
  {
    path: 'regulator-resources',
    component: DynamicComponent,
    canActivate: [
      AuthGuard,
      SessionGuard,
      RoleGuard.forRoles(appConstants.DataOwner),
    ],
  },
  {
    path: 'final-report',
    component: DynamicKendoComponent,
    canActivate: [
      AuthGuard,
      SessionGuard,
      RoleGuard.forRoles(appConstants.DataOwner),
    ],
  },

  {
    path: 'data-review',
    loadChildren: () =>
      import('../../shared/shared-kendo/shared-kendo.module').then(
        (m) => m.SharedKendoModule
      ),
    canActivate: [
      AuthGuard,
      SessionGuard,
      RoleGuard.forRoles(appConstants.DataOwner),
    ],
  },
  {
    path: 'ncoa-review',
    loadChildren: () =>
      import('../../shared/shared-kendo/shared-kendo.module').then(
        (m) => m.SharedKendoModule
      ),
    canActivate: [
      AuthGuard,
      SessionGuard,
      RoleGuard.forRoles(appConstants.DataOwner),
    ],
  },
  {
    path: 'data-update',
    loadChildren: () =>
      import('../../shared/shared-kendo/shared-kendo.module').then(
        (m) => m.SharedKendoModule
      ),
    canActivate: [
      AuthGuard,
      SessionGuard,
      RoleGuard.forRoles(appConstants.DataOwner),
    ],
  },
  {
    path: 'notification-optin',
    component: DynamicComponent,
    canActivate: [
      AuthGuard,
      SessionGuard,
      RoleGuard.forRoles(appConstants.DataOwner),
    ],
  },
  {
    path: 'regulator-optin',
    component: DynamicComponent,
    canActivate: [
      AuthGuard,
      SessionGuard,
      RoleGuard.forRoles(appConstants.DataOwner),
    ],
  },
  {
    path: 'exceptions-review',
    loadChildren: () =>
      import('../../shared/shared-kendo/shared-kendo.module').then(
        (m) => m.SharedKendoModule
      ),
    canActivate: [
      AuthGuard,
      SessionGuard,
      RoleGuard.forRoles(appConstants.DataOwner),
    ],
  },
  {
    path: 'exceptions-table/:mode',
    component: ReviewCustomerExceptionsWebComponent,
    canActivate: [
      AuthGuard,
      SessionGuard,
      RoleGuard.forRoles(appConstants.DataOwner),
    ],
  },
  {
    path: 'web-table/:mode',
    component: WebTableComponent,
    canActivate: [
      AuthGuard,
      SessionGuard,
      RoleGuard.forRoles(appConstants.DataOwner),
    ],
  },
  {
    path: 'ncoa-table/:mode',
    component: ReviewChangeOfAddressWebComponent,
    canActivate: [
      AuthGuard,
      SessionGuard,
      RoleGuard.forRoles(appConstants.DataOwner),
    ],
  },
  {
    path: 'd/:page',
    component: DynamicComponent,
    canActivate: [
      AuthGuard,
      SessionGuard,
      RoleGuard.forRoles(appConstants.DataOwner),
    ],
  },
  {
    path: 'welcome',
    component: WelcomeComponent,
    canActivate: [
      AuthGuard,
      SessionGuard,
      RoleGuard.forRoles(appConstants.DataOwner),
    ],
  },
  {
    path: 'important-information',
    component: ImportantInformationComponent,
    canActivate: [
      AuthGuard,
      SessionGuard,
      RoleGuard.forRoles(appConstants.DataOwner),
    ],
  },
  {
    path: 'mobile-device-notice',
    component: MobileDeviceNoticeComponent,
    canActivate: [
      AuthGuard,
      SessionGuard,
      RoleGuard.forRoles(appConstants.DataOwner),
    ],
  },
  {
    path: 'legal-disclaimer',
    component: LegalDisclaimerComponent,
    canActivate: [
      AuthGuard,
      SessionGuard,
      RoleGuard.forRoles(appConstants.DataOwner),
    ],
  },
  {
    path: 'select-event',
    component: SelectEventComponent,
    canActivate: [AuthGuard, RoleGuard.forRoles(appConstants.DataOwner)],
  },
  {
    path: 'cms-file/:id/:filename',
    component: CmsFileDownloadComponent,
    canActivate: [AuthGuard, RoleGuard.forRoles(appConstants.DataOwner)],
  },
  {
    path: 'finish-setup',
    component: FinishSetupComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PostloginRoutingModule {}
