import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DynamicKendoComponent } from 'src/app/components/dynamic-kendo/dynamic-kendo.component';
import { SessionGuard } from 'src/app/guards/session-guard';

const routes: Routes = [
  {
    path: '',
    component: DynamicKendoComponent,
    canActivate: [SessionGuard],
  },
  {
    path: 'file',
    component: DynamicKendoComponent,
    canActivate: [SessionGuard],
  },
  {
    path: 'web',
    component: DynamicKendoComponent,
    canActivate: [SessionGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedKendoRoutingModule { }
