import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { RegistrationService, SessionService } from '@cyber/navigator-services';
import { switchMap } from 'rxjs/operators';
import { RegistrationState } from '../components/complete-registration/complete-registration.component';
import { LoadingService } from '../services/loading.service';
import { StorageService } from '../services/storage.service';
import { appConstants } from '../shared/app-constants';

@Injectable({
  providedIn: 'root',
})
export class SessionGuard implements CanActivate {
  constructor(
    private sessionService: SessionService,
    private router: Router,
    private storageService: StorageService,
    public loadingService: LoadingService,
    public registrationService: RegistrationService
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.sessionService.isAuthenticated$.pipe(
      switchMap(async (isAuthenticated) => {
        var cacheKey = localStorage.getItem('knn-cache');
        if (isAuthenticated && !cacheKey) {
          try {
            this.loadingService.show();
            const dataOwners =
              await this.sessionService.getRegisteredDataOwners();

            if (dataOwners?.length === 1) {
              await this.sessionService.beginSession(dataOwners[0].id);

              if (dataOwners[0].workflows.length == 0)
                return this.router.createUrlTree(['/settingup'], {
                  queryParams: { url: '/welcome' },
                });

              if (
                this.storageService.getLocalStorage(appConstants.DataOwner) ==
                null
              )
                return this.router.createUrlTree(['/settingup'], {
                  queryParams: { url: '/home' },
                });

              return true;
            } else if (dataOwners?.length > 1) {
              return this.router.navigateByUrl('/select-event', {
                state: {
                  dataOwners: dataOwners,
                },
              });
            } else {
              //If user is authenticated but has no data owners attempt to finish setup
              try {
                let code = await this.registrationService.fetchIncompleteCode();
                if (code) {
                  const state = <RegistrationState>{
                    code: code,
                    is_additional: false, //We can assume it is not additional due to dataOwners array having 0
                  };
                  this.sessionService.loginWithRedirect('/finish-setup', state);
                } else {
                  return this.router.createUrlTree(['/error/Forbidden']);
                }
              } catch {
                return this.router.createUrlTree(['/error/deactivated']);
              }
            }
          } catch {
            return this.router.createUrlTree(['/error/Forbidden']);
          } finally {
            this.loadingService.hide();
          }
        } else if (isAuthenticated && cacheKey) {
          return true;
        }
        return false;
      })
    );
  }
}
