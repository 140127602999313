import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { Language } from '../models/language';
import { appConstants } from '../shared/app-constants';
import { IRegion, regionConstants } from '../shared/region-resource-constants';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class RegionalDataService {
  private messageSource = new BehaviorSubject('languagechanges');
  
  constructor(public storageService: StorageService,public translateService: TranslateService) { }

  
  get hostRegion(): string {
    var defaultCountry = "US";
    var url = document.location.hostname.toLowerCase();

    var urlParts = url.split('.');
    var urlSuffix =  urlParts[urlParts.length - 1].toLowerCase();
    switch (urlSuffix)
    {
      case "com": {
        defaultCountry = "US";
        break;
      }
      case "ca": {
        defaultCountry = "CA";
        break;
      }
      case "uk": {
        defaultCountry = "UK";
        break;
      }
      default: {
        defaultCountry = "US";
        break;
      }
    }

    this.storageService.setCookie(appConstants.sessionCookieCountry, defaultCountry);
    return defaultCountry;
  }

  get regionConst(): any {
    var regionname = this.hostRegion + "Region";
    return regionConstants.get(regionname);
  }

  get Languages(): Language[] {
    return this.regionConst.RegionalLanguages;
  }

  //Get Language Codes
  get LanguageCodes(): string[] {
    return this.Languages.map((x) => x.code);
  }

  //setting language code when language is selected
  set LanguageCode(code: string) {
    this.storageService.setCookie(appConstants.sessionLanguageCode, code);
    this.translateService.use(code);
    this.changeLanguageCode(code);
  }

  changeLanguageCode(message: string) {
    this.messageSource.next(message)
  }

  get DefaultLanguage(): string {
    let defaultlang: string;
    defaultlang = "en_US";

    if (this.Languages.length > 0) {
      this.Languages.forEach(function (a) {
        if (a.default != undefined && a.default === true) {
          defaultlang = a.code;
        }
      });
    }

    return defaultlang;
  }

  //get language code from cookie
  get LanguageCode(): string {
    const currentLangCode = this.storageService.getCookie(appConstants.sessionLanguageCode);
    return this.ValidLanguageCheck(currentLangCode);
  }

  ValidLanguageCheck(languageCode: string) {
    if (
      languageCode != "" &&
      this.Languages.length > 0 &&
      this.LanguageCodes.indexOf(languageCode) !== -1
    ) {
      return languageCode;
    } else {
      languageCode = this.DefaultLanguage;
      this.LanguageCode = languageCode;
      return languageCode;
    }
  }
}
