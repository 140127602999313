import { Validators } from '@angular/forms';
import { STEP_STATUS } from '@cyber/navigator-services/lib/models/data-owner';

const appFormPatterns = {
  emailRegex: /^[A-Za-z0-9._%+-]+@\w+([\-]\w+)*(\.[a-zA-Z0-9]{1,24})+$/,
  emailMaxLength: 100,
  passwordMinLength: 8,
  FirstNameRegEx:
    /^(?=.{1,50}$)((?!.*[-']{2})[-']*)[a-zA-Z]+(?:[-']*[a-zA-Z\s])*$/,
  LastNameRegEx:
    /^(?=.{1,100}$)((?!.*[-']{2})[-']*)[a-zA-Z]+(?:[-']*[a-zA-Z\s])*$/,
  activationCode: /([^1,0,i,o,I,o]{10})$/,
  zipCode: /^[a-zA-Z0-9]{5}$/,
  phoneNumber: /^(\d){10}$/,
  phoneUS: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s-]\d{3}[\s-]\d{4}$/,
  alphanumericwithspecialcharacters:
    /^[#A-Za-z0-9][A-Za-z0-9\s\:\#\&\(\)\-\;\:\,\.\'\/]*$/,
};

export const appConstants = {
  sessionSelectedViewOrEditMode: 'session-view-mode',
  sessionCookieCountry: 'session-country',
  sessionLanguageCode: 'user-lang-code',
  sessionCookieObject: 'session-cookie',
  DataOwner: 'DataOwner',
  DataOwnerSteps: 'DataOwnerSteps',
  User: 'User',
  FirstTimeUser: 'FirstTimeUser',
  LanguageEn: 'language-en',
  cookieOptInEndDate: 'OptInEndDate',
  cookieOptInStartDate: 'OptInStartDate',
  Regions: 'regions',
  OptInEndDateReached: 'OptInEndDateReached',
  OptInStartDateReached: 'OptInStartDateReached',
  ChangeOfAddressEndDateReached: 'ChangeOfAddressEndDateReached',
  ExceptionReviewEndDateReached: 'ExceptionReviewEndDateReached',
  FromEmail: 'support@krollmonitoring.com',
  ContactEmail: 'incidentsupport@krollsupport.com',
  ContentLastModified: 'ContentLastModified',
  countryId: 6252001,
  EmailRegex: [
    Validators.required,
    Validators.pattern(appFormPatterns.emailRegex),
    Validators.maxLength(appFormPatterns.emailMaxLength),
  ],
  PasswordRegex: [
    Validators.required,
    Validators.minLength(appFormPatterns.passwordMinLength),
  ],
  ActivationCodeRegex: [
    Validators.required,
    Validators.maxLength(10),
    Validators.pattern(appFormPatterns.activationCode),
  ],
  ZipCodeRegex: [
    Validators.required,
    Validators.pattern(appFormPatterns.zipCode),
  ],
  FirstNameRegex: [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(50),
    Validators.pattern(appFormPatterns.FirstNameRegEx),
  ],
  LastNameRegex: [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(50),
    Validators.pattern(appFormPatterns.LastNameRegEx),
  ],
  CoveredEntityNameRegex: [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(50),
    Validators.pattern(appFormPatterns.alphanumericwithspecialcharacters),
  ],
  AddressRegex: [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(50),
    Validators.pattern(appFormPatterns.alphanumericwithspecialcharacters),
  ],
  Address2Regex: [
    Validators.maxLength(50),
    Validators.pattern(appFormPatterns.alphanumericwithspecialcharacters),
  ],
  CityRegex: [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(50),
    Validators.pattern(appFormPatterns.alphanumericwithspecialcharacters),
  ],
  PhoneNumberRegex: [
    Validators.required,
    Validators.pattern(appFormPatterns.phoneUS),
  ],
  SecondaryPhoneNumberRegex: [Validators.pattern(appFormPatterns.phoneUS)],
  StepStatusColors: {
    Green: [
      STEP_STATUS.COMPLETED,
      STEP_STATUS.DOWNLOADED,
      STEP_STATUS.NO_UPDATES_REQUIRED,
      STEP_STATUS.OPTED_IN,
      STEP_STATUS.OPTED_IN_SUBMITTED,
      STEP_STATUS.UPDATED,
      STEP_STATUS.VIEWED,
    ],
    Red: [STEP_STATUS.NA_OPTED_OUT, STEP_STATUS.OPTED_OUT],
    Gray: [STEP_STATUS.NOT_YET_AVAILABLE],
    Default: [STEP_STATUS.PENDING],
    Yellow: [STEP_STATUS.OPTED_IN_PENDING_SUBMISSION, STEP_STATUS.IN_PROGRESS],
  },
  WorkflowStep: {
    InfoCenter: 'InfoCenter',
    CustomerReview: 'CustomerReview',
    NotificationOptIn: 'NotificationOptIn',
    CustomerUpdate: 'CustomerUpdate',
    OCR_OptIn: 'OCR_OptIn',
    Regulator_Notice: 'Regulator_Notice',
    Notification_Report: 'Notification_Report',
    NCOA_Review: 'NCOA_Review',
    Exceptions_Review: 'Exceptions_Review',
  },
};
