<div class="container main-content-container">
    <div class="row justify-content-center  pt-5 pb-5 mb-5">
        <div class="col-sm-12 col-md-7 col-lg-6">
            <div class="title-section mt-5">
                <h1 class="h1-heading grey-heading">{{ 'ResetPassword.Heading' | translate}}</h1>
                <span class="subheading grey-heading">{{ 'ResetPassword.Subheading' | translate}}</span>
            </div>
            <div class="form-div">
                <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <span class="label-row">
                            <label class="control-label">
                                {{'Form.NewPasswordLabel' | translate}}
                            </label>
                            <a class="control-label hover-hand grey-link font-sans link-with-image" *ngIf="hidePassword" (click)="hidePassword=!hidePassword">
                                <i class="material-icons icon pr-1">visibility</i>
                                <span>{{'Form.Show' | translate}}</span>
                            </a>
                            <a class="control-label hover-hand grey-link font-sans link-with-image" *ngIf="!hidePassword" (click)="hidePassword=!hidePassword">
                                <i class="material-icons icon pr-1">visibility_off</i>
                                <span>{{'Form.Hide' | translate}}</span>
                            </a>
                        </span>
                        <input [type]="hidePassword ? 'password' : 'text'"  class="form-control"
                            placeholder="{{'Form.PasswordPlaceholder' | translate}}" formControlName="password">
                        <p class="helptext">
                            {{'Form.NewPasswordHelpText' | translate}}
                        </p>
                        <p class="explanation" *ngIf="password?.invalid && (password?.dirty || password?.touched)">
                            <span class="form-text bad" *ngIf="password?.errors?.required">{{'Form.PasswordRequiredMsg'
                                | translate}}</span>
                            <span class="form-text bad"
                                *ngIf="password?.errors?.minlength">{{'Form.PasswordMinLengthMsg' | translate}}</span>
                        </p>
                    </div>
                    <div *ngIf="errorMessage!==''" class="row col recover-error-message">
                        {{errorMessage}}
                    </div>
                    <button type="submit" class="btn mt-20px btn-primary text-uppercase">
                        <span *ngIf="status==='loaded'">{{'ResetPassword.ChangePasswordButton' | translate }}</span>
                        <ng-container *ngIf="status==='loading'">
                        <app-loading-spinner></app-loading-spinner>
                        </ng-container>
                    </button>

                </form>
            </div>
            <div class="helper-div mt-20px">
                <div>
                    {{'ResetPassword.AlreadyResetPassword' | translate}}
                </div>
                <div>
                    <a class="secondary-link font-sans link-with-image" routerLink="/sign-in">
                        <i class="material-icons icon pr-1">login</i>
                        <span>{{'ResetPassword.SignIn' |translate}}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>