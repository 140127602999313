import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Pipe, PipeTransform } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { CmsContentService } from "@cyber/navigator-services";

@Pipe({
    name: 'authCmsFile'
  })
  export class AuthCmsFilePipe implements PipeTransform {
  
    constructor(
      private http: HttpClient,
      private cmsContentService: CmsContentService
    ) {}
  
    async transform(src: string): Promise<string> {
        try {
          let slug = false;
          let global = false;
          let asset = src.split('?')[0];
          if(src.indexOf('slug=true') > -1){
            slug = true;
          }
          if(src.indexOf('global=true') > -1){
            global=true;
          }
          const dataBlob = (slug ? await this.cmsContentService.getAssetBySlug(asset, global) :
          await this.cmsContentService.getAssetById(asset, global));
          const reader = new FileReader();
          return new Promise((resolve, reject) => {
            reader.onloadend = () => resolve(reader.result as string);
            reader.readAsDataURL(dataBlob);
          });
        } catch {
          return '';
        }
      }
  
  }