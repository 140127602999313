import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  DataOwnerService,
  DataOwnerWorkflowStepEntity,
  STEP_STATUS,
} from '@cyber/navigator-services';
import { BehaviorSubject } from 'rxjs';
import { appConstants } from '../shared/app-constants';
import { LoadingService } from './loading.service';
import { MemberService } from './member.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class StepsHelperService {
  private updatedStep = false;
  public stepDataUpdate$ = new BehaviorSubject(this.updatedStep);
  dataOwnerWorkflowSteps!: DataOwnerWorkflowStepEntity[];
  constructor(
    private _storageService: StorageService,
    private _dataOwnerService: DataOwnerService,
    private router: Router,
    private _memberService: MemberService,
    private loadingService: LoadingService
  ) {}

  updateStepStatus(binding: string, status: any = null, data: any = '') {
    var promise = new Promise<void>((resolve, reject) => {
      this.getDataOwnerFlowStepsFromLocalStorage();
      let currentStep: DataOwnerWorkflowStepEntity =
        this.getCurrentStepByBinding(binding);
      let currentStepIndex = this.getCurrentStepIndex(currentStep);
      if (status != null) currentStep.step_status_id = parseInt(status);
      if (data != null && data != '') {
        currentStep['data'] = JSON.stringify(data);
      }
      currentStep.user = JSON.parse(
        this._storageService.getLocalStorage(appConstants.User)
      );
      currentStep.modify_time = new Date();
      this.loadingService.show();
      this._dataOwnerService
        .saveWorkflowStep(currentStep)
        .then((d: any) => {
          this.loadingService.hide();
          // update local storage
          this.dataOwnerWorkflowSteps[currentStepIndex] = currentStep;
          this._memberService.setCurrentStep(currentStep);
          this._storageService.setLocalStorage(
            appConstants.DataOwnerSteps,
            JSON.stringify(this.dataOwnerWorkflowSteps)
          );
          resolve();
        })
        .catch((e: any) => {
          reject();
        })
        .finally(() => {
          resolve();
        });
    });
    return promise;
  }

  getDataOwnerFlowStepsFromLocalStorage() {
    let json_data = this._storageService.getLocalStorage(
      appConstants.DataOwnerSteps
    );
    if (
      json_data != null &&
      json_data != '' &&
      json_data != undefined &&
      json_data != 'undefined' &&
      json_data != '{}'
    ) {
      this.dataOwnerWorkflowSteps = JSON.parse(json_data);
    }
  }

  getDataOwnerFromLocalStorage() {
    let json_data = this._storageService.getLocalStorage(
      appConstants.DataOwner
    );
    if (
      json_data != null &&
      json_data != '' &&
      json_data != undefined &&
      json_data != 'undefined' &&
      json_data != '{}'
    ) {
      return JSON.parse(json_data);
    }
  }

  getCurrentStepByRoute(route: string): any {
    this.getDataOwnerFlowStepsFromLocalStorage();
    let current_page_routes = route.split('/');
    let current_page_route = '/' + current_page_routes[1];
    let currentStep = this.dataOwnerWorkflowSteps?.find(
      (x: any) => x.step.page_route == current_page_route
    );
    this._memberService.setCurrentStep(currentStep);
    return currentStep;
  }

  getCurrentStepIndex(currentStep: any) {
    this.getDataOwnerFlowStepsFromLocalStorage();
    let currentStepIndex = this.dataOwnerWorkflowSteps.findIndex(
      (x: DataOwnerWorkflowStepEntity) => x.id == currentStep.id
    );
    return currentStepIndex;
  }

  getStepFromBindingPosition(binding: any, position: number) {
    let currentStep = this.getCurrentStepByBinding(binding);
    let currentStepIndex = this.getCurrentStepIndex(currentStep);

    let get_step_id = currentStepIndex + position;
    if (
      get_step_id > 0 &&
      get_step_id <= this.dataOwnerWorkflowSteps.length - 1
    ) {
      return this.dataOwnerWorkflowSteps[currentStepIndex + position];
    }
    return null;
  }

  getCurrentStepByBinding(type: string): any {
    this.getDataOwnerFlowStepsFromLocalStorage();
    let currentStep = this.dataOwnerWorkflowSteps?.find(
      (x: any) => x.step.cms_binding == this.getAlternateStepType(type)
    );
    this._memberService.setCurrentStep(currentStep);
    return currentStep;
  }

  getAlternateStepType(type: string) {
    if (type == 'CustomerReview_File') return 'CustomerReview';
    else if (type == 'CustomerReview_Web') return 'CustomerReview';
    else if (type == 'CustomerUpdate_File') return 'CustomerUpdate';
    else if (type == 'CustomerUpdate_Web') return 'CustomerUpdate';
    else if (type == 'NCOA_Review_File') return 'NCOA_Review';
    else if (type == 'NCOA_Review_Web') return 'NCOA_Review';
    else if (type == 'Exception_Review_File') return 'Exception_Review';
    else if (type == 'Exception_Review_Web') return 'Exception_Review';
    return type;
  }

  disableStep(stepStatusId: number) {
    switch (stepStatusId) {
      case STEP_STATUS.NA_OPTED_OUT:
      case STEP_STATUS.NOT_YET_AVAILABLE:
        return true;
      default:
        return false;
    }
  }
}
