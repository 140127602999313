import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CmsContentService,
  DataOwnerEntity,
  DataOwnerMilestoneEntity,
  DataOwnerService,
  Milestones,
  RegistrationService,
  SessionService,
} from '@cyber/navigator-services';
import { TranslateService } from '@ngx-translate/core';
import { DateHelper } from 'src/app/helpers/date.helper';
import { ContentService } from 'src/app/services/content.service';
import { LoadingService } from 'src/app/services/loading.service';
import { StorageService } from 'src/app/services/storage.service';
import { appConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-setting-up-environment',
  templateUrl: './setting-up-environment.component.html',
  styleUrls: ['./setting-up-environment.component.scss'],
})
export class SettingUpEnvironmentComponent implements OnInit {
  redirectToPage = '';
  loadingLanguage!: any;
  timeZones: any;

  constructor(
    public activatedRoute: ActivatedRoute,
    public dataOwnerService: DataOwnerService,
    public translateService: TranslateService,
    private loadingService: LoadingService,
    private _storageService: StorageService,
    public router: Router,
    public cmsContentService: CmsContentService,
    public contentService: ContentService,
    private _sessionService: SessionService,
    private _dateHelper: DateHelper,
    private _registrationService: RegistrationService
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.redirectToPage = params['url'];
    });
  }

  lang = 'en';

  langCheckTimeOut!: any;
  currentTranslateService!: any;
  async ngOnInit() {
    this.loadingService.show();

    // Loading DataOwner Steps and Milestones
    let currentDataOwnerAsync =
      this._sessionService.fetchCurrentDataOwnerSession();
    let userSessionDetailsAsync =
      this._sessionService.fetchCurrentUserSession();
    let timeZoneAsync = this._registrationService.getTimezones();
    let [currentDataOwner, userSessionDetails, timeZoneDetails] =
      await Promise.all([
        currentDataOwnerAsync,
        userSessionDetailsAsync,
        timeZoneAsync,
      ]);

    this.timeZones = timeZoneDetails;
    let dataOwnerDetails = currentDataOwner;
    let dataOwnerWorkflowSteps =
      currentDataOwner?.workflows[0]?.actions[0]?.steps?.sort(
        (a, b) => a.step?.execution_order - b.step?.execution_order
      );

    this._storageService.setLocalStorage(
      appConstants.DataOwner,
      JSON.stringify(currentDataOwner)
    );

    this._storageService.setLocalStorage(
      appConstants.DataOwnerSteps,
      JSON.stringify(dataOwnerWorkflowSteps)
    );

    let optInStartDateMileStone: DataOwnerMilestoneEntity | undefined =
      dataOwnerDetails?.milestones?.find(
        (x: { milestone_id: number }) => x.milestone_id == Milestones.OptInStart
      );

    let optInStartDate = 'OptIn-Start Date Not Provided';
    let optInStartDateReached = false;
    if (optInStartDateMileStone != null) {
      let optInStartTimeZone = this.timeZones.find(
        (timeZone: any) =>
          timeZone.time_zone_id ==
          optInStartDateMileStone?.effective_date_time_zone_id
      );

      if (
        !this._dateHelper.isFutureDateWithTimeZone(
          optInStartDateMileStone.effective_date,
          optInStartTimeZone?.offset
        )
      ) {
        optInStartDateReached = true;
      }

      let formattedDate = this._dateHelper.formatToDisplayDate(
        optInStartDateMileStone.effective_date,
        optInStartTimeZone?.offset
      );
      optInStartDate = formattedDate;
    }
    this._storageService.setLocalStorage(
      appConstants.OptInStartDateReached,
      optInStartDateReached ? '1' : '0'
    );

    this._storageService.setLocalStorage(
      appConstants.cookieOptInStartDate,
      optInStartDate
    );

    let optInEndDateMileStone: DataOwnerMilestoneEntity | undefined =
      dataOwnerDetails?.milestones?.find(
        (x: { milestone_id: number }) => x.milestone_id == Milestones.OptInEnd
      );

    let optInEndDate = 'OptIn-End Date Not Provided';
    let optInEndDateReached = false;
    if (optInEndDateMileStone != null) {
      let optInEndTimeZone = this.timeZones.find(
        (timeZone: any) =>
          timeZone.time_zone_id ==
          optInEndDateMileStone?.effective_date_time_zone_id
      );
      if (
        !this._dateHelper.isFutureDateWithTimeZone(
          optInEndDateMileStone.effective_date,
          optInEndTimeZone?.offset
        )
      ) {
        optInEndDateReached = true;
      }
      let formattedDate = this._dateHelper.formatToDisplayDate(
        optInEndDateMileStone.effective_date,
        optInEndTimeZone?.offset
      );
      optInEndDate = formattedDate;
    }
    this._storageService.setLocalStorage(
      appConstants.OptInEndDateReached,
      optInEndDateReached ? '1' : '0'
    );

    this._storageService.setLocalStorage(
      appConstants.cookieOptInEndDate,
      optInEndDate
    );

    let changeOfAddressMilestone: DataOwnerMilestoneEntity | undefined =
      dataOwnerDetails?.milestones?.find(
        (x: { milestone_id: number }) =>
          x.milestone_id == Milestones.ChangeOfAddressReviewEndDate
      );

    let changeOfAddressEndDateReached = false;
    if (changeOfAddressMilestone != null) {
      if (
        !this._dateHelper.isFutureDate(changeOfAddressMilestone.effective_date)
      ) {
        changeOfAddressEndDateReached = true;
      }
    }

    this._storageService.setLocalStorage(
      appConstants.ChangeOfAddressEndDateReached,
      changeOfAddressEndDateReached ? '1' : '0'
    );

    let exceptionReviewMilestone: DataOwnerMilestoneEntity | undefined =
      dataOwnerDetails?.milestones?.find(
        (x: { milestone_id: number }) =>
          x.milestone_id == Milestones.ExceptionsReviewEndDate
      );

    let exceptionReviewEndDateReached = false;
    if (exceptionReviewMilestone != null) {
      if (
        !this._dateHelper.isFutureDate(exceptionReviewMilestone.effective_date)
      ) {
        exceptionReviewEndDateReached = true;
      }
    }

    this._storageService.setLocalStorage(
      appConstants.ExceptionReviewEndDateReached,
      exceptionReviewEndDateReached ? '1' : '0'
    );

    // Loading Current User Entity to display name
    this._storageService.setLocalStorage(
      appConstants.User,
      JSON.stringify(userSessionDetails)
    );

    //Final redirect to page
    this.contentService.loadCmsContent();

    this.contentService.getContentState().subscribe(async (data) => {
      if (data) {
        this.loadingService.hide();

        let userSessionDetailsFromLocalStorage = JSON.parse(
          this._storageService.getLocalStorage(appConstants.User)
        );
        let dataOwnersList =
          await this._sessionService.getRegisteredDataOwners();

        if (
          userSessionDetailsFromLocalStorage?.data_owner_user
            ?.data_owner_user_status_id == 2
        ) {
          if (dataOwnersList?.length > 1)
            this.clearAndRedirectToError('deactivated multiple');
          else this.clearAndRedirectToError('deactivated');
          return;
        }
        if (this.getSiteAccessEndDateEnded(dataOwnerDetails)) {
          if (dataOwnersList?.length > 1)
            this.clearAndRedirectToError('event access ended multiple');
          else this.clearAndRedirectToError('event access ended');
          return;
        }

        if (
          !dataOwnerWorkflowSteps ||
          !userSessionDetailsFromLocalStorage?.data_owner_user
            ?.has_completed_welcome
        ) {
          this.router.navigate(['/welcome']);
        } else {
          this.router.navigateByUrl(this.redirectToPage);
        }
      }
    });
  }

  getSiteAccessEndDateEnded(dataOwnerDetails: DataOwnerEntity) {
    let siteAccessEndDateMileStone: DataOwnerMilestoneEntity | undefined =
      dataOwnerDetails?.milestones?.find(
        (x: { milestone_id: number }) =>
          x.milestone_id == Milestones.SiteAccessEnd
      );

    let siteAccessEndDateReached = false;
    if (siteAccessEndDateMileStone != null) {
      let siteAccessEndTimeZone = this.timeZones.find(
        (timeZone: any) =>
          timeZone.time_zone_id ==
          siteAccessEndDateMileStone?.effective_date_time_zone_id
      );
      if (
        !this._dateHelper.isFutureDateWithTimeZone(
          siteAccessEndDateMileStone.effective_date,
          siteAccessEndTimeZone?.offset
        )
      ) {
        siteAccessEndDateReached = true;
      }
    }
    return siteAccessEndDateReached;
  }

  clearAndRedirectToError(errorType: string) {
    this._storageService.removeLocalStorage(appConstants.User);
    this._storageService.removeLocalStorage(appConstants.DataOwner);
    this._storageService.removeLocalStorage(appConstants.DataOwnerSteps);
    this.router.navigateByUrl('error/' + errorType);
  }
}
