import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dynamic-child',
  templateUrl: './dynamic-child.component.html',
  styleUrls: ['./dynamic-child.component.scss']
})
export class DynamicChildComponent implements OnInit {


  @Input() component!: any;
  @Input() currentStep!: any;
  constructor() { }

  ngOnInit(): void {
  }

}
