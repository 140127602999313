import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { appConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-redeem-confirmation',
  templateUrl: './redeem-confirmation.component.html',
  styleUrls: ['./redeem-confirmation.component.scss'],
})
export class RedeemConfirmationComponent implements OnInit {
  pageCms: any;

  constructor(private translate: TranslateService, private _cookieService: CookieService) {}

  ngOnInit(): void {
    this._cookieService.delete(appConstants.sessionCookieObject);
    const cmsPageData = this.translate.instant(`page`);
    this.pageCms = cmsPageData['Redeem_Confirmation'];
  }
}
