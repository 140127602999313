import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivityService, DataOwnerWorkflowStep, RegionService, STEP_STATUS } from '@cyber/navigator-services';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from 'src/app/services/loading.service';
import { MemberService } from 'src/app/services/member.service';
import { StepsHelperService } from 'src/app/services/steps-helper.service';
import { StorageService } from 'src/app/services/storage.service';
import { appConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-optin',
  templateUrl: './optin.component.html',
  styleUrls: ['./optin.component.scss']
})
export class OptinComponent implements OnInit {
  currentStep: any;
  optedValue: number = 0;
  showMakeSelectionPopupValue = false;
  showOptoutConfirmationPopupValue = false;
  subheadingUpdated = "";
  stateColor = "";
  public loadedTip = "";
  showSectionsBasedOnOptInEndDateReached = '0';
  editable = false;

  constructor(public translate: TranslateService,
    private _storageService: StorageService,
    private _router: Router,
    private _stepsHelperService: StepsHelperService,
    private _memberService: MemberService,
    private _activityService: ActivityService,
    private _regionService: RegionService,
    private _loadingService: LoadingService) {
    this._loadingService.show();
    this.currentStep = this._stepsHelperService.getCurrentStepByRoute(this._router.url);
  }

  ngOnInit(): void {
    this.optedValue = this.currentStep.step_status_id;
    this.showSectionsBasedOnOptInEndDateReached = this._storageService.getLocalStorage(appConstants.OptInEndDateReached);
    this.getStateColor();
    this._loadingService.hide();
  }


  showMakeSelectionPopup() {
    this.showMakeSelectionPopupValue = true;
  }

  async emittedPendingOption(selected: any) {
    this.showMakeSelectionPopupValue = false;

    if (selected == null) return;
    if (selected) {
      this.optedValue = STEP_STATUS.OPTED_IN;
      await this._stepsHelperService.updateStepStatus(this.currentStep?.step?.cms_binding, this.optedValue);
    }
    else {
      this.showOptoutConfirmationPopupValue = true;
    }
    this.getStateColor();
  }

  async emittedOptoutConfirmationPopup(selected: any) {
    this.showOptoutConfirmationPopupValue = false;

    if (selected == null) return;
    if (selected) {
      this.optedValue = STEP_STATUS.OPTED_IN;
      await this._stepsHelperService.updateStepStatus(this.currentStep?.step?.cms_binding, this.optedValue);
    }
    else {
      this.optedValue = STEP_STATUS.OPTED_OUT;
      await this._stepsHelperService.updateStepStatus(this.currentStep?.step?.cms_binding, this.optedValue, '{}');
    }
    this.getStateColor();
  }

  getStateColor() {
    let currentStatus = this.translate.instant('globalrichtext.CurrentStatus');

    switch (this.optedValue) {
      case STEP_STATUS.OPTED_OUT: {
        this.stateColor = 'b-red';
        this.subheadingUpdated = currentStatus.replace('{{Selection_Type}}', this.translate.instant('globaltext.OptedOut'));
      }
        break;
      case STEP_STATUS.OPTED_IN_PENDING_SUBMISSION: {
        this.stateColor = 'b-yellow';
        this.subheadingUpdated = currentStatus.replace('{{Selection_Type}}', this.translate.instant('globaltext.OptedInPendingForm'));
      }
        break;
      case STEP_STATUS.OPTED_IN: {
        this.stateColor = 'b-green';
        this.subheadingUpdated = currentStatus.replace('{{Selection_Type}}', this.translate.instant('globaltext.OptedIn'));
      }
        break;
      case STEP_STATUS.OPTED_IN_SUBMITTED: {
        this.stateColor = 'b-green';
        this.subheadingUpdated = currentStatus.replace('{{Selection_Type}}', this.translate.instant('globaltext.OptedInSubmitted'));
      }
        break;
      default: {
        this.stateColor = 'b-gray';
        this.subheadingUpdated = currentStatus.replace('{{Selection_Type}}', this.translate.instant('globaltext.PendingSelection'));
      }
        break;
    }
    this._stepsHelperService.stepDataUpdate$.next(true);
  }
}
