<div
  class="d-flex justify-content-center danger-text mt-3"
  *ngIf="showSectionsBasedOnChangeOfAddressEndDateReached == '1'"
  [innerHTML]="'globalrichtext.NCOAReviewEndDateCrossed' | translate | safeHtml"
></div>
<div *ngIf="showSectionsBasedOnChangeOfAddressEndDateReached == '0'">
  <div
    *ngIf="state !== 'submittedChanges' && state !== 'noChanges'"
    class="row subheading-18p tile-color mt-1"
    [innerHTML]="
      'globalrichtext.SwitchToChangeOfAddressFile' | translate | safeHtml
    "
  ></div>
  <div
    class="button-center d-flex"
    *ngIf="loadButtonText"
    [class]="!hideResumeButton ? '' : 'd-none'"
  >
    <a class="btn btn-primary text-uppercase" (click)="mainButtonClick()">
      <span>{{ loadButtonText }}</span>
    </a>
  </div>
  <p
    class="subheading-18p tile-color mt-2 d-flex justify-content-center"
    [class]="stateColor"
    [innerHTML]="subheadingUpdated"
  ></p>
  <div
    *ngIf="state == 'pending' || state == 'confirmChanges'"
    class="row d-flex justify-content-center mb-2"
  >
    <a
      class="secondary-link font-sans link-with-image"
      (click)="loadedTip = 'web-tips'"
    >
      <span class="material-icons pr-1"> info </span>
      <span [innerHTML]="'globaltext.WebTableTipsTitle' | translate"></span>
    </a>
  </div>
  <app-tips
    [show]="loadedTip == 'web-tips'"
    (closestate)="loadedTip = ''"
    [pageType]="'globalrichtext.ReviewChangeOfAddressWebTipsContent'"
  ></app-tips>

  <div class="row" *ngIf="state == 'pending' || state == 'confirmChanges'">
    <app-review-change-of-address-web
      (emitOnDataChanged)="emitOnDataChanged($event)"
    ></app-review-change-of-address-web>
  </div>
  <b class="d-flex justify-content-center" *ngIf="state === 'submittedChanges'">
    {{ "globaltext.StepCompleteLabel" | translate
    }}<span class="ml-1 secondary-color">
      {{ "globaltext.WebTableSubmittedSuccessfully" | translate }}
      {{ SubmittedDate }}</span
    >
  </b>
</div>

<app-modal-popup
  [show]="showNoChangesPopup"
  leftbutton="CloseButton"
  rightbutton="ContinueButton"
  (emitstate)="emittedNoChangesPopup($event)"
  [modalType]="'EditWebTableTipsContent'"
></app-modal-popup>
<app-modal-popup
  [show]="showConfirmChangesPopup"
  leftbutton="CloseButton"
  rightbutton="ContinueButton"
  (emitstate)="emittedConfirmChangesPopup($event)"
  [modalType]="'edit_customer-web-confirm-changes'"
>
</app-modal-popup>
