<div class="modal" *ngIf="showPopup">
    <div class="card sessionPopupStyle">
        <section class="consent-header">
            <div class="content-container">
                <app-svg [type]="'warning'"></app-svg>
            </div>
        </section>
        <section class="main-content">
            <div class="row">
                <div class="col">
                    <p class="description">
                        {{'globaltext.SessionTimeoutText' | translate}}
                    </p>
                </div>
            </div>
        </section>
        <section class="button-row center">
            <button (click)="closeSessionWarningModel()" type="button" class="btn save btn-secondary">
                <span *ngIf="!sessionExtendClicked">{{'globaltext.OkButton' | translate }}</span>
                <ng-container *ngIf="sessionExtendClicked">
                    <app-loading-spinner></app-loading-spinner>
                </ng-container>
            </button>
        </section>
    </div>
</div>