import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordSuccessComponent } from 'src/app/components/forgot-password-success/forgot-password-success.component';
import { ForgotPasswordComponent } from 'src/app/components/forgot-password/forgot-password.component';
import { SignInComponent } from 'src/app/components/sign-in/sign-in.component';
import { RequestRegistrationLinkSuccessComponent } from 'src/app/components/request-registration-link-success/request-registration-link-success.component';
import { RequestRegistrationLinkComponent } from 'src/app/components/request-registration-link/request-registration-link.component';
import { ResetPasswordSuccessComponent } from 'src/app/components/reset-password-success/reset-password-success.component';
import { ResetPasswordComponent } from 'src/app/components/reset-password/reset-password.component';
import { RedeemComponent } from 'src/app/components/redeem/redeem.component';
import { RedeemAddUsersComponent } from 'src/app/components/redeem-add-users/redeem-add-users.component';
import { RedeemConfirmationComponent } from 'src/app/components/redeem-confirmation/redeem-confirmation.component';
import { GetStartedComponent } from 'src/app/components/get-started/get-started.component';
import { CompleteRegistrationComponent } from 'src/app/components/complete-registration/complete-registration.component';
import { AddEventComponent } from 'src/app/components/add-event/add-event.component';
import { CardErrorComponent } from 'src/app/components/card-error/card-error.component';
import { DynamicHtmlComponent } from 'src/app/components/dynamic-html/dynamic-html.component';
import { BrowserCompatibilityComponent } from 'src/app/components/footer-pages/browser-compatibility/browser-compatibility.component';
import { TermsOfUseComponent } from 'src/app/components/footer-pages/terms-of-use/terms-of-use.component';
import { EndUserAgreementComponent } from 'src/app/components/footer-pages/end-user-agreement/end-user-agreement.component';
import { ContactUsComponent } from 'src/app/components/contact-us/contact-us.component';
import { MaintenanceWrapperComponent } from 'src/app/components/maintenance-wrapper/maintenance-wrapper.component';

const routes: Routes = [
  { path: 'sign-in', component: SignInComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  {
    path: 'forgot-password-success',
    component: ForgotPasswordSuccessComponent,
  },
  { path: 'registration-reset', component: RequestRegistrationLinkComponent },
  {
    path: 'registration-reset-success',
    component: RequestRegistrationLinkSuccessComponent,
  },
  { path: 'password-reset', component: ResetPasswordComponent },
  { path: 'password-reset-success', component: ResetPasswordSuccessComponent },
  { path: 'redeem', component: RedeemComponent },
  {
    path: 'redeem/add-users',
    component: RedeemAddUsersComponent,
  },
  {
    path: 'redeem/confirmation',
    component: RedeemConfirmationComponent,
  },
  {
    path: 'register/:registrationCode',
    component: CompleteRegistrationComponent,
  },
  {
    path: 'add-engagement',
    component: AddEventComponent,
  },
  {
    path: 'get-started',
    component: GetStartedComponent,
  },
  {
    path: 'error/:error',
    component: CardErrorComponent,
  },
  {
    path: 'docs/:type',
    component: DynamicHtmlComponent,
  },
  {
    path: 'browser-compatibility',
    component: BrowserCompatibilityComponent,
  },
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent,
  },
  {
    path: 'end-user-agreement',
    component: EndUserAgreementComponent,
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
  },
  {
    path: 'maintenance',
    component: MaintenanceWrapperComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PreloginRoutingModule {}
