<div class="d-flex justify-content-center danger-text mt-3" *ngIf="showSectionsBasedOnOptInEndDateReached == '1'"
    [innerHTML]="'globalrichtext.CustomerUpdateOptInEndDateCrossed' | translate| safeHtml">
</div>
<div *ngIf="showSectionsBasedOnOptInEndDateReached == '0'">
    <app-switch-to-file-upload *ngIf="state!=='submittedChanges' && state!=='noChanges' ">
    </app-switch-to-file-upload>
    <div class="button-center d-flex" *ngIf="loadButtonText"  [class]="(!hideResumeButton) ? '' : 'd-none'">
        <a class="btn btn-primary text-uppercase" (click)="mainButtonClick()">
            <span>{{loadButtonText}}</span>
        </a>
    </div>
    <p class="subheading-18p tile-color mt-2 d-flex justify-content-center" [class]="stateColor"
        [innerHTML]="subheadingUpdated"></p>
    <app-review-web-table-tips *ngIf="state=='pending' || state=='confirmChanges'">
    </app-review-web-table-tips>

    <div class="row" *ngIf="state=='pending' || state=='confirmChanges'">
        <app-web-table [editable]="'1'" (emitOnDataChanged)="emitOnDataChanged($event)"></app-web-table>
    </div>
    <b class="d-flex justify-content-center" *ngIf="state==='submittedChanges'">
        {{'globaltext.StepCompleteLabel' | translate}}<span class="ml-1 secondary-color"> {{'globaltext.WebTableSubmittedSuccessfully' |
            translate}} {{SubmittedDate}}</span>
    </b>
</div>

<app-modal-popup [show]="showNoChangesPopup" leftbutton="CloseButton" rightbutton="ContinueButton"
    (emitstate)="emittedNoChangesPopup($event)" [modalType]="'EditWebTableTipsContent'"></app-modal-popup>
<app-modal-popup [show]="showConfirmChangesPopup" leftbutton="CloseButton" rightbutton="ContinueButton"
    (emitstate)="emittedConfirmChangesPopup($event)" [modalType]="'edit_customer-web-confirm-changes'">
</app-modal-popup>