import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.service';
import {
  DataOwnerEntity,
  SessionService,
  DataOwnerService,
  RegistrationService,
} from '@cyber/navigator-services';
import { StorageService } from 'src/app/services/storage.service';
import { appConstants } from 'src/app/shared/app-constants';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { RegistrationState } from '../complete-registration/complete-registration.component';

@Component({
  selector: 'app-select-event',
  templateUrl: './select-event.component.html',
  styleUrls: ['./select-event.component.scss'],
})
export class SelectEventComponent implements OnInit {
  selectedDataOwnerId: any = "-1";
  dataOwners: DataOwnerEntity[] = [];

  constructor(
    private sessionService: SessionService,
    private dataOwnerService: DataOwnerService,
    private router: Router,
    public loading: LoadingService,
    private _storageService: StorageService,
    private registrationService: RegistrationService
  ) {}

  async ngOnInit(): Promise<void> {
    this.loading.show();
    this.sessionService.appState$.pipe(take(1)).subscribe(async (appState) => {
      try {
        this.loading.show();
        const registrationState = appState.state as RegistrationState;
        //Complete additional registration for additional event if code is passed in state
        if (registrationState?.code) {
          await this.registrationService.complete(registrationState.code);
          await this.sessionService.getAccessTokenSilently(); //Completing registration may involve adding roles, get new token so that user groups are updated
          this.dataOwners = await this.sessionService.getRegisteredDataOwners();
        }
      } catch (err: any) {
        this.loading.hide();
        this.router.navigateByUrl(`/error/${err}`);
      } finally {
        this.loading.hide();
      }
    });

    this.dataOwners =
      history.state?.dataOwners ??
      (await this.sessionService.getRegisteredDataOwners());
    this.loading.hide();
  }

  async confirmSelection() {
    if (this.selectedDataOwnerId != "-1" && this.selectedDataOwnerId != "") {
      this.loading.show();
      var existingSession = localStorage.getItem('knn-cache');
      if (existingSession) {
        await this.sessionService.switchDataOwner(this.selectedDataOwnerId);
      } else {
        await this.sessionService.beginSession(this.selectedDataOwnerId);
      }
      let dataOwnerWorkflowCreated =
        await this.dataOwnerService.beginWorkflow();
      if (dataOwnerWorkflowCreated)
        this._storageService.setCookie(appConstants.FirstTimeUser, '1');
      this.loading.hide();
      this.router.navigateByUrl('/settingup?url=/home');
    }
  }
}
