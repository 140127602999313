<div class="container-fluid header post-login-header">
  <div class="row">
    <div class="col-8 logos-div">
      <img src="../../../assets/kroll.svg" class="logo-img" />
      <div class="logo-seperator" *ngIf="clientLogoUrl != ''"></div>
      <app-image
        [src]="clientLogoUrl"
        [class]="'client-logo-img'"
        *ngIf="clientLogoUrl != ''"
      ></app-image>
    </div>
    <div class="col-4 main-nav">
      <ng-container
        *ngFor="let eachMenu of DashBoardMenuLinks; let last = last"
      >
        <a [routerLink]="eachMenu.Routerlink" class="nav-row">
          <i class="material-icons-outlined icon pr-1">{{ eachMenu.Icon }}</i>
          <span>{{ "globaltext." + eachMenu.Key | translate }}</span></a
        >
      </ng-container>
      <a class="nav-row hover-hand" (click)="sessionService.logout()">
        <i class="material-icons-outlined icon pr-1">logout</i>
        <span>{{ "globaltext.SignOut" | translate }}</span></a
      >
    </div>
  </div>
</div>
