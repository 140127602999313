import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from 'src/app/services/storage.service';
import { appConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InfoBoxComponent implements OnInit {

  @Input() Content = '';
  @Input() Button = '';
  @Input() Url = '';
  @Input() OpenNewTab = true;
  infoBox: any;

  loadingDateTimeOut!: any;
  constructor(public translate: TranslateService, public router: Router, public storageService: StorageService) {
  }

  ngOnInit(): void {
  }

  navigateTo(url: any) {
    
    if (this.OpenNewTab)
      window.open(url, "_blank");
    else
      this.router.navigate([url]);
  }

  get UpdatedContent() {
    while (this.Content.indexOf("[icon:") > -1) {
      let icon = this.Content.substring(this.Content.indexOf("[icon:") + 6, this.Content.indexOf(":icon]"));
      this.Content = this.Content.replace("[icon:" + icon + ":icon]", '<i class="material-icons custom-icon">' + icon + '</i>');
    }
    while (this.Content.indexOf("[outlineicon:") > -1) {
      let icon = this.Content.substring(this.Content.indexOf("[outlineicon:") + 13, this.Content.indexOf(":outlineicon]"));
      this.Content = this.Content.replace("[outlineicon:" + icon + ":outlineicon]", '<i class="material-icons-outlined custom-icon">' + icon + '</i>');
    }

    if (this.Content.indexOf("{{OptInEndDate}}") > -1) {
      let optInEndDate = this.storageService.getLocalStorage(appConstants.cookieOptInEndDate);
      this.Content = this.Content.replace("{{OptInEndDate}}", optInEndDate);
    }
    return this.Content;
  }
}
