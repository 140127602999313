import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-switch-to-web-update',
  templateUrl: './switch-to-web-update.component.html',
  styleUrls: ['./switch-to-web-update.component.scss'],
})
export class SwitchToWebUpdateComponent implements OnInit {

  
  constructor() { }

  ngOnInit(): void {
  }

}
