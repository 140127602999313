import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prelogin',
  templateUrl: './prelogin.component.html',
  styleUrls: ['./prelogin.component.scss']
})
export class PreloginComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
