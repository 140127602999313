import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService, OutageService } from '@cyber/navigator-services';
import { OutageInformation } from '@cyber/navigator-services/lib/models/outage-information';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, take } from 'rxjs/operators';
import { ContentService } from './services/content.service';
import { LoadingService } from './services/loading.service';
import { RegionalDataService } from './services/regional-data.service';
import { StorageService } from './services/storage.service';
import { IRegion } from './shared/region-resource-constants';
import { MemberService } from './services/member.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'NotificationNavigator';
  regionResourceConst: IRegion;

  timeOutTimerId: any;
  timedOut = false;
  idleState = 'Not started.';
  public sessionCheckTimerId: any;

  showSessionPopup = false;
  translationContentLoaded = false;
  keepAliveTimeoutId: number | undefined;
  outageDetailsTimer: any;
  /**
   *
   */
  constructor(
    public regionalDataService: RegionalDataService,
    public translateService: TranslateService,
    public sessionService: SessionService,
    public storageService: StorageService,
    private el: ElementRef,
    public contentService: ContentService,
    public memberService: MemberService,
    public loadingService: LoadingService,
    private router: Router,
    private outageService: OutageService
  ) {
    this.translationContentLoaded = false;
    let lang = 'en';
    this.loadingService.show();

    const queryParamMap = new URLSearchParams(window.location.search);
    const success = queryParamMap.get('success');
    const message = queryParamMap.get('message');
    if (success === 'false' && message) {
      this.router.navigateByUrl(`/error/${message}`);
    }

    this.contentService.loadCmsContent();
    this.regionResourceConst = this.regionalDataService.regionConst;
    this.translateService.addLangs(this.regionalDataService.LanguageCodes);
    this.translateService.setDefaultLang(lang);

    this.memberService.errorInForm.subscribe((data) => {
      if (data) {
        this.scrollToFirstInvalidControl();
        this.memberService.errorInForm.next(false);
      }
    });

    this.sessionService.initializeIdleTimeoutWarning();

    this.outageService.outageObservable$.subscribe(async (outageInfo) => {
      if (outageInfo?.display_maintenance_page) {
        const isAuthenticated = await this.sessionService.isAuthenticated$
          .pipe(take(1))
          .toPromise();
        if (isAuthenticated) {
          this.sessionService.logout(); //will automatically be redirected to maintenance after log out
        } else {
          await this.router.navigateByUrl('/maintenance');
        }
      } else {
        if (this.router.url === '/maintenance') {
          this.sessionService.loginWithRedirect('/'); //Navigate back to dashboard / login
        }
      }
    });

    this.contentService.getContentState().subscribe((data: any) => {
      if (data) {
        this.translationContentLoaded = true;
        this.loadingService.hide();
      }
    });
  }

  ngOnInit(): void {}

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector('form .ng-invalid');

    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: 'smooth',
    });
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }
}
