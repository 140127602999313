import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RegionalDataService } from 'src/app/services/regional-data.service';
import { appConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-reset-password-success',
  templateUrl: './reset-password-success.component.html',
  styleUrls: ['./reset-password-success.component.scss']
})
export class ResetPasswordSuccessComponent implements OnInit {

  constructor(public regionalDataService: RegionalDataService) {

  }

  ngOnInit(): void {
  }
  

}
