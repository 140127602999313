import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { appConstants } from 'src/app/shared/app-constants';
import {
  FormGroup,
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { RedeemUserWrapper } from 'src/app/models/redeem';
import { RegistrationService } from '@cyber/navigator-services';
import { LoadingService } from 'src/app/services/loading.service';


@Component({
  selector: 'app-redeem-add-user',
  templateUrl: './redeem-add-user.component.html',
  styleUrls: ['./redeem-add-user.component.scss'],
})
export class RedeemAddUserComponent implements OnInit {
  @Input() userDetails!: RedeemUserWrapper;
  @Input() events!: Observable<void>;
  @Output() emitUserDetails: EventEmitter<RedeemUserWrapper> =
    new EventEmitter<RedeemUserWrapper>();

  formGroup!: FormGroup;
  private eventsSubscription!: Subscription;
  isDuplicateEmail!: boolean;

  constructor(private _cookieService: CookieService,
    private _registrationService: RegistrationService,
    private loading: LoadingService) { }

  ngOnInit(): void {
    this.eventsSubscription = this.events?.subscribe(() =>
      this.revalidateForm()
    );
    this.formGroup = new FormGroup({
      id: new FormControl(this.userDetails?.id),
      firstName: new FormControl(
        this.userDetails?.first_name,
        appConstants.FirstNameRegex
      ),
      lastName: new FormControl(
        this.userDetails?.last_name,
        appConstants.LastNameRegex
      ),
      email: new FormControl(this.userDetails?.email, appConstants.EmailRegex),
    });
  }

  get firstName() {
    return this.formGroup?.get('firstName');
  }
  get lastName() {
    return this.formGroup?.get('lastName');
  }
  get email() {
    return this.formGroup?.get('email');
  }

  async duplicateEmailCheck(event: any) {
    const token = this._cookieService.get(appConstants.sessionCookieObject);
    if (token && this.email?.valid) {
      this.loading.show();
      this._registrationService.redeemDuplicateEmailCheck(token, event.target.value).then((result: boolean) => {
        if (result) {
          this.email?.setErrors({ invalid: true, isDuplicate: true })
        }
        else {
          this.email?.setErrors(null);
        }
        this.loading.hide();
      }).catch((error: any) => {
        this.loading.hide();
      })
    }
  }


  onSubmit() {
    if (this.firstName?.value === null) {
      this.firstName.markAsTouched();
    }
    if (this.lastName?.value === null) {
      this.lastName.markAsTouched();
    }
    if (this.email?.value === null) {
      this.email.markAsTouched();
    }

    this.userDetails.isValid = this.formGroup?.valid;
    this.userDetails.email = this.email?.value;
    this.userDetails.first_name = this.firstName?.value;
    this.userDetails.last_name = this.lastName?.value;
    this.formGroup?.updateValueAndValidity();

    this.emitUserDetails.emit(this.userDetails);
  }

  revalidateForm() {
    if (!this.formGroup?.valid) {
      /* if you are using angular 8 or above, you can just do form.markAllAsTouched() which will touch 
         all the fields without having to loop through all the fields and mark it as touched.
      */
      for (let i in this.formGroup?.controls) {
        this.formGroup?.controls[i].markAsTouched();
      }
      return false;
    } else {
      return true;
    }
  }
}
